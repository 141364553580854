import React, {useEffect, useState} from 'react';
import {green} from '@mui/material/colors';
import strings from '../config/strings';
import {Button, Dialog, Fade, Grid, Modal, TextField} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {ModalContainer, ModalPaper} from './styledComponents';
import {getAsyncSelectField} from './fieldsInItem';
import {useDispatch} from 'react-redux';
import {departmentsGroupsActions} from '../redux/actions';
import {pagination} from '../config/settings';

export const GetCreateGroupModal = ({onClose, onAccept, onDecline, open, text}) => {
  const [model, setModel] = useState({
    list: [],
    params: {
      ordering: 'id', limit: pagination.limit, offset: 0, search: null
    },
    item: {
      group: null
    }
  });
  const dispatch = useDispatch();

  const fetchMoreDepartmentsGroups = async (search) => {
    let _search = !search ? '' : search;
    let _modelSearch = !model.params.search ? '' : model.params.search;
    const offset = _search === _modelSearch ? model.params.offset + pagination.limit : 0;

    let newParams = {
      search: search
    };
    if (_search !== _modelSearch) {
      newParams = {
        offset: offset,
        search: search
      };
    }

    const reqParams = Object.assign({}, model.params, newParams);
    let res = await dispatch(departmentsGroupsActions.getList(reqParams, true));
    if (_search === _modelSearch) {
      await setModel((prevState) => ({
        params: {
          limit: pagination.limit,
          ordering: 'id',
          offset: offset,
          search: search
        },
        list: [...prevState.list, ...res.list], // Объединяем
        item: prevState.item
      }));
      return res;
    } else {
      await setModel((prevState) => ({
        params: {
          limit: pagination.limit,
          ordering: 'id',
          offset: offset,
          search: search
        },
        list: [],
        item: prevState.item
      }));
      return [];
    }

  };

  const [state, setState] = useState({
    fieldsInitState: {
      group: {
        error: false,
        helperText: null
      }
    }, fieldsValidators: {}
  });

  const handleAsyncSelectChange = (field) => async (e) => {
    setModel((prevState) => ({
      list: prevState.list,
      params: prevState.params,
      item: {
        group: e?.value ?? null
      }
    }));
  };

  const onCloseModal = async () => {
    await setModel({
      list: [],
      params: {
        ordering: 'id', limit: pagination.limit, offset: 0, search: null
      },
      item: {
        group: null
      }
    });
    onClose();
  };


  return (
    <div>
      <ModalContainer
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={onCloseModal}
        closeAfterTransition
      >
        <Fade in={open}>
          <ModalPaper>
            {/*<h2 id="transition-modal-title">Подтвердите удаление</h2>*/}
            <p id="transition-modal-text">{text}</p>
            <Grid container={true} justifyContent={'center'}>
              {model.item && getAsyncSelectField(model, model, state, handleAsyncSelectChange, fetchMoreDepartmentsGroups, 'group', 'name', ['name'], null, true)}
            </Grid>
            <Grid container={true} justifyContent={'space-around'}>
              <Button
                disabled={!model?.item?.group}
                type="button"
                variant="contained"
                sx={{
                  margin: 1,
                  backgroundColor: green[500]
                }}
                onClick={() => onAccept(model.item.group)}
              >
                {strings.button.addToGroup}
              </Button>
              <Button
                disabled={!model?.item?.group}
                type="button"
                variant="contained"
                color="secondary"
                sx={{
                  margin: 1,
                }}
                onClick={() => onDecline(model.item.group)}
              >
                {strings.button.removeFromGroup}
              </Button>
              <Button
                sx={{
                  margin: 1,
                }}
                type="button"
                color="primary"
                variant="contained"
                onClick={onCloseModal}
              >
                {strings.button.close}
              </Button>
            </Grid>
          </ModalPaper>
        </Fade>
      </ModalContainer>
    </div>
  );
};

export const ChangeUploadingFileTitle = ({onClose, open, title, currentFile, changeFileTitle}) => {
  const [currTitle, setCurrTitle] = useState(null);

  const handleSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    changeFileTitle(currTitle);
  };

  useEffect(() => {
      if (currentFile) {
        setCurrTitle(currentFile.name);
      }
    }, [currentFile]
  );

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={onClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <div> {/*className={classes.paper}*/}
            <h2
              id="transition-modal-title"
              // className={classes.changeTitleHeader}
            >{title}</h2>
            <form
              onSubmit={handleSubmit}
              // className={classes.typesContainer}
            >
              <Grid container={true} justifyContent={'space-around'}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  name="ratesCount"
                  required
                  value={currTitle}
                  onChange={(e) => setCurrTitle(e.target.value)}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  // className={classes.submit}
                >
                  {strings.accept}
                </Button>
              </Grid>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export const RetryPaymentTransitionsModal = ({onClose, open, text}) => {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={onClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <div>{/*className={classes.paper}*/}
            <p id="transition-modal-description">{text}</p>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export const ConfirmRemovingModal = ({onClose, onConfirm, open, text}) => {
  return (
    <div>
      <ModalContainer
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={onClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <ModalPaper>
            <h2 id="transition-modal-title">Подтвердите удаление</h2>
            <p id="transition-modal-text">{text}</p>
            <Grid container={true} justifyContent={'space-around'}>
              <Button
                type="button"
                variant="contained"
                color="secondary"
                // className={classes.button}
                onClick={onConfirm}
              >
                {strings.button.remove}
              </Button>
              <Button
                // className={classes.button}
                type="button"
                color="primary"
                variant="contained"
                onClick={onClose}
              >
                {strings.button.cancel}
              </Button>
            </Grid>
          </ModalPaper>
        </Fade>
      </ModalContainer>
    </div>
  );
};

export const AcceptDeclineModal = ({onClose, onAccept, onDecline, open, text}) => {
  return (
    <div>
      <ModalContainer
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={onClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <ModalPaper>
            {/*<h2 id="transition-modal-title">Подтвердите удаление</h2>*/}
            <p id="transition-modal-text">{text}</p>
            <Grid container={true} justifyContent={'space-around'}>
              <Button
                type="button"
                variant="contained"
                sx={{
                  backgroundColor: green[500]
                }}
                onClick={onAccept}
              >
                {strings.button.accept}
              </Button>
              <Button
                type="button"
                variant="contained"
                color="secondary"
                // className={classes.button}
                onClick={onDecline}
              >
                {strings.button.decline}
              </Button>
              <Button
                // className={classes.button}
                type="button"
                color="primary"
                variant="contained"
                onClick={onClose}
              >
                {strings.button.close}
              </Button>
            </Grid>
          </ModalPaper>
        </Fade>
      </ModalContainer>
    </div>
  );
};

export const AlertDialog = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Use Google\'s location service?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};