import React from 'react';
import {Box, Checkbox, FormControlLabel, Grid, TextField} from '@mui/material';
import strings from '../config/strings';
import {DatePicker, DateTimePicker, FilterDatePicker} from './datePickers';
import {AsyncSelect, ChipSelectMultiple, CustomSelect} from './select';
import {UploadOrShowFileDocs, UploadOrShowMediaImage} from './media';
import {renderEditFieldSkeleton} from './skeletons';
import {StyledGrid} from './styledComponents';


const getError = (state, field) => {
  return state[field] !== undefined ? state[field]['error'] : '';
};

const getHelperText = (state, field) => {
  return state[field] !== undefined ? state[field]['helperText'] : '';
};

export const getTextField = (model, state, handleChange, field, params = {}) => {
  const {required, disabled} = params;
  return (
    <StyledGrid item xs={12}>
      <TextField
        disabled={disabled}
        required={required}
        fullWidth
        size="small"
        variant="outlined"
        multiline
        maxRows={10}
        label={strings[field]}
        name={field}
        value={model.item[field] || ''}
        error={disabled ? null : getError(state, field)}
        helperText={disabled ? null : getHelperText(state, field)}
        onChange={handleChange(field)}
      />
    </StyledGrid>
  );
};

export const getNumberField = (model, state, handleChange, field, params = {}) => {
  const {required, disabled} = params;
  return (
    <StyledGrid item xs={12}>
      <TextField
        disabled={disabled}
        type="number"
        required={required}
        inputProps={{
          min: 0
        }}
        fullWidth
        size="small"
        variant="outlined"
        label={strings[field]}
        name={field}
        value={model.item[field] || null}
        error={disabled ? null : getError(state, field)}
        helperText={disabled ? null : getHelperText(state, field)}
        onChange={handleChange(field)}
      />
    </StyledGrid>
  );
};

export const getBooleanField = (model, state, handleChange, field, params = {}) => {
  const {disabled} = params;
  return (
    <Grid item xs={12}>
      <FormControlLabel
        control={
          <Checkbox
            disabled={disabled}
            checked={model.item[field] || false}
            onChange={handleChange(field)}
            name={field}
          />
        }
        label={strings[field]}
      />
    </Grid>
  );
};

export const getDateTimePicker = (model, state, handleChange, field, params = {}) => {
  const {required, disabled} = params;
  return (
    <StyledGrid item xs={12}>
      <DateTimePicker
        disabled={disabled}
        label={strings[field]}
        name={field}
        value={model.item[field]}
        onChange={(dateObj) => (handleChange(field))(dateObj ? dateObj.getTime() : null)}
      />
    </StyledGrid>
  );
};

export const getDatePicker = (model, state, handleChange, field, params = {}) => {
  const {required, disabled} = params;
  return (
    <StyledGrid item xs={12}>
      <DatePicker
        disabled={disabled}
        label={strings[field]}
        name={field}
        value={model.item[field]}
        onChange={(dateObj) => (handleChange(field))(dateObj ? dateObj.getTime() : null)}
      />
    </StyledGrid>
  );
};

export const getFilterDatePicker = (model, state, handleChange, field, params = {}) => {
  const {required, disabled} = params;
  return (
    <StyledGrid item xs={12}>
      <FilterDatePicker
        disabled={disabled}
        label={strings[field]}
        name={field}
        value={model.item[field]}
        onChange={(dateObj) => (handleChange(field))(dateObj ? dateObj.getTime() : null)}
      />
    </StyledGrid>
  );
};

export const getSelectField = (options, model, state, handleSelectChange, field, optLabel = 'title', labelComposeFrom = ['title'], params = {}) => {
  const {required, disabled} = params;
  return (
    <Grid item xs={12}>
      {options.list?.length || options.length ?
        <CustomSelect
          disabled={disabled}
          required={required}
          name={field}
          label={strings[field]}
          labelComposeFrom={labelComposeFrom}
          optionsLabel={optLabel}
          options={options.list ? options.list : options}
          value={model.item[field]}
          error={disabled ? null : getError(state, field)}
          helperText={disabled ? null : getHelperText(state, field)}
          onChange={handleSelectChange(field)}
        />
        : renderEditFieldSkeleton()
      }
    </Grid>
  );
};

export const getSelectMultipleField = (options, model, state, handleSelectChange, field, optLabel = 'title', labelComposeFrom = ['title'], params = {}) => {
  const {required, disabled} = params;
  return (
    <Grid item xs={12}>
      <ChipSelectMultiple
        disabled={disabled}
        required={required}
        label={strings[field]}
        name={field}
        labelComposeFrom={labelComposeFrom}
        optionsLabel={optLabel}
        options={options.list ? options.list : options}
        value={model.item[field]}
        error={disabled ? null : getError(state, field)}
        helperText={disabled ? null : getHelperText(state, field)}
        onChange={handleSelectChange(field)}
      />
    </Grid>
  );
};

export const getAsyncSelectField = (optionsModel, model, state, handleAsyncSelectChange, fetchMore, field, optionsLabel, labelComposeFrom = ['title'], defaultValue = null, isSearchable = false, ...params) => {
  return (
    <StyledGrid item xs={12} sx={{zIndex: 1}}>
      {!optionsModel.clearing ?
        <AsyncSelect
          params={params}
          label={strings[field]}
          optionsLabel={optionsLabel}
          fetchMore={fetchMore}
          model={optionsModel}
          field={field}
          labelComposeFrom={labelComposeFrom}
          onChange={handleAsyncSelectChange(field)}
          defaultValue={defaultValue}
          value={model.item[field]}
          isSearchable={isSearchable}
        />
        : renderEditFieldSkeleton()}
    </StyledGrid>
  );
};

export const FilterAsyncSelectField = (
  {
    optionsModel,
    value,
    handleAsyncSelectChange,
    fetchMore,
    field,
    optionsLabel,
    labelComposeFrom = ['title'],
    defaultValue = null,
    isSearchable = false,
    labelDelimiter = '',
    ...params
  }) => {
  return (
    <Box sx={{
      // zIndex: 2,
      width: 250,
      m: 1
    }}>
      {!optionsModel.clearing ?
        <AsyncSelect
          label={strings[field]}
          optionsLabel={optionsLabel}
          fetchMore={fetchMore}
          model={optionsModel}
          field={field}
          labelComposeFrom={labelComposeFrom}
          labelDelimiter={labelDelimiter}
          onChange={handleAsyncSelectChange(field)}
          defaultValue={defaultValue}
          value={value}
          isSearchable={isSearchable}
          {...params}
        />
        : renderEditFieldSkeleton()}
    </Box>
  );
};

export const getRelatedImageField = (model, field, handleChange, params = {}) => {
  const {required, disabled} = params;
  return (
    <Grid item xs={12}>
      <UploadOrShowMediaImage
        title={strings[field]}
        model={model.item}
        fieldName={field}
        handleChange={handleChange}
      />
    </Grid>
  );
};

export const getFileField = (model, field, handleChange, params = {}) => {
  const {required, disabled} = params;
  return (
    <Grid item xs={12}>
      <UploadOrShowFileDocs
        title={strings[field]}
        model={model.item}
        fieldName={field}
        handleChange={handleChange}
      />
    </Grid>
  );
};