import * as permissionsConstants from '../constants/permissions';
import {APIHelper} from '../../helpers';
import {path} from '../../config/api';

export const permissionsActions = {
  getList,
  getItem,
  addItem,
  updateItem,
  editItem,
  removeItem,
  setPage,
  setParams
};

function getList(params) {
  return dispatch => {
    dispatch(request());
    return APIHelper.get(
      `${path.permissions}`,
      {
        params
      }
    ).then(
      res => {
        dispatch(success(res?.data?.results, parseInt(res?.data?.count || 0)));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: permissionsConstants.GET_PERMISSIONS};
  }

  function success(payload, totalCount) {
    return {type: permissionsConstants.GET_PERMISSIONS_SUCCESS, payload, totalCount};
  }

  function failure(error) {
    return {type: permissionsConstants.GET_PERMISSIONS_ERROR, error};
  }
}

function getItem(id) {
  return dispatch => {
    dispatch(request());
    return APIHelper.get(
      `${path.permissions}/${id}`
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: permissionsConstants.GET_PERMISSION};
  }

  function success(payload) {
    return {type: permissionsConstants.GET_PERMISSION_SUCCESS, payload};
  }

  function failure(error) {
    return {type: permissionsConstants.GET_PERMISSION_ERROR, error};
  }
}

function addItem(data) {
  return dispatch => {
    dispatch(request());
    return APIHelper.post(
      `${path.permissions}`,
      data
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: permissionsConstants.ADD_PERMISSION};
  }

  function success(payload) {
    return {type: permissionsConstants.ADD_PERMISSION_SUCCESS, payload};
  }

  function failure(error) {
    return {type: permissionsConstants.ADD_PERMISSION_ERROR, error};
  }
}

function updateItem(id, data) {
  return dispatch => {
    dispatch(request());
    return APIHelper.put(
      `${path.permissions}/${id}`,
      data
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: permissionsConstants.UPDATE_PERMISSION};
  }

  function success(payload) {
    return {type: permissionsConstants.UPDATE_PERMISSION_SUCCESS, payload};
  }

  function failure(error) {
    return {type: permissionsConstants.UPDATE_PERMISSION_ERROR, error};
  }
}

function editItem(data) {
  return {type: permissionsConstants.EDIT_PERMISSION, data};
}

function removeItem(id) {
  return (dispatch) => {
    dispatch(request());
    return APIHelper.delete(
      `${path.permissions}/${id}`,
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: permissionsConstants.REMOVE_PERMISSION};
  }

  function success(payload) {
    return {type: permissionsConstants.REMOVE_PERMISSION_SUCCESS, payload};
  }

  function failure(error) {
    return {type: permissionsConstants.REMOVE_PERMISSION_ERROR, error};
  }
}

function setParams(data) {
  return {type: permissionsConstants.PERMISSIONS_CHANGE_PARAMS, data};
}

function setPage(data) {
  return {type: permissionsConstants.PERMISSIONS_CHANGE_PAGE, data};
}