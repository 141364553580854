export const GET_DEPARTMENTS_GROUPS = 'GET_DEPARTMENTS_GROUPS';
export const GET_DEPARTMENTS_GROUPS_ERROR = 'GET_DEPARTMENTS_GROUPS_ERROR';
export const GET_DEPARTMENTS_GROUPS_SUCCESS = 'GET_DEPARTMENTS_GROUPS_SUCCESS';

export const GET_DEPARTMENT_GROUP = 'GET_DEPARTMENT_GROUP';
export const GET_DEPARTMENT_GROUP_ERROR = 'GET_DEPARTMENT_GROUP_ERROR';
export const GET_DEPARTMENT_GROUP_SUCCESS = 'GET_DEPARTMENT_GROUP_SUCCESS';

export const EDIT_DEPARTMENT_GROUP = 'EDIT_DEPARTMENT_GROUP';

export const ADD_DEPARTMENT_GROUP = 'ADD_DEPARTMENT_GROUP';
export const ADD_DEPARTMENT_GROUP_ERROR = 'ADD_DEPARTMENT_GROUP_ERROR';
export const ADD_DEPARTMENT_GROUP_SUCCESS = 'ADD_DEPARTMENT_GROUP_SUCCESS';

export const UPDATE_DEPARTMENT_GROUP = 'UPDATE_DEPARTMENT_GROUP';
export const UPDATE_DEPARTMENT_GROUP_ERROR = 'UPDATE_DEPARTMENT_GROUP_ERROR';
export const UPDATE_DEPARTMENT_GROUP_SUCCESS = 'UPDATE_DEPARTMENT_GROUP_SUCCESS';

export const REMOVE_DEPARTMENT_GROUP = 'REMOVE_DEPARTMENT_GROUP';
export const REMOVE_DEPARTMENT_GROUP_ERROR = 'REMOVE_DEPARTMENT_GROUP_ERROR';
export const REMOVE_DEPARTMENT_GROUP_SUCCESS = 'REMOVE_DEPARTMENT_GROUP_SUCCESS';

export const DEPARTMENTS_GROUPS_CHANGE_PARAMS = 'DEPARTMENTS_GROUPS_CHANGE_PARAMS';
export const DEPARTMENTS_GROUPS_UPDATE_PARAMS = 'DEPARTMENTS_GROUPS_UPDATE_PARAMS';
export const DEPARTMENTS_GROUPS_RESET_PARAMS = 'DEPARTMENTS_GROUPS_RESET_PARAMS';
export const DEPARTMENTS_GROUPS_CHANGE_PAGE = 'DEPARTMENTS_GROUPS_CHANGE_PAGE';

export const DEPARTMENTS_GROUPS_CLEAR = 'DEPARTMENTS_GROUPS_CLEAR';