import React from 'react';
import {useNavigate} from 'react-router-dom';
import strings from '../config/strings';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {styled} from '@mui/material/styles';

const LoginContainer = styled(Container)(({theme}) => ({
  padding: theme.spacing(4),
}));
const LoginButton = styled(Button)(({theme}) => ({
  color: '#fff',
  backgroundColor: theme.palette.secondary.main
}));

export const LandingScreen = (props) => {

  const navigate = useNavigate();
  return (
    <div className="">
      <LoginContainer maxWidth="xs">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <LoginButton fullWidth onClick={() => {
              navigate('/login');
            }} type="button" variant="contained">
              {strings.button.authorize}
            </LoginButton>
          </Grid>
        </Grid>
      </LoginContainer>
    </div>
  );
};