import * as constants from '../constants/issues';
import {pagination} from '../../config/settings';
import * as _ from 'lodash';

export const initialState = {
  item: null,
  list: [],
  totalCount: 0,
  loading: false,
  downloadingReport: null,
  reportsParams: {},
  params: {
    ordering: '-created_at', limit: pagination.limit, offset: 0, search: null
  },
  pagesCount: 1,
  page: 1,
};

export default (state = _.cloneDeep(initialState), action) => {
  switch (action.type) {
    case constants.GET_ISSUES:
    case constants.GET_ISSUE:
    case constants.UPDATE_ISSUE:
    case constants.REMOVE_ISSUE:
      return {
        ...state,
        loading: true
      };

    case constants.GET_ISSUES_ERROR:
    case constants.GET_ISSUE_ERROR:
    case constants.UPDATE_ISSUE_ERROR:
    case constants.REMOVE_ISSUE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case constants.GET_ISSUES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
        item: null,
        totalCount: action.totalCount,
        pagesCount: Math.ceil(action.totalCount / pagination.limit)
      };

    case constants.GET_ISSUE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [],
        item: action.payload,
      };

    case constants.UPDATE_ISSUE_SUCCESS:
      return {
        ...state,
        // loading: false,
      };

    case constants.EDIT_ISSUE:
      return {
        ...state,
        item: action.data,
      };
    case constants.ISSUES_CHANGE_PARAMS:
      return {
        ...state,
        params: action.data,
      };
    case constants.ISSUES_UPDATE_REPORTS_PARAMS:
      return {
        ...state,
        reportsParams: action.data !== null ? _.merge(state.reportsParams, action.data) : _.cloneDeep(initialState.reportsParams)
      };
    case constants.ISSUES_UPDATE_PARAMS:
      return {
        ...state,
        paramsChanged: true,
        params: action.data !== null ? _.merge(state.params, action.data) : _.cloneDeep(initialState.params)
      };
    case constants.ISSUES_RESET_PARAMS:
      return {
        ...state,
        clearing: false,
        paramsChanged: false,
        // Сохраняем фильтрацию по статусу для вкладок подменю
        params: Object.assign({status: state.params.status}, _.cloneDeep(initialState.params))
      };
    case constants.ISSUES_CHANGE_PAGE:
      return {
        ...state,
        page: +action.data,
      };

    case constants.DOWNLOAD_REPORT:
      return {
        ...state,
        loading: true,
        downloadingReport: action.format
      };

    case constants.DOWNLOAD_REPORT_SUCCESS:
    case constants.DOWNLOAD_REPORT_ERROR:
      return {
        ...state,
        loading: false,
        downloadingReport: null
      };

    default:
      return state;
  }
};