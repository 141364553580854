import * as messagesConstants from '../constants/messages';
import {APIHelper} from '../../helpers';
import {path} from '../../config/api';
import * as issuesConstants from '../constants/issues';

export const messagesActions = {
  getList,
  getItem,
  addItem,
  updateItem,
  updateItemNoLoadingEvent,
  editItem,
  removeItem,
  setPage,
  setParams,
  clear
};

function getList(params) {
  return dispatch => {
    dispatch(clear());
    dispatch(request());
    return APIHelper.get(
      `${path.messages}`,
      {
        params
      }
    ).then(
      res => {
        dispatch(success(res?.data?.results, parseInt(res?.data?.count || 0)));
        return Promise.resolve(res.data);
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: messagesConstants.GET_MESSAGES};
  }

  function success(payload, totalCount) {
    return {type: messagesConstants.GET_MESSAGES_SUCCESS, payload, totalCount};
  }

  function failure(error) {
    return {type: messagesConstants.GET_MESSAGES_ERROR, error};
  }
}

function getItem(id) {
  return dispatch => {
    dispatch(request());
    return APIHelper.get(
      `${path.messages}/${id}`
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: messagesConstants.GET_MESSAGE};
  }

  function success(payload) {
    if (payload.permissions.length) { // TODO переделать этот костыль
      payload['permissions'] = payload.permissions.map(e => e.id);
    }
    return {type: messagesConstants.GET_MESSAGE_SUCCESS, payload};
  }

  function failure(error) {
    return {type: messagesConstants.GET_MESSAGE_ERROR, error};
  }
}

function addItem(data) {
  return dispatch => {
    dispatch(request());
    return APIHelper.post(
      `${path.messages}`,
      data
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: messagesConstants.ADD_MESSAGE};
  }

  function success(payload) {
    return {type: messagesConstants.ADD_MESSAGE_SUCCESS, payload};
  }

  function failure(error) {
    return {type: messagesConstants.ADD_MESSAGE_ERROR, error};
  }
}

function updateItem(id, data) {
  return dispatch => {
    dispatch(request());
    return APIHelper.put(
      `${path.messages}/${id}`,
      data
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: messagesConstants.UPDATE_MESSAGE};
  }

  function success(payload) {
    return {type: messagesConstants.UPDATE_MESSAGE_SUCCESS, payload};
  }

  function failure(error) {
    return {type: messagesConstants.UPDATE_MESSAGE_ERROR, error};
  }
}

function updateItemNoLoadingEvent(id, data) {
  return dispatch => {
    return APIHelper.put(
      `${path.messages}/${id}`,
      data
    ).then(
      res => {
        return Promise.resolve();
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function failure(error) {
    return {type: issuesConstants.UPDATE_ISSUE_ERROR, error};
  }
}

function editItem(data) {
  return {type: messagesConstants.EDIT_MESSAGE, data};
}

function removeItem(id) {
  return (dispatch) => {
    dispatch(request());
    return APIHelper.delete(
      `${path.messages}/${id}`,
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: messagesConstants.REMOVE_MESSAGE};
  }

  function success(payload) {
    return {type: messagesConstants.REMOVE_MESSAGE_SUCCESS, payload};
  }

  function failure(error) {
    return {type: messagesConstants.REMOVE_MESSAGE_ERROR, error};
  }
}

function setParams(data) {
  return {type: messagesConstants.MESSAGES_CHANGE_PARAMS, data};
}

function setPage(data) {
  return {type: messagesConstants.MESSAGES_CHANGE_PAGE, data};
}

function clear() {
  return {type: messagesConstants.MESSAGES_CLEAR};
}