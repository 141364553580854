export const GET_UNITS = 'GET_UNITS';
export const GET_UNITS_ERROR = 'GET_UNITS_ERROR';
export const GET_UNITS_SUCCESS = 'GET_UNITS_SUCCESS';

export const GET_UNIT = 'GET_UNIT';
export const GET_UNIT_ERROR = 'GET_UNIT_ERROR';
export const GET_UNIT_SUCCESS = 'GET_UNIT_SUCCESS';

export const EDIT_UNIT = 'EDIT_UNIT';

export const ADD_UNIT = 'ADD_UNIT';
export const ADD_UNIT_ERROR = 'ADD_UNIT_ERROR';
export const ADD_UNIT_SUCCESS = 'ADD_UNIT_SUCCESS';

export const UPDATE_UNIT = 'UPDATE_UNIT';
export const UPDATE_UNIT_ERROR = 'UPDATE_UNIT_ERROR';
export const UPDATE_UNIT_SUCCESS = 'UPDATE_UNIT_SUCCESS';

export const REMOVE_UNIT = 'REMOVE_UNIT';
export const REMOVE_UNIT_ERROR = 'REMOVE_UNIT_ERROR';
export const REMOVE_UNIT_SUCCESS = 'REMOVE_UNIT_SUCCESS';

export const UNITS_CHANGE_PARAMS = 'UNITS_CHANGE_PARAMS';
export const UNITS_UPDATE_PARAMS = 'UNITS_UPDATE_PARAMS';
export const UNITS_RESET_PARAMS = 'UNITS_RESET_PARAMS';
export const UNITS_CLEAR = 'UNITS_CLEAR';
export const UNITS_CHANGE_PAGE = 'UNITS_CHANGE_PAGE';