import * as areasConstants from '../constants/areas';
import {APIHelper} from '../../helpers';
import {path} from '../../config/api';

export const areasActions = {
  getList,
  getItem,
  addItem,
  updateItem,
  editItem,
  removeItem,
  setPage,
  setParams,
  updateParams,
  resetParams,
  clear
};

function getList(params) {
  return dispatch => {
    dispatch(request());
    return APIHelper.get(
      `${path.geo.areas}`,
      {
        params
      }
    ).then(
      res => {
        dispatch(success(res?.data?.results, parseInt(res?.data?.count || 0)));
        return {
          list: res?.data?.results, totalCount: parseInt(res?.data?.count || 0)
        };
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: areasConstants.GET_AREAS};
  }

  function success(payload, totalCount) {
    return {type: areasConstants.GET_AREAS_SUCCESS, payload, totalCount};
  }

  function failure(error) {
    return {type: areasConstants.GET_AREAS_ERROR, error};
  }
}

function getItem(id) {
  return dispatch => {
    dispatch(request());
    return APIHelper.get(
      `${path.geo.areas}/${id}`
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: areasConstants.GET_AREA};
  }

  function success(payload) {
    if (payload.permissions.length) { // TODO переделать этот костыль
      payload['permissions'] = payload.permissions.map(e => e.id);
    }
    return {type: areasConstants.GET_AREA_SUCCESS, payload};
  }

  function failure(error) {
    return {type: areasConstants.GET_AREA_ERROR, error};
  }
}

function addItem(data) {
  return dispatch => {
    dispatch(request());
    return APIHelper.post(
      `${path.geo.areas}`,
      data
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: areasConstants.ADD_AREA};
  }

  function success(payload) {
    return {type: areasConstants.ADD_AREA_SUCCESS, payload};
  }

  function failure(error) {
    return {type: areasConstants.ADD_AREA_ERROR, error};
  }
}

function updateItem(id, data) {
  return dispatch => {
    dispatch(request());
    return APIHelper.put(
      `${path.geo.areas}/${id}`,
      data
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: areasConstants.UPDATE_AREA};
  }

  function success(payload) {
    return {type: areasConstants.UPDATE_AREA_SUCCESS, payload};
  }

  function failure(error) {
    return {type: areasConstants.UPDATE_AREA_ERROR, error};
  }
}

function editItem(data) {
  return {type: areasConstants.EDIT_AREA, data};
}

function removeItem(id) {
  return (dispatch) => {
    dispatch(request());
    return APIHelper.delete(
      `${path.geo.areas}/${id}`,
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: areasConstants.REMOVE_AREA};
  }

  function success(payload) {
    return {type: areasConstants.REMOVE_AREA_SUCCESS, payload};
  }

  function failure(error) {
    return {type: areasConstants.REMOVE_AREA_ERROR, error};
  }
}

function setParams(data) {
  return {type: areasConstants.AREAS_CHANGE_PARAMS, data};
}

function updateParams(data) {
  return {type: areasConstants.AREAS_UPDATE_PARAMS, data};
}

function resetParams() {
  return {type: areasConstants.AREAS_RESET_PARAMS};
}

function setPage(data) {
  return {type: areasConstants.AREAS_CHANGE_PAGE, data};
}

function clear(data) {
  return {type: areasConstants.AREAS_CLEAR, data};
}