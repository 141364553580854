export const GET_AREAS = 'GET_AREAS';
export const GET_AREAS_ERROR = 'GET_AREAS_ERROR';
export const GET_AREAS_SUCCESS = 'GET_AREAS_SUCCESS';

export const GET_AREA = 'GET_AREA';
export const GET_AREA_ERROR = 'GET_AREA_ERROR';
export const GET_AREA_SUCCESS = 'GET_AREA_SUCCESS';

export const EDIT_AREA = 'EDIT_AREA';

export const ADD_AREA = 'ADD_AREA';
export const ADD_AREA_ERROR = 'ADD_AREA_ERROR';
export const ADD_AREA_SUCCESS = 'ADD_AREA_SUCCESS';

export const UPDATE_AREA = 'UPDATE_AREA';
export const UPDATE_AREA_ERROR = 'UPDATE_AREA_ERROR';
export const UPDATE_AREA_SUCCESS = 'UPDATE_AREA_SUCCESS';

export const REMOVE_AREA = 'REMOVE_AREA';
export const REMOVE_AREA_ERROR = 'REMOVE_AREA_ERROR';
export const REMOVE_AREA_SUCCESS = 'REMOVE_AREA_SUCCESS';

export const AREAS_CHANGE_PARAMS = 'AREAS_CHANGE_PARAMS';
export const AREAS_UPDATE_PARAMS = 'AREAS_UPDATE_PARAMS';
export const AREAS_RESET_PARAMS = 'AREAS_RESET_PARAMS';
export const AREAS_CHANGE_PAGE = 'AREAS_CHANGE_PAGE';
export const AREAS_CLEAR = 'AREAS_CLEAR';