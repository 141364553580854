import {applyMiddleware, compose, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';
import {logger} from 'redux-logger';

const middleWares = [thunkMiddleware];

if (process.env.NODE_ENV === 'development') {
  const {logger} = require('redux-logger');
  middleWares.push(logger);
}

const store = compose(applyMiddleware(...middleWares))(createStore)(rootReducer);

export default store;