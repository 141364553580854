import {APIHelper} from '../../helpers';
import {path} from '../../config/api';
import * as mediaConstants from '../constants/media';

export const mediaActions = {
  uploadFile,
  deleteFiles,
  downloadFile,
  clear
};

function uploadFile(formData) {
  return dispatch => {
    dispatch(request());
    return APIHelper.post(
      `${path.files}`,
      formData
    ).then(
      res => {
        dispatch(success(res.data));
        return Promise.resolve(res.data);
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: mediaConstants.UPLOAD_FILE};
  }

  function success(payload) {
    return {
      type: mediaConstants.UPLOAD_FILE_SUCCESS, payload: {
        ids: payload.map(e => e.id)
      }
    };
  }

  function failure(error) {
    return {type: mediaConstants.UPLOAD_FILE_ERROR, error};
  }
}

function deleteFiles(uuids) {
  return dispatch => {
    dispatch(request());
    return APIHelper.delete(
      `${path.files}`,
      {
        data: {
          uuid: uuids
        }
      },
      {}
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: mediaConstants.DELETE_FILES};
  }

  function success(payload) {
    return {type: mediaConstants.DELETE_FILES_SUCCESS, payload};
  }

  function failure(error) {
    return {type: mediaConstants.DELETE_FILES_ERROR, error};
  }
}

function clear() {
  return {type: mediaConstants.CLEAR};
}

function downloadFile(url) {
  return dispatch => {
    dispatch(request());
    return APIHelper.post(
      `${url}`,
      null,
      {
        params: {
          cacheBustTimestamp: Date.now(), // prevents IE cache problems on re-download
        },
        responseType: 'blob',
      }
    ).then(
      res => {
        dispatch(success(res.data));
        const type = res.headers['content-type'];
        const disposition = res.headers['content-disposition'];
        const blob = new Blob([res.data], {
          type: type, encoding: 'UTF-8'
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = disposition.split('filename=')[1] || 'file.txt';
        link.click();
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: mediaConstants.DOWNLOAD_FILE};
  }

  function success(payload) {
    return {type: mediaConstants.DOWNLOAD_FILE_SUCCESS, payload};
  }

  function failure(error) {
    return {type: mediaConstants.DOWNLOAD_FILE_ERROR, error};
  }
}