module.exports = {
  adminPanelTitle: 'Quality Patrol | Панель управления - ',
  button: {
    authorize: 'Авторизоваться',
    login: 'Войти',
    add: 'Добавить',
    manageGroup: 'Настроить группу',
    addToGroup: 'Добавить в группу',
    removeFromGroup: 'Убрать из группы',
    select: 'Выбрать',
    cancel: 'Отменить',
    back: 'Назад',
    clear: 'Сбросить',
    upload: 'Загрузить',
    save: 'Сохранить',
    accept: 'Принять',
    decline: 'Отклонить',
    close: 'Закрыть',
    remove: 'Удалить',
    removeIssue: 'Удалить обращение',
    changePassword: 'Сменить пароль',
    regeneratePassword: 'Пересоздать пароль',
    logout: 'Выйти',
  },
  parent: 'Родительский объект',
  email: 'Email',
  unit: 'Юнит',
  department: 'Территориальный орган',
  department2: 'Территориальный орган 2',
  group: 'Группа',
  isBlocked: 'Пользователь заблокирован',
  isJti: 'Пользователь JTI',
  isBat: 'Пользователь BAT',
  isPmi: 'Пользователь PMI',
  stallNumber: 'Номер палатки',
  shopName: 'Название торговой точки',
  internalExecutor: 'Внутренний исполнитель',
  internalExecutor2: 'Внутренний исполнитель 2',
  legalEntity: 'Юридическое лицо продавца (ИП)',
  productName: 'Наименование продукции',
  visitDate: 'Дата посещения торговой точки',
  isReceivedReceipt: 'Точка выдает кассовый чек',
  isSellingUnderStall: 'Продажа из под прилавка',
  sendReplyToUser: 'Отправить ответ пользователю',
  user: 'Заявитель',
  userId: 'Заявитель',
  area: 'Регион',
  city: 'Город',
  phone: 'Телефон',
  type: 'Тип профиля',
  name: 'Название',
  permissions: 'Разрешения',
  username: 'Username',
  lastName: 'Фамилия',
  template: 'Шаблон заявления',
  firstName: 'Имя',
  middleName: 'Отчество',
  groups: 'Группы',
  sex: 'Пол',
  title: 'Заголовок',
  description: 'Дополнительная информация',
  descriptionPlain: 'Простое Описание',
  address: 'Адрес',
  additionalInfo: 'Ориентир',
  createdAt: 'Дата создания',
  createdFrom: 'Создано с',
  createdTo: 'Создано до',
  resolution: 'Решение',
  id: 'ID',
  number: 'Номер',
  status: 'Статус',
  violationType: 'Состав нарушения',
  category: 'Категория',
  kusp: 'КУСП',
  seizedProductCount: 'Количество изъятой продукции',
  image: 'Изображение',
  login: 'Логин',
  password: 'Пароль',
  newPassword: 'Новый пароль',
  passwordRetype: 'Повторите пароль',
  selectFileMediaType: 'Выберите тип документа для загружаемого файла',
  changeFileTitle: 'Изменить название файла',
  system: 'Система',
  buItms: 'БЮ_ITMS',
  errors: {
    401: 'Ошибка авторизации',
    400: 'Ошибка в запросе',
    403: 'Доступ запрещен',
    404: 'Не найдено',
    499: 'Кастомная ошибка',
    500: 'Внутренняя ошибка сервера',
  },
  validation: {
    passwordRequired: 'Необходимо ввести пароль',
    usernameRequired: 'Необходимо ввести имя пользователя',
  },
  cancelled: 'Отменён',
  logout: 'Выйти',
};