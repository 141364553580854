import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {authActions} from '../redux/actions';
import strings from '../config/strings';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AppIcon from '../img/logo.svg';
import {styled} from '@mui/material/styles';
import {analyticsOnly} from '../helpers';

const LogoContainer = styled(Container)(({theme}) => ({
  width: 400,
  height: 400,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));
const LogoImage = styled('img')(({theme}) => ({
  width: 400,
  height: 400,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

const LoginButton = styled(Button)(({theme}) => ({
  color: '#fff',
  backgroundColor: theme.palette.secondary.main
}));

export const LoginScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const xAuthToken = localStorage.getItem('xAuthToken');
    let groups = JSON.parse(localStorage.getItem('authGroups'));
    let mappedGroups = groups && groups.length ? groups.map(e => e.name) : [];
    if (xAuthToken) {
      if (analyticsOnly(mappedGroups)) {
        navigate('/analytics', {replace: true});
      } else {
        navigate('/issues', {replace: true});
      }
    }
  }, [dispatch]);

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    setEmailHelperText(null);
    setEmailError(false);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
    setPasswordHelperText(null);
    setPasswordError(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (email && password) {
      if (email && email.length < 5) {
        setEmailError(true);
        setEmailHelperText('Email должен быть не менее 5 символов');
        return false;
      }
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(email).toLowerCase())) {
        setEmailError(true);
        setEmailHelperText('Email имеет некорректный формат');
        return false;
      }
      if (password && password.length < 5) {
        setPasswordError(true);
        setPasswordHelperText('Пароль должен быть не менее 5 символов');
        return false;
      }
      dispatch(authActions.login(email, password)).then((res) => {
        let groups = res.groups;
        let mappedGroups = groups && groups.length ? groups.map(e => e.name) : [];
        if (analyticsOnly(mappedGroups)) {
          navigate('/analytics', {replace: true});
        } else {
          navigate('/issues', {replace: true});
        }
      });
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="">
      <LogoContainer>
        <LogoImage alt="Logo" src={AppIcon}/>
      </LogoContainer>
      <Container maxWidth="xs">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={strings.email}
                    name="email"
                    size="small"
                    variant="outlined"
                    error={emailError}
                    helperText={emailHelperText}
                    value={email}
                    required
                    onChange={handleChangeEmail}
                    placeholder={strings.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={strings.password}
                    name="password"
                    size="small"
                    type={showPassword ? 'text' : 'password'}
                    variant="outlined"
                    value={password}
                    required
                    error={passwordError}
                    helperText={passwordHelperText}
                    onChange={handleChangePassword}
                    placeholder={strings.password}
                    InputProps={
                      {
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                              size="medium"
                            >
                              {showPassword ? (
                                <Visibility fontSize="small"/>
                              ) : (
                                <VisibilityOff fontSize="small"/>
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <LoginButton fullWidth type="submit" variant="contained">
                {strings.button.login}
              </LoginButton>
            </Grid>
          </Grid>
        </form>
      </Container>
    </div>
  );
};