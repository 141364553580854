import React, {useCallback, useEffect, useState} from 'react';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import {default as MuiSelect} from '@mui/material/Select';
import {AsyncPaginate} from 'react-select-async-paginate';
import {StyledChip, StyledChipList, StyledFormControl} from './styledComponents';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const ChipSelect = ({
                             options,
                             value,
                             label,
                             optionsLabel,
                             labelComposeFrom,
                             onChange,
                             disabled,
                             required,
                             defaultValue
                           }) => {
  const getLabel = (selectedId) => {
    let labelField = optionsLabel || 'id';
    const found = options.find(e => e.id === selectedId);
    return found ? found[labelField] : `UNKNOWN ID:${selectedId}`;
  };
  const getComposedLabel = (option) => {
    return labelComposeFrom.map(e => option[e]).join(' - ');
  };
  return (
    <div>
      <StyledFormControl>
        <InputLabel id="chip-label">{label}</InputLabel>
        <MuiSelect
          fullWidth
          labelId="chip-label"
          id="chip"
          value={value}
          defaultValue={defaultValue || ''}
          disabled={!!disabled}
          required={!!required}
          onChange={onChange}
          input={<Input id="select-chip"/>}
          renderValue={
            (selectedId) => (
              <StyledChipList>
                <StyledChip key={selectedId} label={getLabel(selectedId)}/>
              </StyledChipList>
            )}
          MenuProps={MenuProps}
        >
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {getComposedLabel(option)}
            </MenuItem>
          ))}
        </MuiSelect>
      </StyledFormControl>
    </div>
  );
};

export const ChipSelectMultiple = ({options, value, label, optionsLabel, labelComposeFrom, onChange, disabled}) => {
  let labelField = optionsLabel || 'id';
  const getLabel = (selectedId) => {
    const found = (options || []).find(e => e.id === selectedId);
    return found ? found[labelField] : `UNKNOWN ID:${selectedId}`;
  };
  const getComposedLabel = (option) => {
    return labelComposeFrom.map(e => option[e]).join(' - ');
  };
  return (
    <div>
      <StyledFormControl>
        <InputLabel size={'small'} id="mutiple-chip-label">{label}</InputLabel>
        <MuiSelect
          size={'small'}
          labelId="multiple-chip-label"
          id="multiple-chip"
          multiple
          label={label}
          value={value ? value : []}
          defaultValue={[]}
          onChange={onChange}
          disabled={!!disabled}
          // input={<Input/>} //id="multiple-select-chip"
          renderValue={(selected) => (
            <StyledChipList>
              {(selected || []).map((value) => (
                <StyledChip key={value} label={getLabel(value)}/>
              ))}
            </StyledChipList>
          )}
          MenuProps={MenuProps}
        >
          {(options || []).map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {getComposedLabel(option)}
            </MenuItem>
          ))}
        </MuiSelect>
      </StyledFormControl>
    </div>
  );
};


export const CustomSelect = ({
                               options,
                               value,
                               label,
                               optionsLabel,
                               labelComposeFrom,
                               onChange,
                               disabled,
                               required,
                               defaultValue
                             }) => {
  const getLabel = (selectedId) => {
    let labelField = optionsLabel || 'id';
    const found = options.find(e => e.id === selectedId);
    return found ? found[labelField] : `UNKNOWN ID:${selectedId}`;
  };
  const getComposedLabel = (option) => {
    return labelComposeFrom.map(e => option[e]).join(' - ');
  };
  return (
    <StyledFormControl>
      <InputLabel size={'small'} id="custom-select-label">{label}</InputLabel>
      <MuiSelect
        size={'small'}
        labelId="custom-select-label"
        id="custom-select"
        label={label}
        value={value}
        defaultValue={defaultValue || ''}
        disabled={!!disabled}
        required={!!required}
        onChange={onChange}
        renderValue={
          (selectedId) => (
            getLabel(selectedId)
          )}
        MenuProps={MenuProps}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {getComposedLabel(option)}
          </MenuItem>
        ))}
      </MuiSelect>
    </StyledFormControl>
  );
};

export const CustomSelectMultiple = ({options, value, label, optionsLabel, labelComposeFrom, onChange, disabled}) => {
  let labelField = optionsLabel || 'id';
  const getLabel = (selectedId) => {
    const found = (options || []).find(e => e.id === selectedId);
    return found ? found[labelField] : `UNKNOWN ID:${selectedId}`;
  };
  const getComposedLabel = (option) => {
    return labelComposeFrom.map(e => option[e]).join(' - ');
  };
  return (
    <div>
      <StyledFormControl>
        <InputLabel id="mutiple-chip-label">{label}</InputLabel>
        <MuiSelect
          fullWidth
          labelId="multiple-chip-label"
          id="multiple-chip"
          multiple
          value={value ? value : []}
          defaultValue={[]}
          onChange={onChange}
          disabled={!!disabled}
          input={<Input/>} //id="multiple-select-chip"
          renderValue={(selected) => (
            <StyledChipList>
              {(selected || []).map((value) => (
                <StyledChip key={value} label={getLabel(value)}/>
              ))}
            </StyledChipList>
          )}
          MenuProps={MenuProps}
        >
          {(options || []).map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {getComposedLabel(option)}
            </MenuItem>
          ))}
        </MuiSelect>
      </StyledFormControl>
    </div>
  );
};


export const AsyncSelect = (
  {
    fetchMore,
    model,
    field,
    value,
    labelComposeFrom,
    onChange,
    label,
    defaultValue,
    isSearchable = false,
    labelDelimiter = '',
    ...params
  }
) => {
  const getComposedLabel = (option) => {
    // Формируем текст для элемента в выпадающем списке
    return labelComposeFrom.map(e => {
      return option.originalData ? option.originalData[e] : option[e];
    }).filter(e => e).join(` ${labelDelimiter} `);
  };
  //TODO переделать, слишком сильно грузит
  const listToOptions = (list) => {
    // Переводим в понятный для react-select вид
    const res = list.map(e => {
        return {
          key: e.id,
          value: e.id,
          label: getComposedLabel(e),
          originalData: e
        };
      }
    );
    // console.log(res);
    return res;
  };

  const [allOptions, setAllOptions] = useState(listToOptions(model.list));

  useEffect(() => {
    if (!model.list.length) {
      setAllOptions([]);
    }
  }, [model.list]);

  const loadOptions = async (search, prevOptions) => {
    // Подгрузка новых элементов в список
    const fetched = await fetchMore(search);
    const newOptions = listToOptions(fetched.list);
    setAllOptions((prevState) => {
      return prevState.concat(newOptions); // Добавляем к массиву
    });
    let storeField;
    if (field === 'city') {
      storeField = 'cities';
    }
    if (field === 'department') {
      storeField = 'departments';
    }
    if (field === 'area') {
      storeField = 'areas';
    }
    if (field === 'internalExecutor') {
      storeField = 'executors';
    }
    return {
      options: newOptions,
      hasMore: fetched.list?.length > 1,
    };
  };

  const getValue = (optionNotInRange) => {
    //TODO переделать, слишком сильно грузит
    if (!value) {
      return null;
    }
    let found = allOptions.find(e => e.value === value);
    if (found) {
      found.label = getComposedLabel(found.originalData);
    } else {
      if (optionNotInRange) { // Если есть элемент вне выдаваемого списка
        return {
          key: optionNotInRange?.id,
          value: optionNotInRange?.id,
          label: getComposedLabel(optionNotInRange),
        };
      }
    }
    return found;
  };

  const selectStyles = {menuPortal: (zzz) => ({...zzz, zIndex: 1400})}; // Для модального окна

  const [showOptions, setShowOptions] = useState(false);
  const {minSearchLength} = params;
  const handleInputChange = useCallback((typedOption) => {
    if (typedOption.length > (minSearchLength || 2)) {
      setShowOptions(true);
    } else {
      setShowOptions(false);
    }
  }, []);

  // console.log(model.list);
  return (
    <AsyncPaginate
      styles={selectStyles}
      menuPortalTarget={document.body}
      menuPosition={'fixed'}
      debounceTimeout={showOptions ? 800 : 99999} //костыль для поиска после нескольких букв
      options={listToOptions(model.list)}
      isSearchable={isSearchable}
      onInputChange={handleInputChange}
      value={getValue(defaultValue)}
      onChange={onChange}
      loadOptions={loadOptions}
      placeholder={label}
      noOptionsMessage={() => 'Нет результатов'}
      loadingMessage={() => 'Загрузка'}
      isClearable={true}
      escapeClearsValue={true}
      cacheOptions={false}
    />
  );
};