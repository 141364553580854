export const GET_EXECUTORS = 'GET_EXECUTORS';
export const GET_EXECUTORS_ERROR = 'GET_EXECUTORS_ERROR';
export const GET_EXECUTORS_SUCCESS = 'GET_EXECUTORS_SUCCESS';

export const GET_EXECUTOR = 'GET_EXECUTOR';
export const GET_EXECUTOR_ERROR = 'GET_EXECUTOR_ERROR';
export const GET_EXECUTOR_SUCCESS = 'GET_EXECUTOR_SUCCESS';

export const EDIT_EXECUTOR = 'EDIT_EXECUTOR';

export const ADD_EXECUTOR = 'ADD_EXECUTOR';
export const ADD_EXECUTOR_ERROR = 'ADD_EXECUTOR_ERROR';
export const ADD_EXECUTOR_SUCCESS = 'ADD_EXECUTOR_SUCCESS';

export const UPDATE_EXECUTOR = 'UPDATE_EXECUTOR';
export const UPDATE_EXECUTOR_ERROR = 'UPDATE_EXECUTOR_ERROR';
export const UPDATE_EXECUTOR_SUCCESS = 'UPDATE_EXECUTOR_SUCCESS';

export const REMOVE_EXECUTOR = 'REMOVE_EXECUTOR';
export const REMOVE_EXECUTOR_ERROR = 'REMOVE_EXECUTOR_ERROR';
export const REMOVE_EXECUTOR_SUCCESS = 'REMOVE_EXECUTOR_SUCCESS';

export const EXECUTORS_CHANGE_PARAMS = 'EXECUTORS_CHANGE_PARAMS';
export const EXECUTORS_UPDATE_PARAMS = 'EXECUTORS_UPDATE_PARAMS';
export const EXECUTORS_RESET_PARAMS = 'EXECUTORS_RESET_PARAMS';
export const EXECUTORS_CHANGE_PAGE = 'EXECUTORS_CHANGE_PAGE';
export const EXECUTORS_CLEAR = 'EXECUTORS_CLEAR';