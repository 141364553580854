import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {Box, Button, Grid} from '@mui/material';
import {Columns, CustomPagination, onSortingChange, SearchAndAddToolbar, selectionChange} from '../components/dataGrid';
import {departmentsGroupsActions} from '../redux/actions';
import {generateEmptyErrorsAndValidation} from '../config/validationRules';
import {validateForm} from '../helpers';
import {EditPageContainer, StyledContainer, StyledDataGrid} from '../components/styledComponents';
import strings from '../config/strings';
import {useDebounce} from '../components/hooks';
import {ConfirmRemovingModal} from '../components/modal';
import {getTextField} from '../components/fieldsInItem';
import {renderItemSkeleton} from '../components/skeletons';

export const DepartmentsGroupsScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const model = useSelector(state => state.departmentsGroups);
  const columns = [
    Columns.id(),
    Columns.groupName(),
  ];

  const baseActions = departmentsGroupsActions;
  useEffect(() => {
      dispatch(baseActions.getList(model.params));
    }, [model.page, model.params.search, model.params.ordering]
  );
  const rowSelected = ({id}) => {
    navigate(`/departments_groups/${id}`);
  };

  const [searchText, setSearchText] = useState('');
  useEffect(() => {
    model.params.search = searchText;
    model.params.offset = 0;
    dispatch(departmentsGroupsActions.setParams(model.params));
    dispatch(departmentsGroupsActions.setPage(1));
  }, [useDebounce(searchText, 800)]);

  return (
    <div style={{height: '90%', width: '100%'}}>
      <StyledDataGrid
        getRowClassName={() => 'styled'}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        filterMode="server"
        sortingMode="server"
        paginationMode="server"
        columns={columns}
        rows={model.list}
        onSortModelChange={onSortingChange(model, dispatch, baseActions)}
        onRowClick={rowSelected}
        selectionModel={model.selected}
        onSelectionModelChange={selectionChange(model, dispatch, baseActions)}
        loading={model.loading}
        components={{
          Toolbar: SearchAndAddToolbar,
          Pagination: CustomPagination(model, dispatch, baseActions),
        }}
        componentsProps={{
          toolbar: {
            path: 'departments_groups',
            value: searchText,
            onChange: (event) => setSearchText(event.target.value),
            clearSearch: () => setSearchText(''),
          },
        }}
      />
    </div>
  );
};


const {fieldsInitState, fieldsValidators} = generateEmptyErrorsAndValidation([
  'title',
]);


export const DepartmentGroupEditScreen = () => {
  const dispatch = useDispatch();
  const {id} = useParams();
  const navigate = useNavigate();

  const model = useSelector(state => state.departmentsGroups);

  const [state, setState] = useState(fieldsInitState);

  const handleChange = (field) => (e) => {
    setState(prevState => ({
      ...prevState, [field]: {
        'error': false,
        'helperText': null,
      }
    }));
    let updated = model.item;
    updated[field] = e.target.value;
    dispatch(departmentsGroupsActions.editItem(updated));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = validateForm(model.item, fieldsValidators, setState);
    if (data) {
      dispatch(departmentsGroupsActions.updateItem(id, data)).then(() => {
        location.reload();
      });
    }
  };

  const handleCancel = () => {
    dispatch(departmentsGroupsActions.editItem({}));
    navigate('/departments_groups');
  };

  const [removing, setRemoving] = useState(false);
  const handleRemove = () => {
    setRemoving(true);
  };
  const closeRemoving = () => {
    setRemoving(false);
  };

  const confirmRemove = () => {
    dispatch(departmentsGroupsActions.removeItem(id)).then(() => {
      setRemoving(false);
      navigate('/departments_groups');
    });
  };

  useEffect(() => {
      dispatch(departmentsGroupsActions.getItem(id)).then(res => {
      });
    }, []
  );

  return (
    <EditPageContainer>
      {!model.loading && model.item ?
        <ConfirmRemovingModal
          open={!!removing}
          onClose={closeRemoving}
          onConfirm={confirmRemove}
          text={`Группа подразделений ID:${id} - ${model.item.title}`}
        /> : ''
      }
      <StyledContainer maxWidth="xs">
        {!model.loading && model.item ?
          <form onSubmit={handleSubmit}>
            <Box sx={{flexGrow: 1}}>
              <Grid container spacing={4}>
                {getTextField(model, state, handleChange, 'id', {disabled: true})}
                {getTextField(model, state, handleChange, 'name')}
                <Grid container={true} mt={4} mb={4} justifyContent={'space-around'}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {strings.button.save}
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={handleCancel}
                  >
                    {strings.button.cancel}
                  </Button>
                  <Button
                    type="button"
                    color="secondary"
                    variant="contained"
                    onClick={handleRemove}
                  >
                    {strings.button.remove}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form> : renderItemSkeleton()
        }
      </StyledContainer>
    </EditPageContainer>
  );
};


export const DepartmentGroupAddScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const model = useSelector(state => state.departmentsGroups);

  const [state, setState] = useState(fieldsInitState);

  const handleChange = (field) => (e) => {
    setState(prevState => ({
      ...prevState, [field]: {
        'error': false,
        'helperText': null,
      }
    }));
    let updated = model.item;
    updated[field] = e.target?.value;
    dispatch(departmentsGroupsActions.editItem(updated));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = validateForm(model.item, fieldsValidators, setState);
    if (data) {
      dispatch(departmentsGroupsActions.addItem(data)).then(() => {
        navigate('/departments_groups');
      });
    }
  };

  const handleCancel = () => {
    dispatch(departmentsGroupsActions.editItem({}));
    navigate('/departments_groups');
  };

  useEffect(() => {
      dispatch(departmentsGroupsActions.editItem({}));
    }, []
  );

  return (
    <EditPageContainer>
      <StyledContainer maxWidth="xs">
        {!model.loading && model.item ?
          <form onSubmit={handleSubmit}>
            <Box sx={{flexGrow: 1}}>
              <Grid container spacing={4}>

                {getTextField(model, state, handleChange, 'name')}
                <Grid container={true} mt={4} mb={4} justifyContent={'space-around'}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {strings.button.save}
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={handleCancel}
                  >
                    {strings.button.cancel}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form> : renderItemSkeleton()
        }
      </StyledContainer>
    </EditPageContainer>
  );
};