export const GET_USERS = 'GET_USERS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';

export const GET_USER = 'GET_USER';
export const GET_USER_ERROR = 'GET_USER_ERROR';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';

export const EDIT_USERS = 'EDIT_USERS';
export const CLEAR_USERS_CREDENTIALS = 'CLEAR_USERS_CREDENTIALS';

export const REGENERATE_USERS_CREDENTIALS = 'REGENERATE_USERS_CREDENTIALS';
export const REGENERATE_USERS_CREDENTIALS_ERROR = 'REGENERATE_USERS_CREDENTIALS_ERROR';
export const REGENERATE_USERS_CREDENTIALS_SUCCESS = 'REGENERATE_USERS_CREDENTIALS_SUCCESS';

export const ADD_USERS = 'ADD_USERS';
export const ADD_USERS_ERROR = 'ADD_USERS_ERROR';
export const ADD_USERS_SUCCESS = 'ADD_USERS_SUCCESS';

export const UPDATE_USERS = 'UPDATE_USERS';
export const UPDATE_USERS_ERROR = 'UPDATE_USERS_ERROR';
export const UPDATE_USERS_SUCCESS = 'UPDATE_USERS_SUCCESS';

export const REMOVE_USERS = 'REMOVE_USERS';
export const REMOVE_USERS_ERROR = 'REMOVE_USERS_ERROR';
export const REMOVE_USERS_SUCCESS = 'REMOVE_USERS_SUCCESS';

export const USERS_CHANGE_PARAMS = 'USERS_CHANGE_PARAMS';
export const USERS_UPDATE_PARAMS = 'USERS_UPDATE_PARAMS';
export const USERS_RESET_PARAMS = 'USERS_RESET_PARAMS';
export const USERS_CLEAR = 'USERS_CLEAR';
export const USERS_CHANGE_PAGE = 'USERS_CHANGE_PAGE';

export const SELECT_USERS = 'SELECT_USERS';
export const UNSELECT_USERS = 'UNSELECT_USERS';