import * as constants from '../constants/emails';
import {pagination} from '../../config/settings';
import * as _ from 'lodash';

export const initialState = {
  item: null,
  list: [],
  totalCount: 0,
  loading: false,
  params: {
    ordering: '-id', limit: pagination.limit, offset: 0, search: null
  },
  pagesCount: 1,
  page: 1,
};

export default (state =  _.cloneDeep(initialState), action) => {
  switch (action.type) {
    case constants.GET_EMAILS:
    case constants.GET_EMAIL:
    case constants.ADD_EMAIL:
    case constants.UPDATE_EMAIL:
    case constants.REMOVE_EMAIL:
      return {
        ...state,
        loading: true
      };

    case constants.ADD_EMAIL_ERROR:
    case constants.GET_EMAILS_ERROR:
    case constants.GET_EMAIL_ERROR:
    case constants.UPDATE_EMAIL_ERROR:
    case constants.REMOVE_EMAIL_ERROR:
      return {
        ...state,
        loading: false,
      };

    case constants.GET_EMAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
        // item: null,
        totalCount: action.totalCount,
        pagesCount: Math.ceil(action.totalCount / pagination.limit)
      };

    case constants.ADD_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case constants.GET_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        // list: [],
        item: action.payload,
      };

    case constants.EDIT_EMAIL:
      return {
        ...state,
        item: action.data,
      };
    case constants.EMAILS_CHANGE_PARAMS:
      return {
        ...state,
        params: action.data,
      };
    case constants.EMAILS_UPDATE_PARAMS:
      return {
        ...state,
        paramsChanged: true,
        params: action.data !== null ? _.merge(state.params, action.data) : _.cloneDeep(initialState.params)
      };
    case constants.EMAILS_RESET_PARAMS:
      return {
        ...state,
        clearing: false,
        paramsChanged: false,
        // Сохраняем фильтрацию по статусу для вкладок подменю
        params: Object.assign({status: state.params.status}, _.cloneDeep(initialState.params))
      };
    case constants.EMAILS_CHANGE_PAGE:
      return {
        ...state,
        page: +action.data,
      };

    case constants.EMAILS_CLEAR:
      return {
        ...state,
        item: null,
        totalCount: 0,
        pagesCount: 1,
        page: 1,
        list: [],
      };

    default:
      return state;
  }
};