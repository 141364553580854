import * as authConstants from '../constants/auth';
import {APIHelper} from '../../helpers';
import {path} from '../../config/api';

export const authActions = {
  login,
  logout,
  getProfile,
  getLocalStorageData,
  changePassword
};

function login(email, password) {
  return dispatch => {
    dispatch(request());
    return APIHelper.post(
      `${path.auth.login}`,
      {email, password}
    ).then(
      res => {
        dispatch(success(res.data));
        return Promise.resolve(res.data);
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: authConstants.LOGIN};
  }

  function success(payload) {
    localStorage.setItem('xAuthToken', JSON.stringify(payload.token));
    localStorage.setItem('authGroups', JSON.stringify(payload.groups));
    return {type: authConstants.LOGIN_SUCCESS, payload};
  }

  function failure(error) {
    return {type: authConstants.LOGIN_ERROR, error};
  }
}

function logout() {
  localStorage.removeItem('xAuthToken');
  localStorage.removeItem('authGroups');
  return {type: authConstants.LOGOUT};
}

function getProfile() {
  return dispatch => {
    dispatch(request());
    return APIHelper.get(
      `${path.profile}`,
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: authConstants.GET_PROFILE};
  }

  function success(payload) {
    return {type: authConstants.GET_PROFILE_SUCCESS, payload};
  }

  function failure(error) {
    return {type: authConstants.GET_PROFILE_ERROR, error};
  }
}

function getLocalStorageData() {
  return {
    type: authConstants.GET_LOCAL_CREDENTIALS,
    payload: {
      xAuthToken: localStorage.getItem('xAuthToken')
    }
  };
}

function changePassword(password) {
  return dispatch => {
    dispatch(request());
    return APIHelper.post(
      `${path.password}`,
      {password}
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: authConstants.CHANGE_PASSWORD};
  }

  function success(payload) {
    return {type: authConstants.CHANGE_PASSWORD_SUCCESS, payload};
  }

  function failure(error) {
    return {type: authConstants.CHANGE_PASSWORD_ERROR, error};
  }
}