import * as unitsConstants from '../constants/units';
import {APIHelper} from '../../helpers';
import {path} from '../../config/api';

export const unitsActions = {
  getList,
  getItem,
  addItem,
  updateItem,
  editItem,
  removeItem,
  setPage,
  setParams,
  updateParams,
  resetParams,
  clear,
};

function getList(params) {
  return dispatch => {
    dispatch(request());
    return APIHelper.get(
      `${path.geo.units}`,
      {
        params
      }
    ).then(
      res => {
        dispatch(success(res?.data?.results, parseInt(res?.data?.count || 0)));
        return {
          list: res?.data?.results, totalCount: parseInt(res?.data?.count || 0)
        };
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: unitsConstants.GET_UNITS};
  }

  function success(payload, totalCount) {
    return {type: unitsConstants.GET_UNITS_SUCCESS, payload, totalCount};
  }

  function failure(error) {
    return {type: unitsConstants.GET_UNITS_ERROR, error};
  }
}

function getItem(id) {
  return dispatch => {
    dispatch(request());
    return APIHelper.get(
      `${path.geo.units}/${id}`
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: unitsConstants.GET_UNIT};
  }

  function success(payload) {
    if (payload.permissions.length) { // TODO переделать этот костыль
      payload['permissions'] = payload.permissions.map(e => e.id);
    }
    return {type: unitsConstants.GET_UNIT_SUCCESS, payload};
  }

  function failure(error) {
    return {type: unitsConstants.GET_UNIT_ERROR, error};
  }
}

function addItem(data) {
  return dispatch => {
    dispatch(request());
    return APIHelper.post(
      `${path.geo.units}`,
      data
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: unitsConstants.ADD_UNIT};
  }

  function success(payload) {
    return {type: unitsConstants.ADD_UNIT_SUCCESS, payload};
  }

  function failure(error) {
    return {type: unitsConstants.ADD_UNIT_ERROR, error};
  }
}

function updateItem(id, data) {
  return dispatch => {
    dispatch(request());
    return APIHelper.put(
      `${path.geo.units}/${id}`,
      data
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: unitsConstants.UPDATE_UNIT};
  }

  function success(payload) {
    return {type: unitsConstants.UPDATE_UNIT_SUCCESS, payload};
  }

  function failure(error) {
    return {type: unitsConstants.UPDATE_UNIT_ERROR, error};
  }
}

function editItem(data) {
  return {type: unitsConstants.EDIT_UNIT, data};
}

function removeItem(id) {
  return (dispatch) => {
    dispatch(request());
    return APIHelper.delete(
      `${path.geo.units}/${id}`,
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: unitsConstants.REMOVE_UNIT};
  }

  function success(payload) {
    return {type: unitsConstants.REMOVE_UNIT_SUCCESS, payload};
  }

  function failure(error) {
    return {type: unitsConstants.REMOVE_UNIT_ERROR, error};
  }
}

function setParams(data) {
  return {type: unitsConstants.UNITS_CHANGE_PARAMS, data};
}

function updateParams(data) {
  return {type: unitsConstants.UNITS_UPDATE_PARAMS, data};
}

function resetParams() {
  return {type: unitsConstants.UNITS_RESET_PARAMS};
}

function clear() {
  return {type: unitsConstants.UNITS_CLEAR};
}

function setPage(data) {
  return {type: unitsConstants.UNITS_CHANGE_PAGE, data};
}