export const GET_GROUPS = 'GET_GROUPS';
export const GET_GROUPS_ERROR = 'GET_GROUPS_ERROR';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';

export const GET_GROUP = 'GET_GROUP';
export const GET_GROUP_ERROR = 'GET_GROUP_ERROR';
export const GET_GROUP_SUCCESS = 'GET_GROUP_SUCCESS';

export const EDIT_GROUP = 'EDIT_GROUP';

export const ADD_GROUP = 'ADD_GROUP';
export const ADD_GROUP_ERROR = 'ADD_GROUP_ERROR';
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';

export const UPDATE_GROUP = 'UPDATE_GROUP';
export const UPDATE_GROUP_ERROR = 'UPDATE_GROUP_ERROR';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';

export const REMOVE_GROUP = 'REMOVE_GROUP';
export const REMOVE_GROUP_ERROR = 'REMOVE_GROUP_ERROR';
export const REMOVE_GROUP_SUCCESS = 'REMOVE_GROUP_SUCCESS';

export const GROUPS_CHANGE_PARAMS = 'GROUPS_CHANGE_PARAMS';
export const GROUPS_CHANGE_PAGE = 'GROUPS_CHANGE_PAGE';