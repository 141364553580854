import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {Box, Button, Grid} from '@mui/material';
import {
  Columns,
  CustomPagination,
  onSortingChange,
  renderCellExpand,
  SearchAndAddToolbar
} from '../components/dataGrid';
import {categoriesActions, mediaActions} from '../redux/actions';
import {generateEmptyErrorsAndValidation} from '../config/validationRules';
import {validateForm} from '../helpers';
import {EditPageContainer, StyledContainer, StyledDataGrid} from '../components/styledComponents';
import strings from '../config/strings';
import {useDebounce} from '../components/hooks';
import {getFileField, getRelatedImageField, getTextField} from '../components/fieldsInItem';
import {renderItemSkeleton} from '../components/skeletons';

export const CategoriesScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const model = useSelector(state => state.categories);
  const columns = [
    Columns.id(),
    Columns.name(renderCellExpand),
  ];

  const baseActions = categoriesActions;

  const [cleared, setCleared] = useState(false);

  useEffect(() => {
      // Сброс параметров
      dispatch(baseActions.resetParams());
      // Сброс хранилища по категориям
      dispatch(baseActions.clear());
      setCleared(true);
    }, []
  );
  useEffect(() => {
      if (cleared) {
        // Загрузка данных если был сброс
        dispatch(baseActions.getList(model.params));
      }
    }, [cleared, model.page, model.params.order, model.params.ordering]
  );

  const rowSelected = ({id}) => {
    navigate(`/categories/${id}`);
  };

  const [searchText, setSearchText] = useState('');
  useEffect(() => {
    if (cleared) {

      model.params.search = searchText;
      model.params.offset = 0;
      dispatch(categoriesActions.setParams(model.params));
      dispatch(categoriesActions.setPage(1));
    }
  }, [useDebounce(searchText, 800)]);

  return (
    <div style={{height: '90%', width: '100%'}}>
      <StyledDataGrid
        getRowClassName={() => 'styled'}
        getRowHeight={() => 'auto'}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        columns={columns}
        rows={model.list}
        onSortModelChange={onSortingChange(model, dispatch, baseActions)}
        filterMode="server"
        sortingMode="server"
        paginationMode="server"
        onRowClick={rowSelected}
        loading={model.loading}
        components={{
          Toolbar: SearchAndAddToolbar,
          Pagination: CustomPagination(model, dispatch, baseActions),
        }}
        componentsProps={{
          toolbar: {
            path: 'categories',
            value: searchText,
            onChange: (event) => setSearchText(event.target.value),
            clearSearch: () => setSearchText(''),
          },
        }}
      />
    </div>
  );
};


const {fieldsInitState, fieldsValidators} = generateEmptyErrorsAndValidation([
  'name',
  'description',
  // 'parent',
]);


export const CategoryEditScreen = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const {id} = useParams();
  const navigate = useNavigate();

  const model = useSelector(state => state.categories);
  const media = useSelector(state => state.media);

  const [state, setState] = useState(fieldsInitState);

  const handleChange = (field) => (e) => {
    setState(prevState => ({
      ...prevState, [field]: {
        'error': false,
        'helperText': null,
      }
    }));
    let updated = model.item;
    updated[field] = e.target.value;
    dispatch(categoriesActions.editItem(updated));
  };

  const handleSelectChange = (field) => (e) => {
    let updated = model.item;
    updated[field] = e.target.value;
    dispatch(categoriesActions.editItem(updated));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = validateForm(model.item, fieldsValidators, setState);
    if (data) {
      await Promise.all(media.uploadingMedia.map(async m => {
        if (m.file) {
          let formData = new FormData();
          formData.append('file', m.file);
          await dispatch(mediaActions.uploadFile(formData));
        }
      })).then(() => {
        const uploadedIDs = store.getState().media.uploadedIDs;
        if (uploadedIDs?.length) {
          // console.log(uploadedIDs);
          data['image'] = uploadedIDs[0];
        }
        dispatch(categoriesActions.updateItem(id, data)).then(() => {
          dispatch(mediaActions.clear());
          dispatch(categoriesActions.clear());
          window.location.reload();
        }).catch(e => {
          dispatch(mediaActions.clear());
        });
      });
    }
  };

  const handleCancel = () => {
    dispatch(categoriesActions.editItem({}));
    navigate('/categories');
  };

  useEffect(() => {
      dispatch(categoriesActions.clear());
      dispatch(categoriesActions.getItem(id));
      dispatch(categoriesActions.getList({}));
    }, []
  );

  return (
    <EditPageContainer>
      <StyledContainer maxWidth="xs">
        {!model.loading && !media.loading && model.item ?
          <form onSubmit={handleSubmit}>
            <Box sx={{flexGrow: 1}}>
              <Grid container spacing={4}>

                {getTextField(model, state, handleChange, 'id', {disabled: true})}
                {getTextField(model, state, handleChange, 'name')}
                {getTextField(model, state, handleChange, 'description')}
                {/*{getSelectMultipleField(categories, model, state, handleSelectChange, 'parent', 'name', ['id', 'name'])}*/}
                {getRelatedImageField(model, 'image', handleChange)}
                {getFileField(model, 'template', handleChange)}

                <Grid container={true} mt={4} mb={4} justifyContent={'space-around'}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {strings.button.save}
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={handleCancel}
                  >
                    {strings.button.cancel}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form> : renderItemSkeleton()
        }
      </StyledContainer>
    </EditPageContainer>
  );
};


export const CategoryAddScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const store = useStore();

  const model = useSelector(state => state.categories);
  const media = useSelector(state => state.media);

  const [state, setState] = useState(fieldsInitState);

  const handleChange = (field) => (e) => {
    setState(prevState => ({
      ...prevState, [field]: {
        'error': false,
        'helperText': null,
      }
    }));
    let updated = model.item;
    updated[field] = e.target?.value;
    dispatch(categoriesActions.editItem(updated));
  };

  const handleSelectChange = (field) => (e) => {
    let updated = model.item;
    updated[field] = e.target.value;
    dispatch(categoriesActions.editItem(updated));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = validateForm(model.item, fieldsValidators, setState);
    if (data) {
      await Promise.all(media.uploadingMedia.map(async m => {
        if (m.file) {
          let formData = new FormData();
          formData.append('file', m.file);
          await dispatch(mediaActions.uploadFile(formData));
        }
      })).then(() => {
        const uploadedIDs = store.getState().media.uploadedIDs;
        if (uploadedIDs?.length) {
          // console.log(uploadedIDs);
          data['image'] = uploadedIDs[0];
        }
        dispatch(categoriesActions.addItem(data)).then(() => {
          navigate('/categories');
        }).catch(e => {
          dispatch(mediaActions.clear());
        });
      });
    }
  };

  const handleCancel = () => {
    dispatch(categoriesActions.editItem({}));
    navigate('/categories');
  };

  useEffect(() => {
      dispatch(categoriesActions.editItem({}));
      dispatch(categoriesActions.getList({}));
    }, []
  );

  return (
    <EditPageContainer>
      <StyledContainer maxWidth="xs">
        {!model.loading && !media.loading && model.item ?
          <form onSubmit={handleSubmit}>
            <Box sx={{flexGrow: 1}}>
              <Grid container spacing={4}>
                {getTextField(model, state, handleChange, 'name')}
                {getTextField(model, state, handleChange, 'description')}
                {/*{getSelectMultipleField(categories, model, state, handleSelectChange, 'parent', 'name', ['id', 'name'])}*/}
                {getRelatedImageField(model, 'image', handleChange)}
                {getFileField(model, 'template', handleChange)}

                <Grid container={true} mt={4} mb={4} justifyContent={'space-around'}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {strings.button.save}
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={handleCancel}
                  >
                    {strings.button.cancel}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form> : renderItemSkeleton()
        }
      </StyledContainer>
    </EditPageContainer>
  );
};