export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';

export const GET_CATEGORY = 'GET_CATEGORY';
export const GET_CATEGORY_ERROR = 'GET_CATEGORY_ERROR';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';

export const EDIT_CATEGORY = 'EDIT_CATEGORY';

export const ADD_CATEGORY = 'ADD_CATEGORY';
export const ADD_CATEGORY_ERROR = 'ADD_CATEGORY_ERROR';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';

export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORY_ERROR = 'UPDATE_CATEGORY_ERROR';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';

export const REMOVE_CATEGORY = 'REMOVE_CATEGORY';
export const REMOVE_CATEGORY_ERROR = 'REMOVE_CATEGORY_ERROR';
export const REMOVE_CATEGORY_SUCCESS = 'REMOVE_CATEGORY_SUCCESS';

export const CATEGORIES_CHANGE_PARAMS = 'CATEGORIES_CHANGE_PARAMS';
export const CATEGORIES_UPDATE_PARAMS = 'CATEGORIES_UPDATE_PARAMS';
export const CATEGORIES_RESET_PARAMS = 'CATEGORIES_RESET_PARAMS';
export const CATEGORIES_CHANGE_PAGE = 'CATEGORIES_CHANGE_PAGE';
export const CATEGORIES_CLEAR = 'CATEGORIES_CLEAR';