export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const GET_PERMISSIONS_ERROR = 'GET_PERMISSIONS_ERROR';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';

export const GET_PERMISSION = 'GET_PERMISSION';
export const GET_PERMISSION_ERROR = 'GET_PERMISSION_ERROR';
export const GET_PERMISSION_SUCCESS = 'GET_PERMISSION_SUCCESS';

export const EDIT_PERMISSION = 'EDIT_PERMISSION';

export const ADD_PERMISSION = 'ADD_PERMISSION';
export const ADD_PERMISSION_ERROR = 'ADD_PERMISSION_ERROR';
export const ADD_PERMISSION_SUCCESS = 'ADD_PERMISSION_SUCCESS';

export const UPDATE_PERMISSION = 'UPDATE_PERMISSION';
export const UPDATE_PERMISSION_ERROR = 'UPDATE_PERMISSION_ERROR';
export const UPDATE_PERMISSION_SUCCESS = 'UPDATE_PERMISSION_SUCCESS';

export const REMOVE_PERMISSION = 'REMOVE_PERMISSION';
export const REMOVE_PERMISSION_ERROR = 'REMOVE_PERMISSION_ERROR';
export const REMOVE_PERMISSION_SUCCESS = 'REMOVE_PERMISSION_SUCCESS';

export const PERMISSIONS_CHANGE_PARAMS = 'PERMISSIONS_CHANGE_PARAMS';
export const PERMISSIONS_CHANGE_PAGE = 'PERMISSIONS_CHANGE_PAGE';