import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {Box, Button, Grid, IconButton, Paper, Popper, TextField, Typography} from '@mui/material';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import Pagination from '@mui/material/Pagination/Pagination';
import {Clear, Search} from '@mui/icons-material';
import {GridToolbarContainer} from '@mui/x-data-grid';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {pagination} from '../config/settings';
import strings from '../config/strings';
import {FilterDatePicker} from './datePickers';
import {
  areasActions,
  categoriesActions,
  citiesActions,
  departmentsActions,
  departmentsGroupsActions,
  executorsActions,
  issuesActions,
  unitsActions,
  usersActions
} from '../redux/actions';
import {FilterAsyncSelectField} from './fieldsInItem';
import {useDispatch, useSelector} from 'react-redux';
import {FiltersTextField, NotificationOrderHeader} from './styledComponents';
import {blue, blueGrey, green} from '@mui/material/colors';

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const {width, value} = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: 280,
        height: 10,
        position: 'relative',
        display: 'flex',
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: 1,
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{width, marginLeft: -17}}
        >
          <Paper
            elevation={1}
            style={{minHeight: wrapper.current.offsetHeight - 3}}
          >
            <Typography variant="body2" style={{padding: 8, wordBreak: 'break-word'}}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

GridCellExpand.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

export const renderCellExpand = (params) => {
  return (
    <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth}/>
  );
};

renderCellExpand.propTypes = {
  /**
   * The column of the row that the current cell belongs to.
   */
  colDef: PropTypes.object.isRequired,
  /**
   * The cell value, but if the column has valueGetter, use getValue.
   */
  value: PropTypes.string.isRequired,
};

export const CustomPagination = (model, dispatch, actions = null, setPage = null) => () => {

  const handlePageChange = (e, p) => {
    model.params.offset = (p - 1) * pagination.limit;
    if (actions) {
      dispatch(actions.setPage(p));
    } else if (setPage) {
      dispatch(setPage(p));
    }
  };

  return (
    <Pagination
      // className={classes.paginator}
      count={model.pagesCount}
      page={model.page}
      showFirstButton
      showLastButton
      onChange={handlePageChange}
    />
  );
};

export const onSortingChange = (model, dispatch, actions = null, setParams = null) => (newSortModelArray) => {
  const {params} = model;
  const ordering = params?.ordering;
  const field = ordering.replace('-', '');
  const sort = ordering.indexOf('-') !== -1 ? 'desc' : 'asc';
  const newSortModel = newSortModelArray.length ? newSortModelArray[0] : {};
  if (!newSortModel || field !== newSortModel.field || sort !== newSortModel.sort) {
    params.ordering = `${newSortModel.sort === 'desc' ? '-' : ''}${(newSortModel.field || '').split(/(?=[A-Z])/).join('_').toLowerCase()}`;
    if (actions) {
      dispatch(actions.setParams(params));
    } else if (setParams) {
      dispatch(setParams(params));
    }
  }
};

export const selectionChange = (model, dispatch, actions = null, setParams = null) => (newSelection) => {
  if (actions) {
    dispatch(actions.select(newSelection));
  }
};

export const NoRowsOverlay = () => (
  <Box height="100%" alignItems="center" justifyContent="center">
    No rows in DataGrid
  </Box>
);

export const NoResultsOverlay = () => (
  <Box height="100%" alignItems="center" justifyContent="center">
    Local filter returns no result
  </Box>
);

export const renderBoolean = (params) => {
  if (params.value) {
    return <CheckCircleOutlineRoundedIcon color="secondary"/>;
  } else {
    return <HighlightOffRoundedIcon color="primary"/>;
  }
};

export const renderValueFromEnum = (options = null) => (params) => {
  return options?.length ? options.find(e => e.id === params.value)?.title : null;
};

export const SearchAndAddToolbar = (props) => {
  const navigate = useNavigate();
  return (
    <GridToolbarContainer sx={{
      alignItems: 'center',
      marginBottom: 4
    }}>
      <Grid sx={{
        margin: 0
      }}>
        <Button
          sx={{
            // minWidth: '100px'
          }}
          onClick={() => {
            navigate(`/${props.path}/add`);
          }}
          color="primary"
          type="submit"
          variant="contained">
          {strings.button.add}
        </Button>
      </Grid>
      <Grid item mx={2}>
        <SearchToolbar value={props.value} onChange={props.onChange} clearSearch={props.clearSearch} props={props}/>
      </Grid>
    </GridToolbarContainer>
  );
};

export const IssuesSearchAddFilterToolbar = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {handleFiltersChange, handleFiltersClear, handleFiltersApply, model, disableAdding} = props;

  const issues = useSelector(state => state.issues);
  const categories = useSelector(state => state.categories);
  const users = useSelector(state => state.users);
  const executors = useSelector(state => state.executors);
  const cities = useSelector(state => state.cities);
  const areas = useSelector(state => state.areas);
  const units = useSelector(state => state.units);
  const departments = useSelector(state => state.departments);

  const fetchMore = (actions, optionsModel) => async (search) => {
    let _search = !search ? '' : search;
    let _modelSearch = !optionsModel.params.search ? '' : optionsModel.params.search;
    const offset = _search === _modelSearch ? optionsModel.params.offset + pagination.limit : 0;

    let newParams = {
      search: search
    };
    if (_search !== _modelSearch) {
      newParams = {
        offset: offset,
        search: search
      };
    }

    const reqParams = Object.assign({}, optionsModel.params, newParams);

    const result = _search === _modelSearch ? await dispatch(actions.getList(reqParams)) : [];

    let params = {
      limit: pagination.limit,
      ordering: 'id',
      offset: offset,
      search: search
    };

    // if (optionsModel === users) {
    //   params['type'] = 4;
    // }
    dispatch(actions.updateParams(params)); //TODO проверить подгрузку
    return result;
  };

  const handleAsyncSelectChange = (field) => (e) => {
    (handleFiltersChange(field))(e?.value);
  };

  React.useEffect(() => {
    dispatch(issuesActions.resetParams());
    dispatch(areasActions.clear());
    dispatch(areasActions.resetParams());
    dispatch(categoriesActions.clear());
    dispatch(categoriesActions.resetParams());
    dispatch(citiesActions.clear());
    dispatch(citiesActions.resetParams());
    dispatch(executorsActions.clear());
    dispatch(executorsActions.resetParams());
    dispatch(unitsActions.clear());
    dispatch(unitsActions.resetParams());
    dispatch(usersActions.clear());
    dispatch(usersActions.resetParams());
    dispatch(departmentsActions.clear());
    dispatch(departmentsActions.resetParams());
  }, []);

  const applyFilters = () => {
    handleFiltersApply();
  };

  const clearFilters = async () => { // TODO задваиваются выпадающие списки при сбросе фильтров, подгружаются сначала но из select не убираются
    await Promise.all([
      dispatch(areasActions.clear()),
      dispatch(areasActions.resetParams()),
      dispatch(categoriesActions.clear()),
      dispatch(categoriesActions.resetParams()),
      dispatch(citiesActions.clear()),
      dispatch(citiesActions.resetParams()),
      dispatch(executorsActions.clear()),
      dispatch(executorsActions.resetParams()),
      dispatch(unitsActions.clear()),
      dispatch(unitsActions.resetParams()),
      dispatch(usersActions.clear()),
      dispatch(usersActions.resetParams()),
      dispatch(departmentsActions.clear()),
      dispatch(departmentsActions.resetParams()),
    ]);
    handleFiltersClear();
  };

  return (
    <GridToolbarContainer sx={{
      alignItems: 'center',
      marginBottom: 4,
      // minWidth: 1300,
    }}>
      {disableAdding !== true && <Grid sx={{
        margin: 0
      }}>
        <Button
          sx={{
            // minWidth: '100px'
          }}
          onClick={() => {
            navigate(`/${props.path}/add`);
          }}
          color="primary"
          type="submit"
          variant="contained">
          {strings.button.add}
        </Button>
      </Grid>}
      <Grid container mt={0} mb={4}>
        <Grid container
              sx={{
                borderRadius: 1,
                backgroundColor: blueGrey[50]
              }}
        >
          <Grid container item md={12} xs={12} lg={12} mb={1}>
            <FilterAsyncSelectField
              optionsModel={areas}
              field={'area'}
              value={model.params['area']}
              fetchMore={fetchMore(areasActions, areas)}
              handleAsyncSelectChange={handleAsyncSelectChange}
              labelComposeFrom={['name']}
              isSearchable={true}
            />
            <FilterAsyncSelectField
              optionsModel={cities}
              field={'city'}
              value={model.params['city']}
              fetchMore={fetchMore(citiesActions, cities)}
              handleAsyncSelectChange={handleAsyncSelectChange}
              labelComposeFrom={['title', 'areaName']}
              labelDelimiter={' | '}
              isSearchable={true}
            />
            <FilterAsyncSelectField
              optionsModel={units}
              field={'unit'}
              value={model.params['unit']}
              fetchMore={fetchMore(unitsActions, units)}
              handleAsyncSelectChange={handleAsyncSelectChange}
              labelComposeFrom={['title']}
              isSearchable={true}
              minSearchLength={1}
            />
            <FilterAsyncSelectField
              optionsModel={users}
              field={'userId'}
              value={model.params['userId']}
              fetchMore={fetchMore(usersActions, users)}
              handleAsyncSelectChange={handleAsyncSelectChange}
              labelComposeFrom={['lastName', 'firstName', 'email']}
              isSearchable={true}
            />
            <FilterAsyncSelectField
              optionsModel={executors}
              field={'internalExecutor'}
              value={model.params['internalExecutor']}
              fetchMore={fetchMore(executorsActions, executors)}
              handleAsyncSelectChange={handleAsyncSelectChange}
              labelComposeFrom={['name', 'email']}
              isSearchable={true}
            />

          </Grid>
          <Grid container item md={12} xs={12} lg={12} mb={1}>
            <FilterDatePicker
              size={'small'}
              label={strings['createdFrom']}
              name={'createdFrom'}
              value={model.params['createdFrom']}
              onChange={(dateObj) => (handleFiltersChange('createdFrom'))(dateObj ? dateObj.getTime() : null)}
            />
            <FilterDatePicker
              size={'small'}
              label={strings['createdTo']}
              name={'createdTo'}
              value={model.params['createdTo']}
              onChange={(dateObj) => (handleFiltersChange('createdTo'))(dateObj ? dateObj.getTime() : null)}
            />
            <FiltersTextField
              size={'small'}
              label={strings['shopName']}
              value={model.params['shopName'] || ''}
              onChange={e => (handleFiltersChange('shopName'))(e.target.value)}
            />
            <FiltersTextField
              size={'small'}
              label={strings['address']}
              value={model.params['address']}
              onChange={e => (handleFiltersChange('address'))(e.target.value)}
            />
            <FiltersTextField
              size={'small'}
              label={strings['legalEntity']}
              value={model.params['legalEntity'] || ''}
              onChange={e => (handleFiltersChange('legalEntity'))(e.target.value)}
            />
            <FilterAsyncSelectField
              optionsModel={categories}
              field={'category'}
              value={model.params['category']}
              fetchMore={fetchMore(categoriesActions, categories)}
              handleAsyncSelectChange={handleAsyncSelectChange}
              labelComposeFrom={['name']}
              isSearchable={true}
            />
          </Grid>
          <Grid container item md={12} xs={12} lg={12} mb={0}>
            <FiltersTextField
              size={'small'}
              label={strings['kusp']}
              value={model.params['kusp'] || ''}
              onChange={e => (handleFiltersChange('kusp'))(e.target.value)}
            />
            <FilterAsyncSelectField
              optionsModel={departments}
              field={'department'}
              value={model.params['department']}
              fetchMore={fetchMore(departmentsActions, departments)}
              handleAsyncSelectChange={handleAsyncSelectChange}
              labelComposeFrom={['title']}
              isSearchable={true}
              minSearchLength={3}
            />
          </Grid>
        </Grid>
        <Grid container pt={4} pb={1} pl={1} pr={1}>
          <Button onClick={applyFilters} sx={{
            borderColor: blue[500],
            borderStyle: 'solid',
            borderRadius: 1,
            borderWidth: !issues.paramsChanged ? 0 : 1
          }}>Применить</Button>
          <Button onClick={clearFilters}>Сбросить</Button>
        </Grid>
      </Grid>
      <Grid item mx={2}>
        <SearchToolbar value={props.value} onChange={props.onChange} clearSearch={props.clearSearch} props={props}/>
      </Grid>
    </GridToolbarContainer>
  );
};

export const DepartmentsSearchAddFilterGroupToolbar = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {handleFiltersChange, handleFiltersClear, handleFiltersApply, model, disableAdding, startGrouping} = props;

  const departmentsGroups = useSelector(state => state.departmentsGroups);
  const departments = useSelector(state => state.departments);

  const fetchMore = (actions, optionsModel) => async (search) => {
    let _search = !search ? '' : search;
    let _modelSearch = !optionsModel.params.search ? '' : optionsModel.params.search;
    const offset = _search === _modelSearch ? optionsModel.params.offset + pagination.limit : 0;

    let newParams = {
      search: search
    };
    if (_search !== _modelSearch) {
      newParams = {
        offset: offset,
        search: search
      };
    }

    const reqParams = Object.assign({}, optionsModel.params, newParams);

    const result = _search === _modelSearch ? await dispatch(actions.getList(reqParams)) : [];

    let params = {
      limit: pagination.limit,
      ordering: 'id',
      offset: offset,
      search: search
    };

    dispatch(actions.updateParams(params)); //TODO проверить подгрузку
    return result;
  };

  const handleAsyncSelectChange = (field) => (e) => {
    (handleFiltersChange(field))(e?.value);
  };

  React.useEffect(() => {
    dispatch(departmentsActions.clear());
  }, []);

  const applyFilters = () => {
    handleFiltersApply();
  };

  const clearFilters = async () => {
    await Promise.all([
      dispatch(departmentsActions.clear()),
    ]);
    handleFiltersClear();
  };

  return (
    <GridToolbarContainer sx={{
      alignItems: 'center',
      marginBottom: 4,
      // minWidth: 1300,
    }}>
      {disableAdding !== true && <Grid sx={{
        margin: 0
      }}>
        <Button
          sx={{
            // minWidth: '100px'
          }}
          onClick={() => {
            navigate(`/${props.path}/add`);
          }}
          color="primary"
          type="submit"
          variant="contained">
          {strings.button.add}
        </Button>
      </Grid>}
      {departments.selected && departments.selected.length ?
        <Grid sx={{
          marginLeft: 4
        }}>
          <Button
            sx={{
              // minWidth: '100px'
            }}
            onClick={startGrouping}
            color="primary"
            type="submit"
            variant="contained">
            {strings.button.manageGroup}
          </Button>
        </Grid> : ''
      }
      <Grid container mt={0} mb={4}>
        <Grid container xs={12}
              sx={{
                borderRadius: 1,
                backgroundColor: blueGrey[50]
              }}
        >
          <Grid container item md={12} xs={12} lg={12}>
            <FilterAsyncSelectField
              optionsModel={departmentsGroups}
              field={'group'}
              value={model.params['group']}
              fetchMore={fetchMore(departmentsGroupsActions, departmentsGroups)}
              handleAsyncSelectChange={handleAsyncSelectChange}
              labelComposeFrom={['name']}
              isSearchable={true}
            />

          </Grid>
        </Grid>
        <Grid container pt={4} pb={1} pl={1} pr={1}>
          <Button onClick={applyFilters} sx={{
            borderColor: blue[500],
            borderStyle: 'solid',
            borderRadius: 1,
            borderWidth: !departmentsGroups.paramsChanged ? 0 : 1
          }}>Применить</Button>
          <Button onClick={clearFilters}>Сбросить</Button>
        </Grid>
      </Grid>
      <Grid item mx={2}>
        <SearchToolbar value={props.value} onChange={props.onChange} clearSearch={props.clearSearch} props={props}/>
      </Grid>
    </GridToolbarContainer>
  );
};

export const ProfilesSearchAddRemoveToolbar = (props) => {
  const navigate = useNavigate();
  const {disableAdding, handleRemove} = props;

  const users = useSelector(state => state.users);

  return (
    <GridToolbarContainer sx={{
      alignItems: 'center',
      marginBottom: 4,
    }}>
      {disableAdding !== true && <Grid sx={{
        margin: 0
      }}>
        <Button
          onClick={() => {
            navigate(`/${props.path}/add`);
          }}
          color="primary"
          type="submit"
          variant="contained">
          {strings.button.add}
        </Button>
      </Grid>}
      {users.selected && users.selected.length ?
        <Grid sx={{
          marginLeft: 4
        }}>
          <Button
            onClick={handleRemove}
            color="secondary"
            type="submit"
            variant="contained">
            {strings.button.remove}
          </Button>
        </Grid> : ''
      }
      <Grid item mx={2}>
        <SearchToolbar value={props.value} onChange={props.onChange} clearSearch={props.clearSearch} props={props}/>
      </Grid>
    </GridToolbarContainer>
  );
};

export const SearchToolbar = (props) => {
  return <TextField
    fullWidth={true}
    variant="standard"
    value={props.value}
    onChange={props.onChange}
    placeholder="Поиск…"
    InputProps={{
      startAdornment: <Search fontSize="small"/>,
      endAdornment: (
        <IconButton
          title="Очистить"
          aria-label="Очистить"
          size="small"
          style={{visibility: props.value ? 'visible' : 'hidden'}}
          onClick={props.clearSearch}
        >
          <Clear fontSize="small"/>
        </IconButton>
      ),
    }}
  />;
};

SearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export const Columns = {
  id: (renderCell = null) => ({
    headerAlign: 'center',
    align: 'center',
    field: 'id', headerName: 'ID', flex: 0, width: 70, renderCell
  }),
  type: (renderCell = null) => ({
    headerAlign: 'center',
    sortable: false,
    align: 'center',
    field: 'type',
    headerName: 'Тип профиля',
    flex: 0,
    width: 150,
    renderCell
  }),
  username: (renderCell = null) => ({
    headerAlign: 'center',
    align: 'center',
    field: 'username',
    headerName: 'username',
    flex: 1,
    minWidth: 150,
    renderCell
  }),
  email: (renderCell = null) => ({
    headerAlign: 'center',
    align: 'center',
    field: 'email',
    headerName: 'email',
    flex: 1,
    minWidth: 200,
    renderCell
  }),
  phone: (renderCell = null) => ({
    headerAlign: 'center',
    align: 'center',
    field: 'number',
    headerName: 'Телефон',
    flex: 1,
    minWidth: 150,
    renderCell
  }),
  sex: (renderCell = null) => ({
    headerAlign: 'center',
    align: 'center',
    field: 'sex',
    headerName: 'Пол',
    flex: 0,
    width: 80,
    renderCell
  }),
  lastName: (renderCell = null) => ({
    headerAlign: 'center',
    align: 'center',
    field: 'lastName',
    headerName: 'Фамилия',
    flex: 1,
    minWidth: 180,
    renderCell
  }),
  firstName: (renderCell = null) => ({
    headerAlign: 'center',
    align: 'center',
    field: 'firstName',
    headerName: 'Имя',
    flex: 1,
    minWidth: 180,
    renderCell
  }),
  middleName: (renderCell = null) => ({
    headerAlign: 'center',
    align: 'center',
    field: 'middleName',
    headerName: 'Отчество',
    flex: 1,
    minWidth: 180,
    renderCell
  }),
  name: (renderCell = null) => ({
    headerAlign: 'center',
    align: 'center',
    field: 'name',
    headerName: 'Название',
    flex: 0,
    width: 250,
    renderCell
  }),
  sender: (renderCell = null) => ({
    headerAlign: 'center',
    align: 'center',
    field: 'sender',
    headerName: 'Отправитель',
    flex: 0,
    width: 250,
    renderCell
  }),
  text: (renderCell = null) => ({
    headerAlign: 'center',
    align: 'center',
    field: 'text',
    headerName: 'Тема',
    flex: 1,
    // width: 250,
    renderCell
  }),
  number: (renderCell = null) => ({
    headerAlign: 'center',
    align: 'center',
    field: 'number',
    headerName: 'Номер',
    flex: 0,
    width: 80,
    renderCell
  }),
  unreadMessages: (model, orderHandler, renderCell = null) => ({
    headerAlign: 'center',
    align: 'center',
    field: '',
    sortable: false,
    flex: 0,
    headerClassName: 'notification-sort',
    width: 50,
    renderHeader: () => {
      if (model.params.ordering === '-unread_messages') {
        return <NotificationOrderHeader title={'Непрочитанные сообщения'}><NotificationsIcon sx={{color: green[700]}}
                                                                                             onClick={orderHandler}/></NotificationOrderHeader>;
      }
      if (model.params.ordering === '-chat_request') {
        return <NotificationOrderHeader title={'Запрос на переписку'}><NotificationsActiveIcon sx={{color: blue[700]}}
                                                                                               onClick={orderHandler}/>
        </NotificationOrderHeader>;
      }
      return <NotificationOrderHeader>
        <NotificationsNoneIcon onClick={orderHandler}/>
      </NotificationOrderHeader>;

    },
    renderCell
  }),
  description: (renderCell = null) => ({
    headerAlign: 'center',
    align: 'center',
    field: 'description',
    headerName: 'Описание',
    flex: 0,
    width: 150,
    renderCell
  }),
  descriptionPlain: (renderCell = null) => ({
    headerAlign: 'center',
    align: 'center',
    field: 'descriptionPlain',
    headerName: 'Простое описание',
    flex: 0,
    width: 150,
    renderCell
  }),
  address: (renderCell = null) => ({
    headerAlign: 'center',
    align: 'left',
    field: 'address',
    headerName: 'Адрес',
    cellStyle: { whiteSpace: 'normal', wordWrap: 'break-word' },
    flex: 1,
    minWidth: 180,
    renderCell
  }),
  resolution: (renderCell = null) => ({
    headerAlign: 'center',
    align: 'center',
    field: 'resolution',
    headerName: 'Решение',
    flex: 0,
    width: 150,
    renderCell
  }),
  title: (renderCell = null) => ({
    headerAlign: 'center',
    align: 'center',
    field: 'title',
    headerName: 'Заголовок',
    flex: 0,
    width: 300,
    renderCell
  }),
  subject: (renderCell = null) => ({
    headerAlign: 'center',
    align: 'center',
    field: 'subject',
    headerName: 'Тема',
    flex: 1,
    renderCell
  }),
  groupName: (renderCell = null) => ({
    headerAlign: 'center',
    align: 'center',
    field: 'name',
    headerName: 'Название',
    flex: 1,
    renderCell
  }),
  departmentTitle: (renderCell = null) => ({
    headerAlign: 'center',
    align: 'center',
    field: 'title',
    headerName: 'Заголовок',
    flex: 1,
    renderCell
  }),
  status: (renderCell = null) => ({
    headerAlign: 'center',
    align: 'center',
    field: 'status',
    headerName: 'Статус',
    flex: 0,
    width: 150,
    renderCell
  }),
  issueStatus: (renderCell = null) => ({
    headerAlign: 'center',
    align: 'center',
    field: 'status',
    headerName: 'Статус обращения',
    flex: 0,
    width: 150,
    renderCell
  }),
  user: (renderCell = null) => ({
    headerAlign: 'center',
    align: 'center',
    field: 'user',
    headerName: 'Пользователь',
    flex: 0,
    width: 200,
    renderCell
  }),
  category: (renderCell = null) => ({
    headerAlign: 'center',
    align: 'center',
    field: 'category',
    headerName: 'Категория',
    flex: 1,
    minWidth: 150,
    renderCell
  }),
  area: (renderCell = null) => ({
    headerAlign: 'center',
    align: 'center',
    field: 'area',
    headerName: 'Регион',
    flex: 0,
    minWidth: 200,
    renderCell
  }),
  group: (renderCell = null) => ({
    headerAlign: 'center',
    align: 'center',
    field: 'group',
    headerName: 'Группа',
    flex: 0,
    minWidth: 200,
    renderCell
  }),
  createdAt: (renderCell = null) => ({
    headerAlign: 'center',
    align: 'center',
    field: 'createdAt',
    headerName: 'Дата создания',
    flex: 0,
    minWidth: 120,
    renderCell
  }),
  counter: (renderCell = null) => ({
    headerAlign: 'center',
    align: 'center',
    field: 'counter',
    headerName: 'SLA',
    sortable: false,
    flex: 0,
    width: 150,
    renderCell
  }),
};

export const EmailsSearchFilterToolbar = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {handleFiltersChange, handleFiltersClear, handleFiltersApply, model, disableAdding} = props;
  const emails = useSelector(state => state.emails);
  const fetchMore = (actions, optionsModel) => async (search) => {
    let _search = !search ? '' : search;
    let _modelSearch = !optionsModel.params.search ? '' : optionsModel.params.search;
    const offset = _search === _modelSearch ? optionsModel.params.offset + pagination.limit : 0;

    let newParams = {
      search: search
    };
    if (_search !== _modelSearch) {
      newParams = {
        offset: offset,
        search: search
      };
    }

    const reqParams = Object.assign({}, optionsModel.params, newParams);

    const result = _search === _modelSearch ? await dispatch(actions.getList(reqParams)) : [];

    let params = {
      limit: pagination.limit,
      ordering: 'id',
      offset: offset,
      search: search
    };

    dispatch(actions.updateParams(params)); //TODO проверить подгрузку
    return result;
  };

  const handleAsyncSelectChange = (field) => (e) => {
    (handleFiltersChange(field))(e?.value);
  };

  const applyFilters = () => {
    handleFiltersApply();
  };

  const clearFilters = async () => { // TODO задваиваются выпадающие списки при сбросе фильтров, подгружаются сначала но из select не убираются
    await Promise.all([]);
    handleFiltersClear();
  };

  return (
    <GridToolbarContainer sx={{
      alignItems: 'center',
      marginBottom: 4,
      // minWidth: 1300,
    }}>
      {disableAdding !== true && <Grid sx={{
        margin: 0
      }}>
        <Button
          sx={{
            // minWidth: '100px'
          }}
          onClick={() => {
            navigate(`/${props.path}/add`);
          }}
          color="primary"
          type="submit"
          variant="contained">
          {strings.button.add}
        </Button>
      </Grid>}
      <Grid container mt={0} mb={4}>
        <Grid container
              sx={{
                borderRadius: 1,
                backgroundColor: blueGrey[50]
              }}
        >
          <Grid container item md={12} xs={12} lg={12} mb={1}>
            <FilterDatePicker
              size={'small'}
              label={strings['createdFrom']}
              name={'createdFrom'}
              value={model.params['createdFrom']}
              onChange={(dateObj) => (handleFiltersChange('createdFrom'))(dateObj ? dateObj.getTime() : null)}
            />
            <FilterDatePicker
              size={'small'}
              label={strings['createdTo']}
              name={'createdTo'}
              value={model.params['createdTo']}
              onChange={(dateObj) => (handleFiltersChange('createdTo'))(dateObj ? dateObj.getTime() : null)}
            />
          </Grid>
        </Grid>
        <Grid container pt={4} pb={1} pl={1} pr={1}>
          <Button onClick={applyFilters} sx={{
            borderColor: blue[500],
            borderStyle: 'solid',
            borderRadius: 1,
            borderWidth: !emails.paramsChanged ? 0 : 1
          }}>Применить</Button>
          <Button onClick={clearFilters}>Сбросить</Button>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};