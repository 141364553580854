import * as analyticsConstants from '../constants/analytics';
import {APIHelper} from '../../helpers';
import {path} from '../../config/api';

export const analyticsActions = {
  getStats,
};

function getStats() {
  return dispatch => {
    dispatch(request());
    return APIHelper.get(
      `${path.analytics.stats}`
    ).then(
      res => {
        dispatch(success(res?.data));
        return res?.data;
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: analyticsConstants.GET_STATS};
  }

  function success(payload) {
    return {type: analyticsConstants.GET_STATS_SUCCESS, payload};
  }

  function failure(error) {
    return {type: analyticsConstants.GET_STATS_ERROR, error};
  }
}