import * as departmentsConstants from '../constants/departments';
import {APIHelper} from '../../helpers';
import {path} from '../../config/api';
import * as issuesConstants from '../constants/issues';

export const departmentsActions = {
  getList,
  getItem,
  addItem,
  updateItem,
  editItem,
  removeItem,
  setPage,
  setParams,
  resetParams,
  updateParams,
  clear,
  select,
  unselect,
};

function getList(params, second) {
  return dispatch => {
    dispatch(request(second));
    return APIHelper.get(
      `${path.geo.departments}`,
      {
        params
      }
    ).then(
      res => {
        dispatch(success(res?.data?.results, parseInt(res?.data?.count || 0), second));
        return {
          list: res?.data?.results, totalCount: parseInt(res?.data?.count || 0)
        };
      },
      error => {
        dispatch(failure(error, second));
        return Promise.reject(error);
      }
    );
  };

  function request(second) {
    return {type: departmentsConstants.GET_DEPARTMENTS, second};
  }

  function success(payload, totalCount, second) {
    return {type: departmentsConstants.GET_DEPARTMENTS_SUCCESS, payload, totalCount, second};
  }

  function failure(error, second) {
    return {type: departmentsConstants.GET_DEPARTMENTS_ERROR, error, second};
  }
}

function getItem(id, second) {
  return dispatch => {
    dispatch(request(second));
    return APIHelper.get(
      `${path.geo.departments}/${id}`
    ).then(
      res => {
        dispatch(success(res.data, second));
        return Promise.resolve(res.data);
      },
      error => {
        dispatch(failure(error, second));
        return Promise.reject(error);
      }
    );
  };

  function request(second) {
    return {type: departmentsConstants.GET_DEPARTMENT, second};
  }

  function success(payload, second) {
    payload['area'] = payload.area?.id;
    return {type: departmentsConstants.GET_DEPARTMENT_SUCCESS, payload, second};
  }

  function failure(error, second) {
    return {type: departmentsConstants.GET_DEPARTMENT_ERROR, error, second};
  }
}

function addItem(data, second) {
  return dispatch => {
    dispatch(request(second));
    return APIHelper.post(
      `${path.geo.departments}`,
      data
    ).then(
      res => {
        dispatch(success(res.data, second));
      },
      error => {
        dispatch(failure(error, second));
        return Promise.reject(error);
      }
    );
  };

  function request(second) {
    return {type: departmentsConstants.ADD_DEPARTMENT, second};
  }

  function success(payload, second) {
    return {type: departmentsConstants.ADD_DEPARTMENT_SUCCESS, payload, second};
  }

  function failure(error, second) {
    return {type: departmentsConstants.ADD_DEPARTMENT_ERROR, error, second};
  }
}

function updateItem(id, data) {
  return dispatch => {
    dispatch(request());
    return APIHelper.put(
      `${path.geo.departments}/${id}`,
      data
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: departmentsConstants.UPDATE_DEPARTMENT};
  }

  function success(payload) {
    return {type: departmentsConstants.UPDATE_DEPARTMENT_SUCCESS, payload};
  }

  function failure(error) {
    return {type: departmentsConstants.UPDATE_DEPARTMENT_ERROR, error};
  }
}

function editItem(data) {
  return {type: departmentsConstants.EDIT_DEPARTMENT, data};
}

function removeItem(id) {
  return (dispatch) => {
    dispatch(request());
    return APIHelper.delete(
      `${path.geo.departments}/${id}`,
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: departmentsConstants.REMOVE_DEPARTMENT};
  }

  function success(payload) {
    return {type: departmentsConstants.REMOVE_DEPARTMENT_SUCCESS, payload};
  }

  function failure(error) {
    return {type: departmentsConstants.REMOVE_DEPARTMENT_ERROR, error};
  }
}

function setParams(data) {
  return {type: departmentsConstants.DEPARTMENTS_CHANGE_PARAMS, data};
}

function setPage(data) {
  return {type: departmentsConstants.DEPARTMENTS_CHANGE_PAGE, data};
}

function updateParams(data, second) {
  return {type: departmentsConstants.DEPARTMENTS_UPDATE_PARAMS, data, second};
}

function resetParams() {
  return {type: departmentsConstants.DEPARTMENTS_RESET_PARAMS};
}

function clear(second) {
  return {type: departmentsConstants.DEPARTMENTS_CLEAR, second};
}

function select(data) {
  return {type: departmentsConstants.SELECT_DEPARTMENTS, data};
}

function unselect() {
  return {type: departmentsConstants.UNSELECT_DEPARTMENTS};
}