import * as React from 'react';

import {Box, Container, Divider, Grid, List, Typography} from '@mui/material';


import Skeleton from '@mui/material/Skeleton';

export const renderMenuSkeleton = () => {
  return <List disablePadding>
    {[...Array(5).keys()].map(e => (
      <div key={e}>
        <Box display="flex" alignItems="between">
          <Box margin={1}>
            <Skeleton variant="circular" width={40} height={40}/>
          </Box>
          <Box width="100%">
            <Skeleton variant="text"/>
          </Box>
          <Box width="100%">
            <Skeleton variant="rectangular" width={210} height={118}/>
          </Box>
        </Box>
        <Divider/>
      </div>
    ))}
  </List>;
};


export const renderItemSkeleton = () => {
  return <Container maxWidth="xs">
    <form>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Skeleton width="100%" height={60}>
                <Typography>.</Typography>
              </Skeleton>
            </Grid>

            <Grid item xs={12}>
              <Skeleton width="100%" height={60}>
                <Typography>.</Typography>
              </Skeleton>
            </Grid>

            <Grid item xs={12}>
              <Skeleton width="100%" height={60}>
                <Typography>.</Typography>
              </Skeleton>
            </Grid>

            <Grid item xs={12}>
              <Skeleton width="100%" height={60}>
                <Typography>.</Typography>
              </Skeleton>
            </Grid>

            <Grid item xs={12}>
              <Skeleton width="100%" height={60}>
                <Typography>.</Typography>
              </Skeleton>
            </Grid>

            <Grid item xs={12}>
              <Skeleton width="100%" height={60}>
                <Typography>.</Typography>
              </Skeleton>
            </Grid>

            <Grid container={true} justifyContent={'space-around'}>
              <Box display="flex" width="100%" flexWrap={'wrap'} justifyContent={'space-around'} alignItems="between">
                <Skeleton width="45%" height={50}>
                  <Typography>.</Typography>
                </Skeleton>
                <Skeleton width="45%" height={50}>
                  <Typography>.</Typography>
                </Skeleton>
                <Skeleton width="45%" height={50}>
                  <Typography>.</Typography>
                </Skeleton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  </Container>;
};


export const renderChatSkeleton = () => {
  return <Container maxWidth="xl">
    <form>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <Box display="flex" alignItems="between">
                <Box margin={1}>
                  <Skeleton variant="circular" width={40} height={40}/>
                </Box>
                <Box width="100%">
                  <Skeleton variant="text"/>
                  <Skeleton variant="text"/>
                </Box>
              </Box>
              <Box width="100%">
                <Skeleton variant="rectangular" height={60}/>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" alignItems="between">
                <Box margin={1}>
                  <Skeleton variant="circular" width={40} height={40}/>
                </Box>
                <Box width="100%">
                  <Skeleton variant="text"/>
                  <Skeleton variant="text"/>
                </Box>
              </Box>
              <Box width="100%">
                <Skeleton variant="rectangular" height={60}/>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="between">
                <Box margin={1}>
                  <Skeleton variant="circular" width={40} height={40}/>
                </Box>
                <Box width="100%">
                  <Skeleton variant="text"/>
                  <Skeleton variant="text"/>
                </Box>
              </Box>
              <Box width="100%">
                <Skeleton variant="rectangular" height={60}/>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  </Container>;
};


export const renderEditFieldSkeleton = () => {
  return (<Box width="100%">
    <Skeleton variant="rectangular" height={60}/>
  </Box>);
};


export const renderIssuesSkeleton = () => {
  return <Container maxWidth="xl">
    <form>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" alignItems="between">
                <Box width="100%">
                  <Skeleton variant="text"/>
                  <Skeleton variant="text"/>
                </Box>
              </Box>
              <Box width="100%">
                <Skeleton variant="rectangular" height={60}/>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" alignItems="between">
                <Box width="100%">
                  <Skeleton variant="text"/>
                  <Skeleton variant="text"/>
                </Box>
              </Box>
              <Box width="100%">
                <Skeleton variant="rectangular" height={60}/>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" alignItems="between">
                <Box width="100%">
                  <Skeleton variant="text"/>
                  <Skeleton variant="text"/>
                </Box>
              </Box>
              <Box width="100%">
                <Skeleton variant="rectangular" height={60}/>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  </Container>;
};