import * as groupsConstants from '../constants/groups';
import { APIHelper } from '../../helpers';
import { path } from '../../config/api';

export const groupsActions = {
  getList,
  getItem,
  addItem,
  updateItem,
  editItem,
  removeItem,
  setPage,
  setParams
};

function getList(params) {
  return dispatch => {
    dispatch(request());
    return APIHelper.get(
      `${path.groups}`,
      {
        params
      }
    ).then(
      res => {
        dispatch(success(res?.data?.results, parseInt(res?.data?.count || 0)));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return { type: groupsConstants.GET_GROUPS };
  }

  function success(payload, totalCount) {
    return { type: groupsConstants.GET_GROUPS_SUCCESS, payload, totalCount };
  }

  function failure(error) {
    return { type: groupsConstants.GET_GROUPS_ERROR, error };
  }
}

function getItem(id) {
  return dispatch => {
    dispatch(request());
    return APIHelper.get(
      `${path.groups}/${id}`
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return { type: groupsConstants.GET_GROUP };
  }

  function success(payload) {
    if (payload.permissions.length) { // TODO переделать этот костыль
      payload['permissions'] = payload.permissions.map(e => e.id);
    }
    return { type: groupsConstants.GET_GROUP_SUCCESS, payload };
  }

  function failure(error) {
    return { type: groupsConstants.GET_GROUP_ERROR, error };
  }
}

function addItem(data) {
  return dispatch => {
    dispatch(request());
    return APIHelper.post(
      `${path.groups}`,
      data
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return { type: groupsConstants.ADD_GROUP };
  }

  function success(payload) {
    return { type: groupsConstants.ADD_GROUP_SUCCESS, payload };
  }

  function failure(error) {
    return { type: groupsConstants.ADD_GROUP_ERROR, error };
  }
}

function updateItem(id, data) {
  return dispatch => {
    dispatch(request());
    return APIHelper.put(
      `${path.groups}/${id}`,
      data
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return { type: groupsConstants.UPDATE_GROUP };
  }

  function success(payload) {
    return { type: groupsConstants.UPDATE_GROUP_SUCCESS, payload };
  }

  function failure(error) {
    return { type: groupsConstants.UPDATE_GROUP_ERROR, error };
  }
}

function editItem(data) {
  return { type: groupsConstants.EDIT_GROUP, data };
}

function removeItem(id) {
  return (dispatch) => {
    dispatch(request());
    return APIHelper.delete(
      `${path.groups}/${id}`,
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return { type: groupsConstants.REMOVE_GROUP };
  }

  function success(payload) {
    return { type: groupsConstants.REMOVE_GROUP_SUCCESS, payload };
  }

  function failure(error) {
    return { type: groupsConstants.REMOVE_GROUP_ERROR, error };
  }
}

function setParams(data) {
  return { type: groupsConstants.GROUPS_CHANGE_PARAMS, data };
}

function setPage(data) {
  return { type: groupsConstants.GROUPS_CHANGE_PAGE, data };
}