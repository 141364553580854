import * as citiesConstants from '../constants/cities';
import {APIHelper} from '../../helpers';
import {path} from '../../config/api';

export const citiesActions = {
  getList,
  getItem,
  addItem,
  updateItem,
  editItem,
  removeItem,
  setPage,
  setParams,
  updateParams,
  resetParams,
  clear,
};

function getList(params) {
  return dispatch => {
    dispatch(request());
    return APIHelper.get(
      `${path.geo.cities}`,
      {
        params
      }
    ).then(
      res => {
        dispatch(success(res?.data?.results, parseInt(res?.data?.count || 0)));
        return {
          list: res?.data?.results, totalCount: parseInt(res?.data?.count || 0)
        };
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: citiesConstants.GET_CITIES};
  }

  function success(payload, totalCount) {
    return {type: citiesConstants.GET_CITIES_SUCCESS, payload, totalCount};
  }

  function failure(error) {
    return {type: citiesConstants.GET_CITIES_ERROR, error};
  }
}

function getItem(id) {
  return dispatch => {
    dispatch(request());
    return APIHelper.get(
      `${path.geo.cities}/${id}`
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: citiesConstants.GET_CITY};
  }

  function success(payload) {
    if (payload.permissions.length) { // TODO переделать этот костыль
      payload['permissions'] = payload.permissions.map(e => e.id);
    }
    return {type: citiesConstants.GET_CITY_SUCCESS, payload};
  }

  function failure(error) {
    return {type: citiesConstants.GET_CITY_ERROR, error};
  }
}

function addItem(data) {
  return dispatch => {
    dispatch(request());
    return APIHelper.post(
      `${path.geo.cities}`,
      data
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: citiesConstants.ADD_CITY};
  }

  function success(payload) {
    return {type: citiesConstants.ADD_CITY_SUCCESS, payload};
  }

  function failure(error) {
    return {type: citiesConstants.ADD_CITY_ERROR, error};
  }
}

function updateItem(id, data) {
  return dispatch => {
    dispatch(request());
    return APIHelper.put(
      `${path.geo.cities}/${id}`,
      data
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: citiesConstants.UPDATE_CITY};
  }

  function success(payload) {
    return {type: citiesConstants.UPDATE_CITY_SUCCESS, payload};
  }

  function failure(error) {
    return {type: citiesConstants.UPDATE_CITY_ERROR, error};
  }
}

function editItem(data) {
  return {type: citiesConstants.EDIT_CITY, data};
}

function removeItem(id) {
  return (dispatch) => {
    dispatch(request());
    return APIHelper.delete(
      `${path.geo.cities}/${id}`,
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: citiesConstants.REMOVE_CITY};
  }

  function success(payload) {
    return {type: citiesConstants.REMOVE_CITY_SUCCESS, payload};
  }

  function failure(error) {
    return {type: citiesConstants.REMOVE_CITY_ERROR, error};
  }
}

function setParams(data) {
  return {type: citiesConstants.CITIES_CHANGE_PARAMS, data};
}

function updateParams(data) {
  return {type: citiesConstants.CITIES_UPDATE_PARAMS, data};
}

function resetParams() {
  return {type: citiesConstants.CITIES_RESET_PARAMS};
}

function setPage(data) {
  return {type: citiesConstants.CITIES_CHANGE_PAGE, data};
}

function clear() {
  return {type: citiesConstants.CITIES_CLEAR};
}