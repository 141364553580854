import * as constants from '../constants/categories';
import {pagination} from '../../config/settings';
import * as _ from 'lodash';

export const initialState = {
  item: null,
  list: [],
  totalCount: 0,
  loading: false,
  params: {
    ordering: 'id', limit: pagination.limit, offset: 0, search: null
  },
  pagesCount: 1,
  page: 1,
};

export default (state =  _.cloneDeep(initialState), action) => {
  switch (action.type) {
    case constants.GET_CATEGORIES:
    case constants.GET_CATEGORY:
    case constants.UPDATE_CATEGORY:
    case constants.REMOVE_CATEGORY:
      return {
        ...state,
        loading: true
      };

    case constants.GET_CATEGORIES_ERROR:
    case constants.GET_CATEGORY_ERROR:
    case constants.UPDATE_CATEGORY_ERROR:
    case constants.REMOVE_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        clearing: false,
      };

    case constants.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case constants.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        clearing: false,
        list: action.payload,
        // item: null,
        totalCount: action.totalCount,
        pagesCount: Math.ceil(action.totalCount / pagination.limit)
      };

    case constants.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [],
        item: action.payload,
      };

    case constants.EDIT_CATEGORY:
      return {
        ...state,
        item: action.data,
      };
    case constants.CATEGORIES_CHANGE_PARAMS:
      return {
        ...state,
        params: action.data,
      };

    case constants.CATEGORIES_UPDATE_PARAMS:
      return {
        ...state,
        params: action.data !== null ? _.merge(state.params, action.data) : _.cloneDeep(initialState.params)
      };
    case constants.CATEGORIES_RESET_PARAMS:
      return {
        ...state,
        clearing: false,
        params: _.cloneDeep(initialState.params)
      };
    case constants.CATEGORIES_CHANGE_PAGE:
      return {
        ...state,
        page: +action.data,
      };

    case constants.CATEGORIES_CLEAR:
      return {
        ...state,
        clearing: true,
        totalCount: 0,
        pagesCount: 1,
        page: 1,
        item: null,
        list: []
      };

    default:
      return state;
  }
};