import {port, url} from '../config/api';
import React from 'react';
import PdfIcon from '../img/pdf-icon.png';
import {
  AttachedFile,
  AttachedImage,
  Attachment,
  DocImage,
  DocItem,
  DocItemTitle,
  HiddenInput,
  MessageControlContainer,
  MessageInput,
  NewMessage,
  NotificationOrderHeader,
  RenderBreakSpaces,
  RenderCounter,
  RenderData,
  RenderImage,
  RenderMedia,
  RenderModel,
  SendMessageButton,
  StyledLabel,
  StyledLabelLink,
  UploadingAttachments
} from './styledComponents';
import {Box, Grid, IconButton, Link, TextField} from '@mui/material';
import strings from '../config/strings';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import {extensions, mimeTypes} from '../config/enums';
import DeleteIcon from '@mui/icons-material/Delete';
import DocIcon from '../img/doc-icon.png';
import TxtIcon from '../img/txt-icon.png';
import {useDispatch, useSelector} from 'react-redux';
import * as mediaConstants from '../redux/constants';
import * as dateFns from 'date-fns';
import {PieChart} from 'react-minimal-pie-chart';
import {blue, green} from '@mui/material/colors';
import CircleIcon from '@mui/icons-material/Circle';

export const renderImage = (imageModel) => {
  // const classes = useStyles();
  return <div> {/*className={classes.model}*/}
    <div>  {/*className={classes.media}*/}
      {imageModel ?
        <img
          alt={imageModel.title}
          // className={classes.image}
          src={`${url}:${port}${imageModel['preview']}`}
        />
        : null
      }
    </div>
  </div>;
};

export const renderUser = ({value}) => {
  return <RenderModel>
    {value?.image ?
      <RenderMedia>
        <RenderImage
          alt={value['image']['id']}
          src={`${url}:${port}${value['image']['link']}`}
        />
      </RenderMedia>
      : null
    }
    <RenderData>
      {/*<div>ID:{value?.id}</div>*/}
      <RenderBreakSpaces>{value?.firstName} {value?.lastName}</RenderBreakSpaces>
      <RenderBreakSpaces>{value?.email}</RenderBreakSpaces>
    </RenderData>
  </RenderModel>;
};

export const renderText = ({value}) => {
  return <RenderBreakSpaces>{value}</RenderBreakSpaces>;
};

export const renderIssueLink = (params) => {
  return <Link href={'/issues/' + params.id}>
    <RenderBreakSpaces>{params.value}</RenderBreakSpaces>
  </Link>;
};

export const renderUserLink = (params) => {
  return <Link href={'/users/' + params.id}>
    <RenderBreakSpaces>{params.value}</RenderBreakSpaces>
  </Link>;
};

export const renderStatus = ({value}) => {
  return <RenderModel>
    <RenderData>
      <div style={{
        textAlign: 'center'
      }}>{value?.name}</div>
    </RenderData>
  </RenderModel>;
};

export const renderCounter = ({value}) => {
  return value ? <RenderModel>
    <RenderCounter>
      <PieChart
        style={{
          width: 20,
          minWidth: 10,
          height: 20
        }}
        data={[{value: 1, key: 1, color: blue[500]}]}
        reveal={value.percentage}
        lineWidth={30}
        startAngle={270}
        background="#bfbfbf"
        lengthAngle={360}
        rounded
        animate
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: 2,
          width: 125
        }}
      >
        {`${value.remainedDays} д. ${value.remainedHours} ч. ${value.remainedMinutes} м.`}
      </Box>
    </RenderCounter>
  </RenderModel> : '-';
};


export const renderValue = ({value}) => {
  return <RenderModel>
    <RenderData>
      <div>{value}</div>
    </RenderData>
  </RenderModel>;
};

export const renderCategory = ({value}) => {
  return <RenderModel>
    <RenderData>
      <div>{value?.name}</div>
    </RenderData>
  </RenderModel>;
};

export const renderArea = ({value}) => {
  return <RenderModel>
    <RenderData>
      <RenderBreakSpaces>{value?.name}</RenderBreakSpaces>
    </RenderData>
  </RenderModel>;
};

export const renderGroup = ({value}) => {
  return <RenderModel>
    <RenderData>
      <RenderBreakSpaces>{value?.name}</RenderBreakSpaces>
    </RenderData>
  </RenderModel>;
};

export const renderUnreadMessagesIndicator = (model) => ({row, value}) => {
  if (model.params.ordering === '-unread_messages' && row.unreadMessages) {
    return <NotificationOrderHeader
      title={'Есть непрочитанные сообщения'}
    >
      <CircleIcon sx={{color: green[500]}}/>
    </NotificationOrderHeader>;
  }
  if (model.params.ordering === '-chat_request' && row.chatRequest) {
    return <NotificationOrderHeader
      title={'Есть запрос на переписку'}
    >
      <CircleIcon sx={{color: blue[500]}}/>
    </NotificationOrderHeader>;
  }
  if (row.chatRequest) {
    return <NotificationOrderHeader
      title={'Есть запрос на переписку'}
    >
      <CircleIcon sx={{color: blue[500]}}/>
    </NotificationOrderHeader>;
  }
  if (row.unreadMessages) {
    return <NotificationOrderHeader
      title={'Есть непрочитанные сообщения'}
    >
      <CircleIcon sx={{color: green[500]}}/>
    </NotificationOrderHeader>;
  }
  return '';
};

export const renderDateTime = ({value}) => {
  return <RenderModel>
    <RenderData>
      {/* HH:mm:SS*/}
      <div>{dateFns.format(value, 'dd.MM.yyyy')}</div>
    </RenderData>
  </RenderModel>;
};

export const renderIssueUser = (model, goToProfile = null) => {
  return (
    <Box mt={3} sx={{
      paddingLeft: 3,
      paddingTop: 3
    }}>
      <StyledLabel>
        <span>Пользователь</span>
        <StyledLabelLink onClick={() => {
          if (goToProfile) {
            goToProfile();
          }
        }}>перейти в профиль</StyledLabelLink>
      </StyledLabel>
      <Grid container mt={1} spacing={3}>
        <Grid item xs={12}>
          <TextField
            disabled={true}
            fullWidth
            size="small"
            label={strings['firstName']}
            name={'firstName'}
            value={model.item.user?.firstName || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={true}
            fullWidth
            size="small"
            label={strings['lastName']}
            name={'lastName'}
            value={model.item.user?.lastName || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={true}
            fullWidth
            size="small"
            label={strings['email']}
            name={'email'}
            value={model.item.user?.email || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={true}
            fullWidth
            size="small"
            label={'Телефон'}
            name={'number'}
            value={model.item.user?.number || ''}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export const renderIssueDepartment = (model) => {
  return (
    <Box mt={3} sx={{
      paddingLeft: 3,
      paddingTop: 3
    }}>
      <StyledLabel>
        Исполнитель
      </StyledLabel>
      <Grid container mt={1} spacing={3}>
        <Grid item xs={12}>
          <TextField
            disabled={true}
            fullWidth
            size="small"
            label={strings['title']}
            name={'title'}
            value={model.item.department?.title || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={true}
            fullWidth
            size="small"
            label={strings['area']}
            name={'areaName'}
            value={model.item.department?.area.name || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={true}
            fullWidth
            size="small"
            label={strings['unit']}
            name={'гтше'}
            value={model.item.department?.area?.unit?.title || ''}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export const renderAttachment = (file, index) => {
  const renderFile = (file) => {
    let icon;
    const ext = (file?.link.split('.').pop() || '').toLowerCase();
    if (['jpeg', 'png', 'jpg', 'gif'].includes(ext)) {
      return null;
    }
    switch (ext) {
      case extensions.documents.DOC:
        icon = DocIcon;
        break;
      case extensions.documents.DOCX:
        icon = DocIcon;
        break;
      case extensions.documents.PDF:
        icon = PdfIcon;
        break;
      default:
        icon = TxtIcon;

    }
    return <AttachedFile
      key={index}
      onClick={() => {
        window.open(`${url}:${port}${file['link']}`, '_blank');
      }}
    >
      <DocImage
        alt="edit"
        src={icon}
      />
      <DocItemTitle>
        {file?.originalName}
      </DocItemTitle>
    </AttachedFile>;
  };

  return <Attachment>
    {renderFile(file) ||
      <AttachedImage
        key={index}
        onClick={() => window.open(`${url}:${port}${file['link']}`, '_blank')}
        src={`${url}:${port}${file['link']}`}
        alt={file['originalName']}
      />}
  </Attachment>;
};

export const renderNewMessageInput = (model, textField, fileField, handleMessageChange, handleSubmitSend) => {
  const inputRef = React.useRef(null);
  const accept = `${mimeTypes.documents.DOC}, ${mimeTypes.documents.DOCX}`;
  const dispatch = useDispatch();
  const media = useSelector(state => state.media);

  const handleUploadClick = event => {
    const file = event.target.files[0];
    dispatch({
      type: mediaConstants.ADD_UPLOADING_FILE,
      media: {
        file: file,
      }
    });

    // Очищаем input для загрузки файла, иначе при удалении загружаемого файла и повторном выборе его же
    // не сработает onChange, так как файл из input никуда не денется
    inputRef.current.value = null;
  };

  const renderUploadingDocIcon = () => {
    let icon;
    const ext = media.uploadingMedia[0]?.file.name.split('.').pop();
    switch (ext) {
      case extensions.documents.DOC:
        icon = DocIcon;
        break;
      case extensions.documents.DOCX:
        icon = DocIcon;
        break;
      default:
        icon = TxtIcon;

    }
    return <DocImage
      alt={''}
      src={icon}
    />;
  };

  const removeUploading = () => (e) => {
    dispatch({
      type: mediaConstants.CLEAR
    });
  };

  const renderUploading = () => {
    return <DocItem
    >
      {renderUploadingDocIcon()}
      <DocItemTitle>
        {media.uploadingMedia[0]?.file.name}
      </DocItemTitle>
      <IconButton
        aria-label="delete"
        onClick={removeUploading()}
      >
        <DeleteIcon/>
      </IconButton>
    </DocItem>;
  };

  return (
    <MessageControlContainer>
      <NewMessage>
        <MessageInput
          multiline={true}
          placeholder={'Введите сообщение'}
          value={model.item?.text || ''}
          onChange={handleMessageChange(textField)}
          InputProps={{
            endAdornment: (
              <>
                <HiddenInput
                  ref={inputRef}
                  accept={accept}
                  id={`contained-button-${fileField}`}
                  type="file"
                  onChange={handleUploadClick}
                />
                <label htmlFor={`contained-button-${fileField}`}>
                  <AttachFileIcon cursor={'pointer'} fontSize="small"/>
                </label>
              </>
            ),
          }}
        />
        <SendMessageButton>
          <IconButton
            title="Отправить"
            aria-label="Отправить"
            size="small"
            onClick={handleSubmitSend}
          >
            <SendIcon fontSize="large"/>
          </IconButton>
        </SendMessageButton>
      </NewMessage>
      <UploadingAttachments>
        {!!media.uploadingMedia.length && renderUploading()}
      </UploadingAttachments>
    </MessageControlContainer>
  );
};