import * as constants from '../constants/issueStatuses';
import {pagination} from '../../config/settings';
import * as _ from 'lodash';

export const initialState = {
  item: null,
  list: [],
  totalCount: 0,
  clearing: false,
  loading: false,
  params: {
    ordering: 'id', limit: pagination.limit, offset: 0
  },
  pagesCount: 1,
  page: 1,
};

export default (state = _.cloneDeep(initialState), action) => {
  switch (action.type) {
    case constants.GET_ISSUE_STATUSES:
    case constants.GET_ISSUE_STATUS:
    case constants.UPDATE_ISSUE_STATUS:
    case constants.REMOVE_ISSUE_STATUS:
      return {
        ...state,
        loading: true
      };

    case constants.GET_ISSUE_STATUSES_ERROR:
    case constants.GET_ISSUE_STATUS_ERROR:
    case constants.UPDATE_ISSUE_STATUS_ERROR:
    case constants.REMOVE_ISSUE_STATUS_ERROR:
      return {
        ...state,
        clearing: false,
        loading: false,
      };

    case constants.GET_ISSUE_STATUSES_SUCCESS:
      return {
        ...state,
        loading: false,
        clearing: false,
        list: action.payload,
        item: null,
        totalCount: action.totalCount,
        pagesCount: Math.ceil(action.totalCount / pagination.limit)
      };

    case constants.GET_ISSUE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [],
        item: action.payload,
      };

    case constants.EDIT_ISSUE_STATUS:
      return {
        ...state,
        item: action.data,
      };
    case constants.ISSUE_STATUSES_CHANGE_PARAMS:
      return {
        ...state,
        params: action.data ?? _.cloneDeep(initialState.params),
      };
    case constants.ISSUE_STATUSES_UPDATE_PARAMS:
      return {
        ...state,
        params: action.data !== null ? _.merge(state.params, action.data) : _.cloneDeep(initialState.params)
      };
    case constants.ISSUE_STATUSES_RESET_PARAMS:
      return {
        ...state,
        clearing: false,
        params: _.cloneDeep(initialState.params)
      };
    case constants.ISSUE_STATUSES_CHANGE_PAGE:
      return {
        ...state,
        page: +action.data,
      };
    case constants.ISSUE_STATUSES_CLEAR:
      return {
        ...state,
        clearing: true,
        totalCount: 0,
        pagesCount: 1,
        page: 1,
        item: null,
        list: [],
      };
    default:
      return state;
  }
};