module.exports = {
  pagination: {
    limit: 50
  },
  permissions: {
    issues: ['Администраторы', 'Модераторы', 'Исполнители', 'Наблюдатели'],
    users: ['Администраторы', 'Модераторы',],
    departments: ['Администраторы', 'Модераторы', 'Исполнители'],
    analytics: ['Администраторы', 'Модераторы', 'Статистика', 'Отчёт JTI', 'Отчёт BAT', 'Отчёт PMI'],
    emails: ['Администраторы'],
  }
};