export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';

export const DOWNLOAD_FILE = 'DOWNLOAD_FILE';
export const DOWNLOAD_FILE_ERROR = 'DOWNLOAD_FILE_ERROR';
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS';

export const DELETE_FILES = 'DELETE_FILES';
export const DELETE_FILES_ERROR = 'DELETE_FILES_ERROR';
export const DELETE_FILES_SUCCESS = 'DELETE_FILES_SUCCESS';

export const ADD_DELETING_FILE = 'ADD_DELETING_FILE';
export const REMOVE_FILE_FROM_DELETING = 'REMOVE_FILE_FROM_DELETING';

export const ADD_UPLOADING_FILE = 'ADD_UPLOADING_FILE';
export const REMOVE_FILE_FROM_UPLOADING = 'REMOVE_FILE_FROM_UPLOADING';

export const CLEAR = 'CLEAR';