import * as departmentsConstants from '../constants/departmentsGroups';
import {APIHelper} from '../../helpers';
import {path} from '../../config/api';

export const departmentsGroupsActions = {
  getList,
  getItem,
  addItem,
  updateItem,
  editItem,
  removeItem,
  setPage,
  setParams,
  updateParams,
  resetParams,
  clear,
};

function getList(params, isGrouping = false) {
  return dispatch => {
    dispatch(request(isGrouping));
    // console.log(params);
    return APIHelper.get(
      `${path.geo.departments_groups}`,
      {
        params
      }
    ).then(
      res => {
        dispatch(success(res?.data?.results, parseInt(res?.data?.count || 0), isGrouping));
        return {
          list: res?.data?.results, totalCount: parseInt(res?.data?.count || 0)
        };
      },
      error => {
        dispatch(failure(error, isGrouping));
        return Promise.reject(error);
      }
    );
  };

  function request(isGrouping) {
    return {type: departmentsConstants.GET_DEPARTMENTS_GROUPS, isGrouping};
  }

  function success(payload, totalCount, isGrouping) {
    return {type: departmentsConstants.GET_DEPARTMENTS_GROUPS_SUCCESS, payload, totalCount, isGrouping};
  }

  function failure(error, isGrouping) {
    return {type: departmentsConstants.GET_DEPARTMENTS_GROUPS_ERROR, error, isGrouping};
  }
}

function getItem(id) {
  return dispatch => {
    dispatch(request());
    return APIHelper.get(
      `${path.geo.departments_groups}/${id}`
    ).then(
      res => {
        dispatch(success(res.data));
        return Promise.resolve(res.data);
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: departmentsConstants.GET_DEPARTMENT_GROUP};
  }

  function success(payload) {
    payload['area'] = payload.area?.id;
    return {type: departmentsConstants.GET_DEPARTMENT_GROUP_SUCCESS, payload};
  }

  function failure(error) {
    return {type: departmentsConstants.GET_DEPARTMENT_GROUP_ERROR, error};
  }
}

function addItem(data) {
  return dispatch => {
    dispatch(request());
    return APIHelper.post(
      `${path.geo.departments_groups}`,
      data
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: departmentsConstants.ADD_DEPARTMENT_GROUP};
  }

  function success(payload) {
    return {type: departmentsConstants.ADD_DEPARTMENT_GROUP_SUCCESS, payload};
  }

  function failure(error) {
    return {type: departmentsConstants.ADD_DEPARTMENT_GROUP_ERROR, error};
  }
}

function updateItem(id, data) {
  return dispatch => {
    dispatch(request());
    return APIHelper.put(
      `${path.geo.departments_groups}/${id}`,
      data
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: departmentsConstants.UPDATE_DEPARTMENT_GROUP};
  }

  function success(payload) {
    return {type: departmentsConstants.UPDATE_DEPARTMENT_GROUP_SUCCESS, payload};
  }

  function failure(error) {
    return {type: departmentsConstants.UPDATE_DEPARTMENT_GROUP_ERROR, error};
  }
}

function editItem(data) {
  return {type: departmentsConstants.EDIT_DEPARTMENT_GROUP, data};
}

function removeItem(id) {
  return (dispatch) => {
    dispatch(request());
    return APIHelper.delete(
      `${path.geo.departments_groups}/${id}`,
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: departmentsConstants.REMOVE_DEPARTMENT_GROUP};
  }

  function success(payload) {
    return {type: departmentsConstants.REMOVE_DEPARTMENT_GROUP_SUCCESS, payload};
  }

  function failure(error) {
    return {type: departmentsConstants.REMOVE_DEPARTMENT_GROUP_ERROR, error};
  }
}

function setParams(data, isGrouping = false) {
  return {type: departmentsConstants.DEPARTMENTS_GROUPS_CHANGE_PARAMS, data, isGrouping};
}

function setPage(data, isGrouping = false) {
  return {type: departmentsConstants.DEPARTMENTS_GROUPS_CHANGE_PAGE, data, isGrouping};
}

function updateParams(data, isGrouping = false) {
  return {type: departmentsConstants.DEPARTMENTS_GROUPS_UPDATE_PARAMS, data, isGrouping};
}

function clear(isGrouping = false) {
  return {type: departmentsConstants.DEPARTMENTS_GROUPS_CLEAR, isGrouping};
}

function resetParams(isGrouping = false) {
  return {type: departmentsConstants.DEPARTMENTS_GROUPS_RESET_PARAMS};
}