export const authHeader = () => {
  const xAuthToken = JSON.parse(localStorage.getItem('xAuthToken'));

  if (xAuthToken) {
    return {'x-auth-token': xAuthToken};
  } else {
    return {};
  }
};

export const analyticsOnly = (mappedGroups) => {
  return mappedGroups && mappedGroups.length && (mappedGroups.includes('Статистика') || mappedGroups.includes('Отчёт JTI') || mappedGroups.includes('Отчёт BAT') || mappedGroups.includes('Отчёт PMI'));
};

export const adminsOnly = (mappedGroups, isSuperuser = false) => {
  if (isSuperuser) {
    return true;
  }
  return mappedGroups && mappedGroups.length && mappedGroups.includes('Администраторы');
};

export const adminsModeratorsBatOnly = (mappedGroups, isSuperuser = false) => {
  if (isSuperuser) {
    return true;
  }
  return mappedGroups && mappedGroups.length && (mappedGroups.includes('Администраторы') || mappedGroups.includes('Модераторы') || mappedGroups.includes('Отчёт BAT'));
};