export const GET_ISSUES = 'GET_ISSUES';
export const GET_ISSUES_ERROR = 'GET_ISSUES_ERROR';
export const GET_ISSUES_SUCCESS = 'GET_ISSUES_SUCCESS';

export const GET_ISSUE = 'GET_ISSUE';
export const GET_ISSUE_ERROR = 'GET_ISSUE_ERROR';
export const GET_ISSUE_SUCCESS = 'GET_ISSUE_SUCCESS';

export const EDIT_ISSUE = 'EDIT_ISSUE';

export const ADD_ISSUE = 'ADD_ISSUE';
export const ADD_ISSUE_ERROR = 'ADD_ISSUE_ERROR';
export const ADD_ISSUE_SUCCESS = 'ADD_ISSUE_SUCCESS';

export const UPDATE_ISSUE = 'UPDATE_ISSUE';
export const UPDATE_ISSUE_ERROR = 'UPDATE_ISSUE_ERROR';
export const UPDATE_ISSUE_SUCCESS = 'UPDATE_ISSUE_SUCCESS';

export const REMOVE_ISSUE = 'REMOVE_ISSUE';
export const REMOVE_ISSUE_ERROR = 'REMOVE_ISSUE_ERROR';
export const REMOVE_ISSUE_SUCCESS = 'REMOVE_ISSUE_SUCCESS';

export const ISSUES_CHANGE_PARAMS = 'ISSUES_CHANGE_PARAMS';
export const ISSUES_UPDATE_PARAMS = 'ISSUES_UPDATE_PARAMS';
export const ISSUES_RESET_PARAMS = 'ISSUES_RESET_PARAMS';
export const ISSUES_CLEAR = 'ISSUES_CLEAR';
export const ISSUES_CHANGE_PAGE = 'ISSUES_CHANGE_PAGE';

export const DOWNLOAD_REPORT = 'DOWNLOAD_REPORT';
export const DOWNLOAD_REPORT_ERROR = 'DOWNLOAD_REPORT_ERROR';
export const DOWNLOAD_REPORT_SUCCESS = 'DOWNLOAD_REPORT_SUCCESS';

export const ISSUES_UPDATE_REPORTS_PARAMS = 'ISSUES_UPDATE_REPORTS_PARAMS';