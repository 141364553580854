export const GET_STAFF = 'GET_STAFF';
export const GET_STAFF_ERROR = 'GET_STAFF_ERROR';
export const GET_STAFF_SUCCESS = 'GET_STAFF_SUCCESS';

export const GET_STAFF_MEMBER = 'GET_STAFF_MEMBER';
export const GET_STAFF_MEMBER_ERROR = 'GET_STAFF_MEMBER_ERROR';
export const GET_STAFF_MEMBER_SUCCESS = 'GET_STAFF_MEMBER_SUCCESS';

export const EDIT_STAFF = 'EDIT_STAFF';
export const CLEAR_STAFF_CREDENTIALS = 'CLEAR_STAFF_CREDENTIALS';

export const REGENERATE_STAFF_CREDENTIALS = 'REGENERATE_STAFF_CREDENTIALS';
export const REGENERATE_STAFF_CREDENTIALS_ERROR = 'REGENERATE_STAFF_CREDENTIALS_ERROR';
export const REGENERATE_STAFF_CREDENTIALS_SUCCESS = 'REGENERATE_STAFF_CREDENTIALS_SUCCESS';

export const ADD_STAFF = 'ADD_STAFF';
export const ADD_STAFF_ERROR = 'ADD_STAFF_ERROR';
export const ADD_STAFF_SUCCESS = 'ADD_STAFF_SUCCESS';

export const UPDATE_STAFF = 'UPDATE_STAFF';
export const UPDATE_STAFF_ERROR = 'UPDATE_STAFF_ERROR';
export const UPDATE_STAFF_SUCCESS = 'UPDATE_STAFF_SUCCESS';

export const REMOVE_STAFF = 'REMOVE_STAFF';
export const REMOVE_STAFF_ERROR = 'REMOVE_STAFF_ERROR';
export const REMOVE_STAFF_SUCCESS = 'REMOVE_STAFF_SUCCESS';

export const STAFF_CHANGE_PARAMS = 'STAFF_CHANGE_PARAMS';
export const STAFF_CHANGE_PAGE = 'STAFF_CHANGE_PAGE';