import * as constants from '../constants/analytics';
import * as _ from 'lodash';

export const initialState = {
  stats: null,
  totalCount: 0,
  loading: false,
};

export default (state = _.cloneDeep(initialState), action) => {
  switch (action.type) {
    case constants.GET_STATS:
      return {
        ...state,
        loading: true
      };

    case constants.GET_STATS_ERROR:
      return {
        ...state,
        loading: false,
        clearing: false,
      };

    case constants.GET_STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        stats: action.payload,
      };

    default:
      return state;
  }
};