export const validateFields = (model, fields, rules) => {
  let errors = {};
  fields.map(field => {
    if (!rules[field]) {
      return;
    }
    if (rules[field].type === 'string') {
      if (model[field] && model[field].length < rules[field].minLength) {
        errors[field] = `Длина поля ${field} не должна быть меньше ${rules[field].minLength} символов`;
      }
      if (model[field] && rules[field].regex && !rules[field].regex.test(model[field])) {
        errors[field] = `Поле ${field} имеет невалидный формат`;
      }
    }
  });

  return errors;
};

export const validateForm = (model, validationRules, setValidation) => {
  const fields = Object.keys(model);
  let errors = validateFields(model, fields, validationRules);
  const keys = Object.keys(errors);
  if (keys.length) {
    let newErrors = {};
    keys.map(key => {
      newErrors[key] = {
        error: true,
        helperText: errors[key]
      };
    });
    setValidation(prevState => ({
      ...prevState,
      ...newErrors
    }));
    return null;
  }

  let result = {};
  fields.map(field => {
    result[field] = model[field];
  });
  return result;
};

export const categoryJsonToFormData = (jsonData) => {
  let formData = new FormData();
  for (let key in jsonData) {
    if (key === 'template') {
      if (jsonData[key] === null || jsonData[key] === '') {
        formData.set(key, '');
      } else {
        (typeof jsonData[key]) !== 'string' && formData.append(key, jsonData[key]);
      }
    } else if (['image', 'parent'].includes(key)) {
      // Передаётся существующее изображение
      (typeof jsonData[key]) === 'object' && jsonData[key] !== null && formData.append(key, jsonData[key].id);
      // Передаётся ид загруженного изображения, которое нужно привязать
      (typeof jsonData[key]) === 'number' && formData.append(key, jsonData[key]);
      // Передаётся null или '' - удалить (отправить пустую строку)
      [null, ''].includes(jsonData[key]) && formData.append(key, '');
    } else {
      formData.append(key, jsonData[key]);
    }
  }
  // console.log(formData['template']);
  return formData;
};