import {API_ERROR, CLEAR_ALERT, CUSTOM_ERROR, NETWORK_ERROR} from '../constants';
import * as _ from 'lodash';
export const initialState = {
  severity: null,
  message: null,
  errorCode: null,
  errors: []
};

export default (state =  _.cloneDeep(initialState), action) => {
  switch (action.type) {
    case NETWORK_ERROR:
      return {
        ...initialState,
        severity: 'error',
        message: 'Ошибка подключения',
      };

    case API_ERROR:
      return {
        ...initialState,
        severity: 'error',
        errorCode: action.payload.code,
        message: action.payload.message,
      };

    case CUSTOM_ERROR:
      return {
        ...initialState,
        severity: 'error',
        // message: 'Ошибка валидации',
        errorCode: action.payload.code,
        errors: action.payload.errors,
      };

    case CLEAR_ALERT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};