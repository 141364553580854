import * as _ from 'lodash';

export const fieldsRules = {
  firstName: {
    type: 'string',
    minLength: 1,
    maxLength: 30,
    regex: /^[ A-Za-zА-Яа-яЁё0-9\-–_]+$/
  },
  email: {
    type: 'string',
    minLength: 5,
    regex: /^[-_.a-zA-Z0-9]+@(?:[-_a-zA-Z0-9]+\.)+[A-Za-z]+$/
  },
  lastName: {
    type: 'string',
    minLength: 1,
    maxLength: 30,
    regex: /^[ A-Za-zА-Яа-яЁё0-9\-–_]+$/
  },
  middleName: {
    type: 'string',
    minLength: 1,
    maxLength: 30,
    regex: /^[ A-Za-zА-Яа-яЁё0-9\-–_]+$/
  },
  phone: {
    type: 'string',
    regex: /^(\+7|8)[0-9]{10,}$/
  },
  title: {
    type: 'string',
    minLength: 1,
    maxLength: 40,
  },
  kusp: {
    type: 'string',
    minLength: 0,
    maxLength: 64,
    // regex: /^[ A-Za-zА-Яа-яЁё0-9]+$/
  },
};

export const emptyErrors = {
  error: false,
  helperText: null
};

export const generateEmptyErrorsAndValidation = (fields = null) => {
  let result = {
    fieldsInitState: {}, fieldsValidators: {}
  };
  if (fields?.length) {
    for (let v of fields) {
      result.fieldsInitState[v] = _.cloneDeep(emptyErrors);
      result.fieldsValidators[v] = fieldsRules[v] ?? null;
    }
  }
  return result;
};