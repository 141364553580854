import * as constants from '../constants/departmentsGroups';
import {pagination} from '../../config/settings';
import * as _ from 'lodash';

export const initialState = {
  item: null,
  list: [],
  totalCount: 0,
  loading: false,
  params: {
    ordering: 'id', limit: pagination.limit, offset: 0, search: null
  },
  pagesCount: 1,
  page: 1,
};

export default (state = _.cloneDeep(initialState), action) => {
  if (action.isGrouping === true) {
    return state;
  }
  switch (action.type) {
    case constants.GET_DEPARTMENTS_GROUPS:
    case constants.GET_DEPARTMENT_GROUP:
    case constants.UPDATE_DEPARTMENT_GROUP:
    case constants.REMOVE_DEPARTMENT_GROUP:
      return {
        ...state,
        loading: true
      };

    case constants.GET_DEPARTMENTS_GROUPS_ERROR:
    case constants.GET_DEPARTMENT_GROUP_ERROR:
    case constants.UPDATE_DEPARTMENT_GROUP_ERROR:
    case constants.REMOVE_DEPARTMENT_GROUP_ERROR:
      return {
        ...state,
        loading: false,
        clearing: false,
      };

    case constants.GET_DEPARTMENTS_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        clearing: false,
        list: action.payload,
        item: null,
        totalCount: action.totalCount,
        pagesCount: Math.ceil(action.totalCount / pagination.limit)
      };

    case constants.GET_DEPARTMENT_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [],
        item: action.payload,
      };

    case constants.EDIT_DEPARTMENT_GROUP:
      return {
        ...state,
        item: action.data,
      };
    case constants.DEPARTMENTS_GROUPS_CHANGE_PARAMS:
      return {
        ...state,
        params: action.data,
      };

    case constants.DEPARTMENTS_GROUPS_UPDATE_PARAMS:
      return {
        ...state,
        params: action.data !== null ? _.merge(state.params, action.data) : _.cloneDeep(initialState.params)
      };
    case constants.DEPARTMENTS_GROUPS_CHANGE_PAGE:
      return {
        ...state,
        page: +action.data,
      };

    case constants.DEPARTMENTS_GROUPS_RESET_PARAMS:
      return {
        ...state,
        clearing: false,
        paramsChanged: false,
        params: _.cloneDeep(initialState.params)
      };

    case constants.DEPARTMENTS_GROUPS_CLEAR:
      return {
        ...state,
        clearing: true,
        totalCount: 0,
        pagesCount: 1,
        page: 1,
        item: null,
        list: [],
      };


    default:
      return state;
  }
};