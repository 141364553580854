import * as usersConstants from '../constants/users';
import {APIHelper} from '../../helpers';
import {path} from '../../config/api';

export const usersActions = {
  getList,
  removeList,
  getItem,
  addItem,
  removeItem,
  editItem,
  clearCredentials,
  regenerateCredentials,
  updateItem,
  setPage,
  setParams,
  updateParams,
  resetParams,
  clear,
  select,
  unselect
};

function getList(params) {
  return dispatch => {
    dispatch(request());
    return APIHelper.get(
      `${path.users}`,
      {
        params
      }
    ).then(
      res => {
        dispatch(success(res?.data?.results, parseInt(res?.data?.count || 0)));
        return {
          list: res?.data?.results, totalCount: parseInt(res?.data?.count || 0)
        };
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: usersConstants.GET_USERS};
  }

  function success(payload, totalCount) {
    return {type: usersConstants.GET_USERS_SUCCESS, payload, totalCount};
  }

  function failure(error) {
    return {type: usersConstants.GET_USERS_ERROR, error};
  }
}

function removeList(data) {
  return dispatch => {
    dispatch(request());
    return APIHelper.post(
      `${path.remove_users}`,
      data
    ).then(
      res => {
        dispatch(success(res?.data?.results, parseInt(res?.data?.count || 0)));
        return Promise.resolve();
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: usersConstants.REMOVE_USERS};
  }

  function success(payload) {
    return {type: usersConstants.REMOVE_USERS_SUCCESS, payload};
  }

  function failure(error) {
    return {type: usersConstants.REMOVE_USERS_ERROR, error};
  }
}

function getItem(id) {
  return dispatch => {
    dispatch(request());
    return APIHelper.get(
      `${path.users}/${id}`,
    ).then(
      res => {
        dispatch(success(res.data));
        return Promise.resolve(res.data);
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: usersConstants.GET_USER};
  }

  function success(payload) {
    // if (payload.groups.length) { // TODO переделать этот костыль
    payload['groups'] = payload.groups?.map(e => e.id) || [];
    // }
    // if (payload.area) { // TODO переделать этот костыль
    payload['area'] = payload.area?.id;
    // }
    return {type: usersConstants.GET_USER_SUCCESS, payload};
  }

  function failure(error) {
    return {type: usersConstants.GET_USER_ERROR, error};
  }
}

function addItem(data) {
  return dispatch => {
    dispatch(request());
    return APIHelper.post(
      `${path.users}`,
      data
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: usersConstants.ADD_USERS};
  }

  function success(payload) {
    return {type: usersConstants.ADD_USERS_SUCCESS, payload};
  }

  function failure(error) {
    return {type: usersConstants.ADD_USERS_ERROR, error};
  }
}

function removeItem(id) {
  return (dispatch) => {
    dispatch(request());
    return APIHelper.delete(
      `${path.users}/${id}`,
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: usersConstants.REMOVE_USERS};
  }

  function success(payload) {
    return {type: usersConstants.REMOVE_USERS_SUCCESS, payload};
  }

  function failure(error) {
    return {type: usersConstants.REMOVE_USERS_ERROR, error};
  }
}

function updateItem(id, data) {
  return dispatch => {
    dispatch(request());
    return APIHelper.put(
      `${path.users}/${id}`,
      data
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: usersConstants.UPDATE_USERS};
  }

  function success(payload) {
    return {type: usersConstants.UPDATE_USERS_SUCCESS, payload};
  }

  function failure(error) {
    return {type: usersConstants.UPDATE_USERS_ERROR, error};
  }
}

function editItem(data) {
  return {type: usersConstants.EDIT_USERS, data};
}

function clearCredentials() {
  return {type: usersConstants.CLEAR_USERS_CREDENTIALS};
}

function regenerateCredentials(id) {
  return (dispatch) => {
    dispatch(request());
    return APIHelper.post(
      `${path.users}/${id}/regenerate_password`,
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: usersConstants.REGENERATE_USERS_CREDENTIALS};
  }

  function success(payload) {
    return {type: usersConstants.REGENERATE_USERS_CREDENTIALS_SUCCESS, payload};
  }

  function failure(error) {
    return {type: usersConstants.REGENERATE_USERS_CREDENTIALS_ERROR, error};
  }
}

function setParams(data) {
  return {type: usersConstants.USERS_CHANGE_PARAMS, data};
}

function updateParams(data) {
  return {type: usersConstants.USERS_UPDATE_PARAMS, data};
}

function resetParams() {
  return {type: usersConstants.USERS_RESET_PARAMS};
}

function clear() {
  return {type: usersConstants.USERS_CLEAR};
}

function setPage(data) {
  return {type: usersConstants.USERS_CHANGE_PAGE, data};
}

function select(data) {
  return {type: usersConstants.SELECT_USERS, data};
}

function unselect() {
  return {type: usersConstants.UNSELECT_USERS};
}