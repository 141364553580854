import {GET_LOCAL_CREDENTIALS, GET_PROFILE, GET_PROFILE_SUCCESS, LOGIN, LOGIN_SUCCESS, LOGOUT} from '../constants';
import * as _ from 'lodash';

export const initialState = {
  user: {},
  headers: {},
  isLoggedIn: false,
  isLoggingIn: false,
  loading: false,
  xAuthToken: null,
};

export default (state = _.cloneDeep(initialState), action) => {
  switch (action.type) {
    case LOGIN:
    case GET_PROFILE:
      return {
        ...state,
        loading: true
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        isLoggedIn: true,
        loading: false,
        user: action.payload,
        xAuthToken: action.payload.xAuthToken,
      };

    case GET_LOCAL_CREDENTIALS:
      return {
        ...state,
        xAuthToken: action.payload.xAuthToken,
      };

    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };

    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};