module.exports = {
  mimeTypes: {
    documents: {
      PDF: 'application/pdf',
      DOC: 'application/msword',
      DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      RTF: 'text/richtext',
      TXT: 'text/plain',
      XLS: 'application/vnd.ms-excel'
    }
  },
  extensions: {
    documents: {
      PDF: 'pdf',
      DOC: 'doc',
      DOCX: 'docx',
    }
  },
  issues: {
    chatRequestStatus: {
      INITIAL: 0, //'Не запрашивал'
      REQUESTED: 1, //'Запросил чат'
      DECLINED: 2, //'Запрос отклонён'
      ACCEPTED: 3,// 'Запрос одобрен'
    },
    exportTemplate: {
      CATEGORY_CORRESPONDING: 0,
      ANO_FNS: 1,
      BULGARTABAK: 2,
    }
  },
};