import {
  ADD_DELETING_FILE,
  ADD_UPLOADING_FILE,
  CLEAR,
  REMOVE_FILE_FROM_DELETING,
  REMOVE_FILE_FROM_UPLOADING, UPLOAD_FILE, UPLOAD_FILE_ERROR,
  UPLOAD_FILE_SUCCESS
} from '../constants';
import * as _ from 'lodash';
import {emptyErrors} from '../../config/validationRules';

export const initialState = {
  uploadingMedia: [],
  deletingIDs: [],
  deletingNames: [],
  uploadedIDs: [],
  loading: false,
};

export default (state = _.cloneDeep(initialState), action) => {
  switch (action.type) {
    case ADD_DELETING_FILE:
      return {
        ...state,
        deletingIDs: state.deletingIDs.filter(e => e !== action.id).concat(action.id),
        deletingNames: state.deletingNames.filter(e => e !== action.name).concat(action.name)
      };

    case REMOVE_FILE_FROM_DELETING:
      return {
        ...state,
        deletingIDs: state.deletingIDs.filter(e => e !== action.id),
        deletingNames: state.deletingNames.filter(e => e !== action.name)
      };

    case ADD_UPLOADING_FILE:
      return {
        ...state,
        uploadingMedia: state.uploadingMedia.filter(e => action.media && (e.name !== action.media.file.name)).concat(action.media)
      };

    case REMOVE_FILE_FROM_UPLOADING:
      return {
        ...state,
        uploadingMedia: state.uploadingMedia.filter(e => action.media && (e.name !== action.media.file.name))
      };

    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        uploadingMedia: [],
        uploadedIDs: state.uploadedIDs.filter(e => !action.payload.ids.includes(e)).concat(action.payload.ids)
      };

    case UPLOAD_FILE:
      return {
        ...state,
        loading: true
      };

    case UPLOAD_FILE_ERROR:
      return {
        ...state,
        uploadingMedia: [],
        loading: false
      };

    case CLEAR:
      return _.cloneDeep(initialState);

    default:
      return state;
  }
};