import * as issueStatusesConstants from '../constants/issueStatuses';
import {APIHelper} from '../../helpers';
import {path} from '../../config/api';

export const issueStatusesActions = {
  getList,
  getItem,
  addItem,
  updateItem,
  editItem,
  removeItem,
  setPage,
  setParams,
  resetParams,
  updateParams,
  clear,
};

function getList(params) {
  return dispatch => {
    dispatch(request());
    return APIHelper.get(
      `${path.issueStatuses}`,
      {
        params
      }
    ).then(
      res => {
        dispatch(success(res?.data?.results, parseInt(res?.data?.count || 0)));
        return {
          list: res?.data?.results, totalCount: parseInt(res?.data?.count || 0)
        };
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: issueStatusesConstants.GET_ISSUE_STATUSES};
  }

  function success(payload, totalCount) {
    return {type: issueStatusesConstants.GET_ISSUE_STATUSES_SUCCESS, payload, totalCount};
  }

  function failure(error) {
    return {type: issueStatusesConstants.GET_ISSUE_STATUSES_ERROR, error};
  }
}

function getItem(id) {
  return dispatch => {
    dispatch(request());
    return APIHelper.get(
      `${path.issueStatuses}/${id}`
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: issueStatusesConstants.GET_ISSUE_STATUS};
  }

  function success(payload) {
    return {type: issueStatusesConstants.GET_ISSUE_STATUS_SUCCESS, payload};
  }

  function failure(error) {
    return {type: issueStatusesConstants.GET_ISSUE_STATUS_ERROR, error};
  }
}

function addItem(data) {
  return dispatch => {
    dispatch(request());
    return APIHelper.post(
      `${path.issueStatuses}`,
      data
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: issueStatusesConstants.ADD_ISSUE_STATUS};
  }

  function success(payload) {
    return {type: issueStatusesConstants.ADD_ISSUE_STATUS_SUCCESS, payload};
  }

  function failure(error) {
    return {type: issueStatusesConstants.ADD_ISSUE_STATUS_ERROR, error};
  }
}

function updateItem(id, data) {
  return dispatch => {
    dispatch(request());
    return APIHelper.put(
      `${path.issueStatuses}/${id}`,
      data
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: issueStatusesConstants.UPDATE_ISSUE_STATUS};
  }

  function success(payload) {
    return {type: issueStatusesConstants.UPDATE_ISSUE_STATUS_SUCCESS, payload};
  }

  function failure(error) {
    return {type: issueStatusesConstants.UPDATE_ISSUE_STATUS_ERROR, error};
  }
}

function editItem(data) {
  return {type: issueStatusesConstants.EDIT_ISSUE_STATUS, data};
}

function removeItem(id) {
  return (dispatch) => {
    dispatch(request());
    return APIHelper.delete(
      `${path.issueStatuses}/${id}`,
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: issueStatusesConstants.REMOVE_ISSUE_STATUS};
  }

  function success(payload) {
    return {type: issueStatusesConstants.REMOVE_ISSUE_STATUS_SUCCESS, payload};
  }

  function failure(error) {
    return {type: issueStatusesConstants.REMOVE_ISSUE_STATUS_ERROR, error};
  }
}

function setParams(data) {
  return {type: issueStatusesConstants.ISSUE_STATUSES_CHANGE_PARAMS, data};
}

function resetParams() {
  return {type: issueStatusesConstants.ISSUE_STATUSES_RESET_PARAMS};
}

function updateParams(data) {
  return {type: issueStatusesConstants.ISSUE_STATUSES_UPDATE_PARAMS, data};
}


function clear() {
  return {type: issueStatusesConstants.ISSUE_STATUSES_CLEAR};
}

function setPage(data) {
  return {type: issueStatusesConstants.ISSUE_STATUSES_CHANGE_PAGE, data};
}