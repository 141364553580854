import * as constants from '../constants/permissions';
import { pagination } from '../../config/settings';
import * as _ from 'lodash';
export const initialState = {
  item: null,
  list: [],
  totalCount: 0,
  loading: false,
  params: {
    ordering: 'id', limit: pagination.limit, offset: 0
  },
  pagesCount: 1,
  page: 1,
};

export default (state =  _.cloneDeep(initialState), action) => {
  switch (action.type) {
    case constants.GET_PERMISSIONS:
    case constants.GET_PERMISSION:
    case constants.UPDATE_PERMISSION:
    case constants.REMOVE_PERMISSION:
      return {
        ...state,
        loading: true
      };

    case constants.GET_PERMISSIONS_ERROR:
    case constants.GET_PERMISSION_ERROR:
    case constants.UPDATE_PERMISSION_ERROR:
    case constants.REMOVE_PERMISSION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case constants.GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
        item: null,
        totalCount: action.totalCount,
        pagesCount: Math.ceil(action.totalCount / pagination.limit)
      };

    case constants.GET_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [],
        item: action.payload,
      };

    case constants.EDIT_PERMISSION:
      return {
        ...state,
        item: action.data,
      };
    case constants.PERMISSIONS_CHANGE_PARAMS:
      return {
        ...state,
        params: action.data,
      };
    case constants.PERMISSIONS_CHANGE_PAGE:
      return {
        ...state,
        page: +action.data,
      };

    default:
      return state;
  }
};