import {createTheme} from '@mui/material/styles';
import {blueGrey, red} from '@mui/material/colors';

export default createTheme({
  palette: {
    primary: {
      main: '#7193BF',
    },
    secondary: {
      main: red[500],
    },
  },
  typography: {
    fontFamily: 'Raleway, Arial',
  },
  spacing: 4,
  components: {},
  overrides: {
    // MuiCssBaseline: {
    //   '@global': {
    //     // '@font-face': [raleway, montserrat],
    //   },
    // },
    // MuiButton: {
    //   root: {
    //     textTransform: 'none',
    //     fontFamily: 'Montserrat',
    //     fontSize: 16,
    //     fontWeight: 600,
    //     width: 150,
    //     height: 40,
    //     borderRadius: 40,
    //     backgroundColor: '#EB6B5E',
    //     marginBottom: 10,
    //     lineHeight: 1
    //   }
    // },
    // MuiDataGrid: {
    //   root: {
    //     '& .MuiDataGrid-columnHeaderTitle': {
    //       overflow: 'hidden',
    //       lineHeight: '20px',
    //       whiteSpace: 'normal'
    //     },
    //     '.MuiDataGrid-overlay': {
    //       height: 'auto !important',
    //     },
    //     border: 'none',
    //     '& .MuiDataGrid-main': {
    //       backgroundColor: '#fff',
    //       borderWidth: 1,
    //       borderRadius: 10,
    //       borderColor: '#c3bdc4',
    //     }
    //   },
    // },
    // MuiListItemIcon: {
    //   root: {
    //     display: 'flex',
    //     justifyContent: 'center'
    //   }
    // },
    // MuiButtonBase: {
    //   root: {
    //     color: 'black',
    //   }
    // },
    // MuiPickersModal: {
    //   dialogRoot: {
    //     '& .MuiButton-root': {
    //       width: 'auto',
    //       height: 'auto',
    //       borderRadius: 0,
    //       backgroundColor: 'unset',
    //       marginBottom: 'unset',
    //     }
    //   }
    // },
  }
});