export const GET_EMAILS = 'GET_EMAILS';
export const GET_EMAILS_ERROR = 'GET_EMAILS_ERROR';
export const GET_EMAILS_SUCCESS = 'GET_EMAILS_SUCCESS';

export const GET_EMAIL = 'GET_EMAIL';
export const GET_EMAIL_ERROR = 'GET_EMAIL_ERROR';
export const GET_EMAIL_SUCCESS = 'GET_EMAIL_SUCCESS';

export const EDIT_EMAIL = 'EDIT_EMAIL';

export const ADD_EMAIL = 'ADD_EMAIL';
export const ADD_EMAIL_ERROR = 'ADD_EMAIL_ERROR';
export const ADD_EMAIL_SUCCESS = 'ADD_EMAIL_SUCCESS';

export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_EMAIL_ERROR = 'UPDATE_EMAIL_ERROR';
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS';

export const REMOVE_EMAIL = 'REMOVE_EMAIL';
export const REMOVE_EMAIL_ERROR = 'REMOVE_EMAIL_ERROR';
export const REMOVE_EMAIL_SUCCESS = 'REMOVE_EMAIL_SUCCESS';

export const EMAILS_CHANGE_PARAMS = 'EMAILS_CHANGE_PARAMS';
export const EMAILS_UPDATE_PARAMS = 'EMAILS_UPDATE_PARAMS';
export const EMAILS_RESET_PARAMS = 'EMAILS_RESET_PARAMS';
export const EMAILS_CHANGE_PAGE = 'EMAILS_CHANGE_PAGE';

export const EMAILS_CLEAR = 'EMAILS_CLEAR';