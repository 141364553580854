import * as constants from '../constants/messages';
import {pagination} from '../../config/settings';
import * as _ from 'lodash';
export const initialState = {
  item: null,
  list: [],
  totalCount: 0,
  loading: false,
  params: {
    ordering: 'id', limit: pagination.limit, offset: 0
  },
  pagesCount: 1,
  page: 1,
};

export default (state =  _.cloneDeep(initialState), action) => {
  switch (action.type) {
    case constants.GET_MESSAGES:
    case constants.GET_MESSAGE:
    case constants.ADD_MESSAGE:
    case constants.UPDATE_MESSAGE:
    case constants.REMOVE_MESSAGE:
      return {
        ...state,
        loading: true
      };

    case constants.ADD_MESSAGE_ERROR:
    case constants.GET_MESSAGES_ERROR:
    case constants.GET_MESSAGE_ERROR:
    case constants.UPDATE_MESSAGE_ERROR:
    case constants.REMOVE_MESSAGE_ERROR:
      return {
        ...state,
        loading: false,
      };

    case constants.GET_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
        // item: null,
        totalCount: action.totalCount,
        pagesCount: Math.ceil(action.totalCount / pagination.limit)
      };

    case constants.ADD_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case constants.GET_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        // list: [],
        item: action.payload,
      };

    case constants.EDIT_MESSAGE:
      return {
        ...state,
        item: action.data,
      };
    case constants.MESSAGES_CHANGE_PARAMS:
      return {
        ...state,
        params: action.data,
      };
    case constants.MESSAGES_CHANGE_PAGE:
      return {
        ...state,
        page: +action.data,
      };

    case constants.MESSAGES_CLEAR:
      return {
        ...state,
        item: null,
        totalCount: 0,
        pagesCount: 1,
        page: 1,
        list: [],
      };

    default:
      return state;
  }
};