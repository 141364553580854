import {combineReducers} from 'redux';

import auth from './auth';
import alerts from './alerts';
import users from './users';
import staff from './staff';
import groups from './groups';
import cities from './cities';
import permissions from './permissions';
import areas from './areas';
import issues from './issues';
import departments from './departments';
import departments2 from './departments2';
import departmentsGroups from './departmentsGroups';
import issueStatuses from './issueStatuses';
import categories from './categories';
import media from './media';
import messages from './messages';
import units from './units';
import executors from './executors';
import executors2 from './executors2';
import analytics from './analytics';
import emails from './emails';

const rootReducer = combineReducers({
  auth,
  media,
  alerts,
  users,
  staff,
  groups,
  permissions,
  areas,
  cities,
  issues,
  departments,
  departments2,
  departmentsGroups,
  issueStatuses,
  categories,
  messages,
  units,
  executors,
  executors2,
  analytics,
  emails,
});

export default (state, action) => rootReducer(state, action);