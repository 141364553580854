import {styled} from '@mui/material/styles';
import {Button, Container, Fab, Grid, Modal, TextField, Typography} from '@mui/material';
import {blue, blueGrey, green, grey, lightBlue} from '@mui/material/colors';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import {MobileDatePicker, MobileDateTimePicker} from '@mui/x-date-pickers';
import {DataGrid} from '@mui/x-data-grid';
import LoadingButton from '@mui/lab/LoadingButton';

export const StyledContainer = styled(Container)(({theme}) => ({
  height: '100%',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  '& .dataGrid-column--header': {
    textTransform: 'capitalize',
    fontSize: 14,
    color: '#777'
  },
}));

export const EditPageContainer = styled('div')(({theme}) => ({
  height: '100%',
  padding: theme.spacing(4),
}));

export const ModalContainer = styled(Modal)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const ModalPaper = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.background.paper,
  border: '2px solid #000',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
}));

export const RenderData = styled('div')(({theme}) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: 3,
  whiteSpace: 'break-spaces',
  wordBreak: 'break-word',
  alignItems: 'start',
  '& div': {
    lineHeight: '17px'
  }
}));

export const RenderCounter = styled('div')(({theme}) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  flexWrap:'wrap',
  justifyContent: 'center',
  // padding: 3,
  alignItems: 'center',
  // '& div': {
  //   lineHeight: '17px'
  // }
}));

export const RenderMedia = styled('div')(({theme}) => ({
  minWidth: 80,
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const RenderImage = styled('img')(({theme}) => ({
  height: '100%',
}));

export const RenderModel = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  cursor: 'pointer',
  whiteSpace: 'break-spaces',
  wordBreak: 'break-word',
}));

export const RenderBreakSpaces = styled('div')(({theme}) => ({
  whiteSpace: 'break-spaces',
  wordBreak: 'break-word',
}));
export const NotificationOrderHeader = styled('div')(({theme}) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const StyledDataGrid = styled(DataGrid)(({theme}) => ({
  backgroundColor: 'white',
  '& .MuiDataGrid-renderingZone': {
    maxHeight: 'none !important',
  },
  '& .MuiDataGrid-cell': {
    lineHeight: 'unset !important',
    maxHeight: 'none !important',
    whiteSpace: 'break-spaces',
    wordBreak: 'break-word',
  },
  '& .MuiDataGrid-row': {
    maxHeight: 'none !important',
  },
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: blueGrey[300],
    minHeight: 56
  },
  '& .styled': {
    backgroundColor: blueGrey[100],
    '&:hover': {
      backgroundColor: blueGrey[200],
    },
  },
  '& .notification-sort': {
    cursor: 'pointer',
  }
}));


export const ImageField = styled('img')(({theme}) => ({
  cursor: 'pointer'
}));

export const DocImage = styled('img')(({theme}) => ({
  cursor: 'pointer',
  width: 'auto',
  height: 40,
  marginRight: theme.spacing(1)
}));

export const DocBlock = styled('div')(({theme}) => ({
  cursor: 'pointer',
  width: 'auto',
  height: 40,
}));
export const DocItem = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 5
}));
export const DocItemTitle = styled('div')(({theme}) => ({
  flexBasis: '75%'
}));

export const HiddenInput = styled('input')(({theme}) => ({
  display: 'none'
}));

export const StyledFab = styled(Fab)(({theme}) => ({
  color: blue[900],
  margin: 10
}));

export const StyledChipList = styled('div')(({theme}) => ({
  display: 'flex',
  flexWrap: 'wrap',
}));

export const StyledChip = styled(Chip)(({theme}) => ({
  margin: 2,
}));

export const StyledFormControl = styled(FormControl)(({theme}) => ({
  width: '100%',
}));

export const StyledLabel = styled(Typography)(({theme}) => ({
  color: grey[500],
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: 5,
  paddingRight: 5,
}));

export const StyledLabelLink = styled('a')(({theme}) => ({
  color: lightBlue[500],
  cursor: 'pointer'
}));

export const StyledGrid = styled(Grid)(({theme}) => ({
  minHeight: 56
}));

export const StyledGridPad = styled(Grid)(({theme}) => ({
  minHeight: 56,
  paddingLeft: 4
}));

export const ChatContainer = styled('div')(({theme}) => ({
  marginTop: theme.spacing(4),
  marginLeft: theme.spacing(4)
}));

export const IssuesContainer = styled('div')(({theme}) => ({
  marginTop: theme.spacing(4)
}));
export const IssuesTitle = styled('div')(({theme}) => ({
  marginBottom: theme.spacing(4)
}));

export const MessagesContainer = styled('div')(({theme}) => ({
  marginTop: theme.spacing(4)
}));

export const IssuesFilesContainer = styled('div')(({theme}) => ({
  marginTop: theme.spacing(4),
  borderColor: grey[200],
  borderWidth: '1px',
  borderStyle: 'solid',
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(2)
}));

export const MessagesList = styled('div')(({theme}) => ({}));

export const IssueFilesList = styled('div')(({theme}) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%'
}));

export const IssuesList = styled('div')(({theme}) => ({}));

export const MessageContainer = styled('div')(({theme}) => ({
  borderColor: grey[200],
  borderWidth: '1px',
  borderStyle: 'solid',
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(2)
}));

export const EmailContainer = styled('div')(({theme}) => ({
  borderColor: grey[200],
  margin: theme.spacing(2),
  width: '100%',
  borderWidth: '1px',
  borderStyle: 'solid',
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(2)
}));

export const IssueContainer = styled('div')(({theme}) => ({
  borderColor: grey[200],
  borderWidth: '1px',
  borderStyle: 'solid',
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(2),
  cursor: 'pointer'
}));

export const UserInfo = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  marginBottom: theme.spacing(3)
}));

export const UserName = styled('div')(({theme}) => ({
  // width: '50px',
  // height: '50px',
}));

export const UserRole = styled('div')(({theme}) => ({
  // width: '50px',
  // height: '50px',
  paddingRight: theme.spacing(2),
  color: green['A200']
}));
export const IssueFilesHeader = styled('div')(({theme}) => ({
  marginBottom: theme.spacing(4),
  color: grey[700],
}));


export const UserData = styled('div')(({theme}) => ({
  marginLeft: theme.spacing(3),
}));

export const EmailData = styled('div')(({theme}) => ({
}));

export const FromEmail = styled('div')(({theme}) => ({
  marginLeft: theme.spacing(1),
  marginBottom: theme.spacing(3)
}));

export const ToEmail = styled('div')(({theme}) => ({
  marginLeft: theme.spacing(1),
  marginBottom: theme.spacing(3)
}));

export const Subject = styled('div')(({theme}) => ({
  marginLeft: theme.spacing(1),
  marginBottom: theme.spacing(3)
}));

export const IssueHeader = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between'
}));

export const MessageOwner = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
}));

export const NewMessage = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%'
}));

export const MessageData = styled('div')(({theme}) => ({
  backgroundColor: grey[200],
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  marginBottom: theme.spacing(2)
}));

export const IssueData = styled('div')(({theme}) => ({
  backgroundColor: grey[200],
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  marginBottom: theme.spacing(2)
}));

export const MessageText = styled(Typography)(({theme}) => ({
  color: grey[700],
  whiteSpace: 'pre-line'
}));

export const MessageHtml = styled('div')(({theme}) => ({
  color: grey[700],
  // whiteSpace: 'pre-line'
}));

export const IssueText = styled(Typography)(({theme}) => ({
  color: grey[700],
}));

export const IssueLink = styled('a')(({theme}) => ({
  color: grey[700],
}));

export const SidebarContainer = styled('div')(({theme}) => ({
  // backgroundColor: '#7193BF',
}));

export const MessageDate = styled('div')(({theme}) => ({
  fontSize: '12px',
  color: grey[500]
}));

export const EmailDate = styled('div')(({theme}) => ({
  fontSize: '14px',
  color: grey[500],
  marginBottom: theme.spacing(3)
}));

export const EmailFields = styled('div')(({theme}) => ({
  fontSize: '14px',
  color: grey[600],
}));


export const Attachments = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column'
}));

export const Attachment = styled('div')(({theme}) => ({}));

export const FileName = styled('div')(({theme}) => ({}));

export const DownloadButton = styled(Button)(({theme}) => ({
  fontSize: 10,
  backgroundColor: '#7193BF',
  color: 'white',
  '&:hover': {
    backgroundColor: '#9bbae0',
  }
}));

export const AnalyticsDownloadButton = styled(LoadingButton)(({theme}) => ({
  width: '100%',
  minWidth: 110,
  backgroundColor: '#7193BF',
  color: 'white',
  '&:hover': {
    backgroundColor: '#9bbae0',
  }
}));

export const MessageControlContainer = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const FileIcon = styled('div')(({theme}) => ({
  width: '50px',
  height: '50px',
}));

export const AttachedImage = styled('img')(({theme}) => ({
  cursor: 'pointer',
  width: 'auto',
  maxWidth: '350px'
}));

export const AttachedFile = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  cursor: 'pointer',
  width: 'auto',
  // maxWidth: '350px'
}));

export const UploadingAttachments = styled('div')(({theme}) => ({
  // width: '100%'
}));

export const SendMessageButton = styled('div')(({theme}) => ({}));

export const DownloadDocsContainer = styled('div')(({theme}) => ({}));

export const DownloadReportsContainer = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'start'

}));

export const MessageInput = styled(TextField)(({theme}) => ({
  width: '100%',
  // padding: theme.spacing(2),
}));

export const StyledMobileDateTimePicker = styled(MobileDateTimePicker)(({theme}) => ({
  backgroundColor: 'white'
}));

export const StyledMobileDatePicker = styled(MobileDatePicker)(({theme}) => ({
  backgroundColor: 'white'
}));

export const FiltersTextField = styled(TextField)(({theme}) => ({
  margin: 4, width: '250px', backgroundColor: 'white'
}));