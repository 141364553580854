import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {PageLayout} from './sidebar';
import {Outlet} from '@mui/icons-material';
import {useDispatch, useSelector} from 'react-redux';

export const ProtectedRoute = ({isAllowed, children, redirectPath = '/login'}) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace/>;
  }
  return children ? <PageLayout>{children}</PageLayout> : <Outlet/>;
};

export const RequireAuth = ({children}) => {
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  let location = useLocation();

  if (!localStorage.getItem('xAuthToken')) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{from: location}} replace/>;
  }

  return children;
};