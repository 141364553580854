import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import * as dateFns from 'date-fns';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {Columns, CustomPagination, IssuesSearchAddFilterToolbar, onSortingChange} from '../components/dataGrid';

import {
  areasActions,
  categoriesActions,
  departmentsActions,
  executorsActions,
  issuesActions,
  issueStatusesActions,
  mediaActions,
  messagesActions,
  unitsActions
} from '../redux/actions';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {generateEmptyErrorsAndValidation} from '../config/validationRules';
import {validateForm} from '../helpers';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import {People} from '@mui/icons-material';

import {
  Attachments,
  ChatContainer,
  DownloadButton,
  EditPageContainer,
  IssueFilesHeader,
  IssueFilesList,
  IssuesFilesContainer,
  MessageContainer,
  MessageData,
  MessageDate,
  MessageHtml,
  MessageOwner,
  MessagesContainer,
  MessagesList,
  MessageText,
  StyledContainer,
  StyledDataGrid,
  StyledGrid,
  StyledLabel,
  StyledLabelLink,
  UserData,
  UserInfo,
  UserName,
  UserRole
} from '../components/styledComponents';
import {Avatar, Box, Button, Checkbox, FormControlLabel, Grid, IconButton, TextField} from '@mui/material';
import strings from '../config/strings';
import {issues} from '../config/enums';
import {useDebounce} from '../components/hooks';
import {
  renderArea,
  renderAttachment,
  renderCategory,
  renderCounter,
  renderDateTime,
  renderIssueLink,
  renderNewMessageInput,
  renderStatus,
  renderUnreadMessagesIndicator,
  renderUser,
  renderValue
} from '../components/renderFields';

import {
  getAsyncSelectField,
  getBooleanField,
  getDatePicker,
  getDateTimePicker,
  getNumberField,
  getSelectField,
  getTextField,
} from '../components/fieldsInItem';

import selectOptions from '../config/selectOptions';
import {port, url} from '../config/api';
import {renderChatSkeleton, renderItemSkeleton} from '../components/skeletons';
import {pagination} from '../config/settings';
import {grey} from '@mui/material/colors';
import {AcceptDeclineModal, ConfirmRemovingModal} from '../components/modal';

export const IssuesScreen = () => {
  const baseActions = issuesActions;

  const dispatch = useDispatch();
  const store = useStore();
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const model = useSelector(state => state.issues);

  const changeNotificationsOrder = () => {
    const oldOrdering = model.params.ordering;
    let newOrdering = '-unread_messages';
    if (oldOrdering === '-unread_messages') {
      newOrdering = '-chat_request';
    }
    if (oldOrdering === '-chat_request') {
      newOrdering = '-created_at';
    }
    dispatch(baseActions.updateParams({ordering: newOrdering}));
  };

  const columns = [
    // Columns.id(),
    Columns.unreadMessages(model, changeNotificationsOrder, renderUnreadMessagesIndicator(model)),
    Columns.number(renderIssueLink),
    Columns.createdAt(renderDateTime),
    Columns.issueStatus(renderStatus),
    Columns.counter(renderCounter),
    // Columns.address(renderCellExpand),
    Columns.address(renderValue),
    Columns.category(renderCategory),
    Columns.user(renderUser),
    Columns.area(renderArea),
    // Columns.description(renderCellExpand),
    // Columns.category(renderCategory),
  ];

  const rowSelected = ({id}) => {
    navigate(`/issues/${id}`);
  };

  const cellSelected = ({field, value, row}) => {
    if (field !== 'number') {
      navigate(`/issues/${row.id}`);
    }
  };

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    model.params.search = searchText;
    model.params.offset = 0;
    dispatch(issuesActions.setParams(model.params));
    dispatch(issuesActions.setPage(1));
  }, [useDebounce(searchText, 800)]);

  useEffect(() => {
      if (model.params.status !== undefined) {
        dispatch(baseActions.getList(model.params));
      }
    }, [model.page, model.params.search, model.params.ordering]
  );

  useEffect(() => {
    dispatch(baseActions.updateParams({
      status: query.get(['status']),
      search: searchText,
      offset: 0
    }));
    dispatch(issuesActions.setPage(1));
    dispatch(baseActions.getList(model.params));
  }, [query]);

  const handleFiltersChange = (field) => (value) => {
    let val = value ?? null;
    let updating = {};

    if (field === 'createdFrom' && val !== null) {
      val = new Date(value).setHours(0, 0, 0, 0);
    }

    if (field === 'createdTo' && val !== null) {
      val = new Date(value).setHours(23, 59, 59, 999);
    }

    updating[field] = val;
    dispatch(issuesActions.updateParams(updating));
  };

  const handleFiltersClear = async () => {
    dispatch(issuesActions.setPage(1));
    dispatch(issuesActions.resetParams());
    dispatch(issuesActions.getList(store.getState().issues.params));
  };

  const handleFiltersApply = () => {
    dispatch(issuesActions.getList(store.getState().issues.params));
  };

  return (
    <div style={{height: '90%', width: '100%'}}>
      <StyledDataGrid
        getRowClassName={() => 'styled'}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        columns={columns}
        rows={model.list}
        onSortModelChange={onSortingChange(model, dispatch, baseActions)}
        filterMode="server"
        // onRowClick={rowSelected}
        onCellClick={cellSelected}
        sortingMode="server"
        paginationMode="server"
        // filterModel={filterModel}
        // onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        loading={model.loading}
        components={{
          Toolbar: IssuesSearchAddFilterToolbar,
          Pagination: CustomPagination(model, dispatch, baseActions),
        }}
        componentsProps={{
          toolbar: {
            disableAdding: true,
            path: 'issues',
            model: model,
            handleFiltersChange: handleFiltersChange,
            handleFiltersClear: handleFiltersClear,
            handleFiltersApply: handleFiltersApply,
            value: searchText,
            onChange: (event) => setSearchText(event.target.value),
            clearSearch: () => setSearchText(''),
          },
        }}
      />
    </div>
  );
};


const {fieldsInitState, fieldsValidators} = generateEmptyErrorsAndValidation([
  'number',
  'stallNumber',
  'legalEntity',
  'productName',
  'visitDate',
  'description',
  'address',
  'additionalInfo',
  'lat',
  'lon',
  // 'resolution',
  'status',
  'violationType',
  'title',
  'user',
  'category',
  'files',
  'sendReplyToUser',
  'shopName',
  'kusp',
  'seizedProductCount',
  'internalExecutor',
  'internalExecutor2',
]);


export const IssueEditScreen = () => {
  const dispatch = useDispatch();
  const {id} = useParams();
  const navigate = useNavigate();
  const store = useStore();

  const model = useSelector(state => state.issues);
  const messages = useSelector(state => state.messages);
  const executors = useSelector(state => state.executors);
  const executors2 = useSelector(state => state.executors2);
  const issueStatuses = useSelector(state => state.issueStatuses);
  const units = useSelector(state => state.units);
  const areas = useSelector(state => state.areas);
  const departments = useSelector(state => state.departments);
  const departments2 = useSelector(state => state.departments2);
  const categories = useSelector(state => state.categories);
  const media = useSelector(state => state.media);

  const [state, setState] = useState(fieldsInitState);

  const [chatRequested, setChatRequested] = useState(false);

  const handleChange = (field) => (e) => {
    setState(prevState => ({
      ...prevState, [field]: {
        'error': false,
        'helperText': null,
      }
    }));
    let updated = model.item;
    updated[field] = e?.target?.value ?? e;
    dispatch(issuesActions.editItem(updated));
  };

  const handleSelectChange = (field) => async (e) => {
    let updated = model.item;
    updated[field] = e.target.value;
    if (field === 'area') {
      // Автоподстановка юнита
      const area = areas.list.find(a => a.id === e.target.value);
      updated['unit'] = area?.unit;
      ////

      Promise.all([
        dispatch(departmentsActions.clear()),
        dispatch(departmentsActions.updateParams({
          area: e.target.value,
          limit: 50,
          offset: 0
        }))
      ]).then(() => {
        dispatch(departmentsActions.getList(store.getState().departments.params));
      });
    }
    dispatch(issuesActions.editItem(updated));
  };

  const handleCheckboxChange = (field) => (e) => {
    let updated = model.item;
    updated[field] = e.target.checked;
    dispatch(issuesActions.editItem(updated));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = validateForm(model.item, fieldsValidators, setState);
    if (data) {
      dispatch(issuesActions.updateItem(id, data)).then(() => {
        location.reload();
      });
    }
  };

  const handleOneFieldSubmit = (field) => () => {
    let data = {};
    data[field] = model.item[field];
    dispatch(issuesActions.updateItemNoLoadingEvent(id, data));
  };

  const handleCancel = () => {
    dispatch(issuesActions.editItem({}));
    navigate('/issues');
  };

  const [removing, setRemoving] = useState(false);
  const handleRemove = () => {
    setRemoving(true);
  };
  const closeRemoving = () => {
    setRemoving(false);
  };

  const confirmRemove = () => {
    dispatch(issuesActions.removeItem(id)).then(() => {
      setRemoving(false);
      navigate('/issues');
    });
  };

  const handleMessageChange = (field) => (e) => {
    setState(prevState => ({
      ...prevState, [field]: {
        'error': false,
        'helperText': null,
      }
    }));
    let updated = messages.item;
    updated[field] = e?.target !== undefined ? e.target.value : e;
    dispatch(messagesActions.editItem(updated));
  };

  useEffect(() => {
      dispatch(issuesActions.getItem(id)).then(res => {
        dispatch(issueStatusesActions.getList({}));
        dispatch(categoriesActions.getList({}));
        dispatch(unitsActions.getList({}));
        // dispatch(areasActions.getList({}));
        // dispatch(executorsActions.getList({}));
        dispatch(departmentsActions.updateParams({area: res.area}));
        // dispatch(departmentsActions.getList({area: res.area}));
        dispatch(messagesActions.getList({issue: id})).then(res => {
          if (res.results && res.results.length) {
            dispatch(issuesActions.updateItemNoLoadingEvent(id, {
              adminLastReadMsgId: res.results[0].id
            }));
          }
        });
        dispatch(messagesActions.editItem({
          entityId: id
        }));
        // console.log(res);
        if (res.chatRequestStatus === issues.chatRequestStatus.REQUESTED) {
          setChatRequested(true);
        }
      });
    }, []
  );

  const renderAvatar = (user) => {
    let link = People;
    if (user?.image) {
      link = `${url}:${port}${user.image['link']}`;
      return <Avatar src={link}/>;
    }
    return <Avatar child={People}/>;
  };

  const renderVisibility = (model) => {
    let {isVisible} = model;
    let Visible = VisibilityOffIcon;
    let visibilityColor = grey[500];
    if (isVisible) {
      visibilityColor = grey[300];
      Visible = VisibilityIcon;
    }
    return <IconButton
      sx={{
        marginLeft: 'auto',
        marginRight: 2
      }}
      onClick={() => {
        dispatch(messagesActions.updateItemNoLoadingEvent(model.id, {
          isVisible: !model.isVisible
        })).then(
          () => dispatch(messagesActions.getList({issue: id})).then(
            res => {
              if (res.results && res.results.length) {
                dispatch(issuesActions.updateItemNoLoadingEvent(id, {
                  adminLastReadMsgId: res.results[0].id
                }));
              }
            }
          ));
      }}>
      <Visible sx={{
        height: 24,
        width: 24,
        color: visibilityColor
      }}/>
    </IconButton>;
  };

  const handleSubmitSend = (e) => {
    e.preventDefault();
    const hasText = true; //!!messages.item.text
    if (hasText) {
      Promise.all(media.uploadingMedia.map(async m => {
        if (m.file) {
          let formData = new FormData();
          formData.append('file', m.file);
          await dispatch(mediaActions.uploadFile(formData));
        }
      })).then(() => {
        let data = messages.item;
        const uploadedIDs = store.getState().media.uploadedIDs;
        if (uploadedIDs?.length) {
          data['files'] = uploadedIDs;
        }
        dispatch(messagesActions.addItem(data)).then(() => {
          dispatch(mediaActions.clear());
          dispatch(messagesActions.getList({issue: id}));
          dispatch(messagesActions.editItem({entityId: id}));
        });
      });
    }
  };

  const renderMessage = (message, index) => {
    return message ? (<MessageContainer key={message?.id}>
      <UserInfo>
        {renderAvatar(message.user)}
        {message.user ? (
          <UserData>
            <MessageOwner>
              <UserRole>
                {selectOptions.profile.type.find(e => e.id === message.user.type).title}
              </UserRole>
              <UserName>
                {message.user.lastName} {message.user.middleName} {message.user.firstName}
              </UserName>
            </MessageOwner>
            <MessageDate>{dateFns.format(message.createdAt, 'yyyy.MM.dd HH:mm:SS')}</MessageDate>
          </UserData>
        ) : <UserData>
          <MessageOwner>
            <UserRole>
              {strings.system}
            </UserRole>
            <UserName>
            </UserName>
          </MessageOwner>
          <MessageDate>{dateFns.format(message.createdAt, 'yyyy.MM.dd HH:mm:SS')}</MessageDate>
        </UserData>}
        {renderVisibility(message)}
      </UserInfo>
      {message.isParsedFromEmail
        ? message.html && message.html.trim()
          ? <MessageData><MessageHtml dangerouslySetInnerHTML={{__html: message.html}}></MessageHtml></MessageData>
          : null
        : message.text && message.text.trim()
          ? <MessageData><MessageText>{message.text}</MessageText></MessageData>
          : null
      }
      <Attachments>
        {(message.files || []).map((e, index) => renderAttachment(e, index))}
      </Attachments>
    </MessageContainer>) : '';
  };

  const referToDownloadDoc = (template) => {
    dispatch(mediaActions.downloadFile(`${url}:${port}/api/issue/${id}/as-docx/${template}`));
  };

  const referToDownloadPdf = (template) => {
    dispatch(mediaActions.downloadFile(`${url}:${port}/api/issue/${id}/as-pdf/${template}`));
  };

  const renderCanSendCheckbox = (model, field, handleChange, handleSubmit) => {
    const submitCanSend = (value) => {
      (handleChange(field))(value);
      handleSubmit();
    };

    return model.item && (
      <FormControlLabel
        sx={{
          marginLeft: 0
        }}
        control={
          <Checkbox
            sx={{
              padding: 'unset'
            }}
            checked={model.item[field] || false}
            onChange={submitCanSend}
            name={field}
          />
        }
        label={strings[field]}
      />
    );
  };

  const handleAsyncSelectChange = (field) => (e) => {
    let updated = model.item;
    updated[field] = e?.value ?? null;
    if (field === 'area') {
      // Автоподстановка юнита
      const area = areas.list.find(a => a.id === e?.value);
      updated['unit'] = area?.unit;
      Promise.all([
        dispatch(departmentsActions.clear()),
        dispatch(departmentsActions.clear(true)),
        dispatch(departmentsActions.updateParams({
          area: e?.value,
          limit: 50,
          offset: 0
        })),
        dispatch(departmentsActions.updateParams({
          area: e?.value,
          limit: 50,
          offset: 0
        }, true))
      ]).then(() => {
        dispatch(departmentsActions.getList(store.getState().departments.params, false));
        dispatch(departmentsActions.getList(store.getState().departments2.params, true));
      });
    }
    dispatch(issuesActions.editItem(updated));
  };

  const fetchMoreDepartments = (second = false) => async (search) => {
    let params = store.getState().departments.params;
    if (second) {
      params = store.getState().departments2.params;
    }
    let offset = 0;
    if (search === params.search) {
      offset = params.offset + pagination.limit;
    }

    dispatch(departmentsActions.updateParams({
      limit: pagination.limit,
      ordering: 'id',
      offset: offset,
      search: search
    }, second));
    if (second) {
      return dispatch(departmentsActions.getList(store.getState().departments2.params, second));
    }
    return dispatch(departmentsActions.getList(store.getState().departments.params));
  };

  const fetchMoreAreas = async (search) => {
    const {params} = store.getState().areas;
    let offset = 0;
    if (search === params.search) {
      offset = params.offset + pagination.limit;
    }

    dispatch(areasActions.updateParams({
      limit: pagination.limit,
      ordering: 'id',
      offset: offset,
      search: search
    }));
    return dispatch(areasActions.getList(store.getState().areas.params));
  };

  const fetchMoreExecutors = (second = false) => async (search) => {
    let params = store.getState().executors.params;
    if (second) {
      params = store.getState().executors2.params;
    }
    let offset = 0;
    if (search === params.search) {
      offset = params.offset + pagination.limit;
    }

    dispatch(executorsActions.updateParams({
      limit: pagination.limit,
      ordering: 'id',
      offset: offset,
      search: search
    }, second));

    if (second) {
      return dispatch(executorsActions.getList(store.getState().executors2.params, second));
    }
    return dispatch(executorsActions.getList(store.getState().executors.params));
  };

  const acceptRequest = () => {
    dispatch(issuesActions.updateItemNoLoadingEvent(id, {
      chatRequestStatus: issues.chatRequestStatus.ACCEPTED
    })).then(() => {
      setChatRequested(false);
    });
  };

  const declineRequest = () => {
    dispatch(issuesActions.updateItemNoLoadingEvent(id, {
      chatRequestStatus: issues.chatRequestStatus.DECLINED
    })).then(() => {
      setChatRequested(false);
    });
  };

  return (
    <EditPageContainer>
      {!model.loading && model.item ?
        <>
          <AcceptDeclineModal
            open={!!chatRequested}
            onClose={() => {
              setChatRequested(false);
            }}
            onAccept={acceptRequest}
            onDecline={declineRequest}
            text={'Заявитель запросил чат по данному обращению'}
          />
          <ConfirmRemovingModal
            open={!!removing}
            onClose={closeRemoving}
            onConfirm={confirmRemove}
            text={`Обращение ID:${id} - №${model.item.number}`}
          />
        </> : ''
      }
      <Grid container>
        <Grid item xs={12} md={6} lg={4}>
          <StyledContainer maxWidth="xl" sx={{
            height: 'auto',
          }}>
            {!model.loading && model.item ?
              <form onSubmit={handleSubmit}>
                <Box sx={{flexGrow: 1}}>
                  <Grid container spacing={2}>
                    <Grid container={true} item xs={12} mt={6} mb={4} justifyContent={'space-between'}>
                      <Grid xs={12} mb={4} item display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <Grid xs={6} item display={'flex'} alignItems={'center'} justifyContent={'center'}>
                          <DownloadButton
                            onClick={() => referToDownloadDoc(issues.exportTemplate.CATEGORY_CORRESPONDING)}
                            endIcon={<TextSnippetIcon/>}>Скачать заявление в
                            doc</DownloadButton>
                        </Grid>
                        <Grid xs={6} item display={'flex'} alignItems={'center'} justifyContent={'center'}>
                          <DownloadButton
                            onClick={() => referToDownloadPdf(issues.exportTemplate.CATEGORY_CORRESPONDING)}
                            endIcon={<PictureAsPdfIcon/>}>Скачать заявление в
                            pdf</DownloadButton>
                        </Grid>
                      </Grid>
                      <Grid xs={12} mb={4} item display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <Grid xs={6} item display={'flex'} alignItems={'center'} justifyContent={'center'}>
                          <DownloadButton onClick={() => referToDownloadDoc(issues.exportTemplate.ANO_FNS)}
                                          endIcon={<TextSnippetIcon/>}>Заявление ФНС в
                            doc</DownloadButton>
                        </Grid>
                        <Grid xs={6} item display={'flex'} alignItems={'center'} justifyContent={'center'}>
                          <DownloadButton onClick={() => referToDownloadPdf(issues.exportTemplate.ANO_FNS)}
                                          endIcon={<PictureAsPdfIcon/>}>Заявление ФНС в
                            pdf</DownloadButton>
                        </Grid>
                      </Grid>
                      <Grid xs={12} item display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <Grid xs={6} item display={'flex'} alignItems={'center'} justifyContent={'center'}>
                          <DownloadButton
                            onClick={() => referToDownloadDoc(issues.exportTemplate.BULGARTABAK)}
                            endIcon={<TextSnippetIcon/>}
                          >Булгартабак в doc</DownloadButton>
                        </Grid>
                        <Grid xs={6} item display={'flex'} alignItems={'center'} justifyContent={'center'}>
                          <DownloadButton
                            onClick={() => referToDownloadPdf(issues.exportTemplate.BULGARTABAK)}
                            endIcon={<PictureAsPdfIcon/>}
                          >Булгартабак в pdf</DownloadButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    {getTextField(model, state, handleChange, 'number', {disabled: true})}
                    {getTextField(model, state, handleChange, 'email', {disabled: true})}
                    {getDateTimePicker(model, state, handleChange, 'createdAt', {disabled: true})}
                    {getSelectField(issueStatuses, model, state, handleSelectChange, 'status', 'name', ['name'])}
                    {getSelectField(selectOptions.issue.violationType, model, state, handleSelectChange, 'violationType', 'title', ['title'], {
                      disabled: false,
                    })}
                    {getSelectField(categories, model, state, handleSelectChange, 'category', 'name', ['name'])}
                    {getAsyncSelectField(executors, model, state, handleAsyncSelectChange, fetchMoreExecutors(false), 'internalExecutor', 'name', ['name', 'email'], model.item._internalExecutor, true)}
                    {getAsyncSelectField(executors2, model, state, handleAsyncSelectChange, fetchMoreExecutors(true), 'internalExecutor2', 'name', ['name', 'email'], model.item._internalExecutor2, true)}
                    {getAsyncSelectField(areas, model, state, handleAsyncSelectChange, fetchMoreAreas, 'area', 'name', ['id', 'name'], model.item._area, true)}
                    {/*{getSelectField(areas, model, state, handleSelectChange, 'area', 'name', ['name'])}*/}
                    {getSelectField(units, model, state, handleSelectChange, 'unit', 'title', ['title'])}
                    {getSelectField(selectOptions.issue.buItms, model, state, handleSelectChange, 'buItms', 'title', ['title'], {
                      disabled: false,
                    })}
                    {getAsyncSelectField(departments, model, state, handleAsyncSelectChange, fetchMoreDepartments(false), 'department', 'title', ['id', 'title'], model.item._department, true)}
                    {getAsyncSelectField(departments2, model, state, handleAsyncSelectChange, fetchMoreDepartments(true), 'department2', 'title', ['id', 'title'], model.item._department2, true)}
                    {getTextField(model, state, handleChange, 'kusp')}
                    {getNumberField(model, state, handleChange, 'seizedProductCount')}
                    <Grid container={true} xs={12} item mt={4} mb={4} justifyContent={'space-around'}>
                      <Grid xs={4} item display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <Button
                          sx={{
                            fontSize: '0.8rem'
                          }}
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          {strings.button.save}
                        </Button>
                      </Grid>
                      <Grid xs={2} item display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <Button
                          sx={{
                            fontSize: '0.8rem'
                          }}
                          type="button"
                          color="primary"
                          variant="contained"
                          onClick={handleCancel}
                        >
                          {strings.button.back}
                        </Button>
                      </Grid>
                      <Grid xs={6} item display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <Button
                          sx={{
                            fontSize: '0.8rem'
                          }}
                          type="button"
                          color="secondary"
                          variant="contained"
                          onClick={handleRemove}
                        >
                          {strings.button.removeIssue}
                        </Button>
                      </Grid>
                    </Grid>
                    {getTextField(model, state, handleChange, 'title')}
                    {getTextField(model, state, handleChange, 'address')}
                    {getTextField(model, state, handleChange, 'additionalInfo')}
                    {getTextField(model, state, handleChange, 'shopName')}
                    {getTextField(model, state, handleChange, 'legalEntity')}
                    {getTextField(model, state, handleChange, 'stallNumber')}
                    {getTextField(model, state, handleChange, 'productName')}
                    {getTextField(model, state, handleChange, 'description')}
                    {getDatePicker(model, state, handleChange, 'visitDate', {disabled: false})}
                    {getSelectField(selectOptions.issue.isReceivedReceipt, model, state, handleSelectChange, 'isReceivedReceipt', 'title', ['title'], {
                      disabled: false,
                    })}
                    {getBooleanField(model, state, handleCheckboxChange, 'isSellingUnderStall')}
                    <Grid item container sx={{
                      marginTop: 4,
                      marginLeft: 2,
                      marginBottom: 2,
                      backgroundColor: grey[100],
                      padding: 2,
                      borderRadius: 2
                    }}>
                      <StyledGrid sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }} item xs={12}>
                        <StyledLabel sx={{
                          width: '100%'
                        }}>
                          <span>Пользователь</span>
                          <StyledLabelLink
                            href={`/users/${model.item.user.id}`}
                          >перейти в профиль</StyledLabelLink>
                        </StyledLabel>
                      </StyledGrid>
                      <StyledGrid item xs={12}>
                        <TextField
                          disabled={true}
                          fullWidth
                          size="small"
                          label={strings['firstName']}
                          name={'firstName'}
                          value={model.item.user?.firstName || ''}
                        />
                      </StyledGrid>
                      <StyledGrid item xs={12}>
                        <TextField
                          disabled={true}
                          fullWidth
                          size="small"
                          label={strings['lastName']}
                          name={'lastName'}
                          value={model.item.user?.lastName || ''}
                        />
                      </StyledGrid>
                      <StyledGrid item xs={12}>
                        <TextField
                          disabled={true}
                          fullWidth
                          size="small"
                          label={strings['email']}
                          name={'email'}
                          value={model.item.user?.email || ''}
                        />
                      </StyledGrid>
                      <StyledGrid item xs={12}>
                        <TextField
                          disabled={true}
                          fullWidth
                          size="small"
                          label={'Телефон'}
                          name={'number'}
                          value={model.item.user?.number || ''}
                        />
                      </StyledGrid>
                    </Grid>
                  </Grid>
                </Box>
              </form> : <div>{renderItemSkeleton()}{renderItemSkeleton()}</div>
            }
          </StyledContainer>
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <ChatContainer>
            {renderNewMessageInput(messages, 'text', 'files', handleMessageChange, handleSubmitSend)}
            {renderCanSendCheckbox(model, 'sendReplyToUser', handleCheckboxChange, handleOneFieldSubmit('sendReplyToUser'))}

            <MessagesContainer>
              {
                messages.loading ?
                  renderChatSkeleton()
                  :
                  <MessagesList>
                    {(messages.list || []).map((e, index) => renderMessage(e, index))}
                  </MessagesList>
              }
            </MessagesContainer>
            <IssuesFilesContainer>
              <IssueFilesHeader>Файлы обращения</IssueFilesHeader>
              {
                model.loading || messages.loading ? renderChatSkeleton() :
                  model.item ?
                    <IssueFilesList>
                      {model.item.files.length ? (model.item.files || []).map((e, index) => renderAttachment(e, index)) : '...'}
                    </IssueFilesList> :
                    ''
              }
            </IssuesFilesContainer>
          </ChatContainer>
        </Grid>
      </Grid>
    </EditPageContainer>
  );
};