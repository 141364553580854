import * as React from 'react';
import {useEffect} from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import {styled} from '@mui/material/styles';
import {Link, useLocation, useNavigate, useSearchParams} from 'react-router-dom';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {Apartment, List as ListIcon, People, Hub, AlternateEmail} from '@mui/icons-material';

import Logo from '../img/logo.png';
import {useDispatch, useSelector} from 'react-redux';
import {authActions, issueStatusesActions} from '../redux/actions';
import MenuItem from '@mui/material/MenuItem';
import {blue} from '@mui/material/colors';
import {SidebarContainer} from './styledComponents';
import {permissions} from '../config/settings';
import {analyticsOnly} from '../helpers';

const drawerWidth = 270;

const SidebarLink = styled(Link)(({theme}) => ({
  textDecoration: 'inherit',
  color: 'black',
}));

const LogoutButton = styled(Button)(({theme}) => ({
  textDecoration: 'inherit',
  color: 'white',
}));

const StyledDrawer = styled(Drawer)(({theme}) => ({
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      boxSizing: 'border-box',
    },
  }
));

const SidebarListItemButton = (props) => (
  <ListItemButton
    sx={{
      '&:hover': {
        fontWeight: 'bold',
        boxShadow: 1,
        // backgroundColor: '#7193BF',
      }
    }}
  >
    {props.children}
  </ListItemButton>
);

const CustomToolbar = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(authActions.logout());
    location.reload();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#7193BF',
      }}
    >
      {/*App Logo*/}
      <Avatar
        src={Logo}
        alt="404"
        sx={{width: 100, height: 100, alignSelf: 'center', margin: 1}}
      />
      <Box>
        <LogoutButton
          onClick={handleLogout}
        >
          <ExitToAppIcon/>
        </LogoutButton>
      </Box>

    </Box>
  );
};

const PathNames = {
  '/analytics': 'Аналитика',
  '/users': 'Пользователи',
  '/users/add': 'Добавить пользователя',
  '/users/edit': 'Просмотр пользователя',
  // '/groups': 'Группы',
  // '/groups/add': 'Добавить группу',
  // '/groups/edit': 'Просмотр группы',
  '/issues': 'Обращения',
  '/issues/add': 'Добавить обращение',
  '/issues/edit': 'Просмотр обращения',
  // '/categories': 'Категории обращений',
  // '/categories/add': 'Добавить категорию обращений',
  // '/categories/edit': 'Просмотр категории обращений',
  '/departments': 'Подразделения',
  '/departments/add': 'Добавить подразделение',
  '/departments/edit': 'Просмотр подразделения',
  '/departments_groups': 'Группы подразделений',
  '/departments_groups/add': 'Добавить группу подразделений',
  '/departments_groups/edit': 'Просмотр группы подразделений',
  '/emails': 'Почта',
  '/emails/edit': 'Просмотр почты',
};

const renderIssuesSubmenuItem = (item, query, navigate) => {

  return <MenuItem
    key={item.id}
    onClick={() => {
      navigate(`/issues?status=${item.id}`);
    }}
    sx={{
      width: 'calc(100%-4px)',
      marginLeft: 4,
      position: 'relative',
      paddingLeft: 2,
      marginBottom: 1,
      overflowX: 'hidden',
      // color: 'white',
      backgroundColor: '' + query.get('status') === '' + item.id ? item.color + '90' : item.color + '30',
      borderRadius: 1,
      '&:hover': {
        backgroundColor: item.color + '90',
      }
    }}
  >
    <Box sx={{
      backgroundColor: blue[500],
      width: '40px',
      borderRadius: 1,
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      right: 0,
      color: 'white',
      marginRight: 1,
      fontSize: 12
    }}>
      {item.issuesCount}
    </Box>
    <Box sx={{
      width: 180,
      whiteSpace: 'break-spaces',
      wordBreak: 'break-word'
    }}>{item.name}</Box>
  </MenuItem>;
};

const LinksList = ({submenuList, groups}) => {
  const navigate = useNavigate();
  const [query] = useSearchParams();

  const hasPermissions = (path) => {
    if (groups && groups.length) {
      for (let g of groups) {
        return !!(permissions[path] && permissions[path].includes(g.name));
      }
    }
    return false;
  };

  const statusesIdsOrdering = {
    '1': 1,
    '3': 2,

    '4': 3,
    '5': 4,
    '16': 5,
    '6': 6,
    '13': 7,
    '7': 8,

    '9': 9,
    '10': 10,

    '11': 11,

    '12': 12,
    '2': 13,
    '14': 14,
    '8': 15,

  };// id:position Пожелания заказчика по хардкорной сортировке, делаем на фронте

  return <List>
    {/*Обращения*/}

    {hasPermissions('issues') ?
      <SidebarLink to="/issues">
        <SidebarListItemButton>
          <ListItemIcon>
            <ListIcon sx={{}}/>
          </ListItemIcon>
          <ListItemText primary={PathNames['/issues']}/>
        </SidebarListItemButton>
      </SidebarLink> : ''
    }
    {hasPermissions('issues') && !!submenuList?.length && submenuList.sort(function (x, y) {
      return statusesIdsOrdering[x.id] - statusesIdsOrdering[y.id];
    }).map(e => renderIssuesSubmenuItem(e, query, navigate))}
    <Divider/>

    {/*Пользователи*/}
    {hasPermissions('users') ?
      <SidebarLink to="/users">
        <SidebarListItemButton>
          <ListItemIcon>
            <People sx={{}}/>
          </ListItemIcon>
          <ListItemText primary={PathNames['/users']}/>
        </SidebarListItemButton>
      </SidebarLink> : ''}
    <Divider/>

    {/*/!*Группы*!/*/}
    {/*<SidebarLink to="/groups">*/}
    {/*  <SidebarListItemButton>*/}
    {/*    <ListItemIcon>*/}
    {/*      <Groups/>*/}
    {/*    </ListItemIcon>*/}
    {/*    <ListItemText primary={PathNames['/groups']}/>*/}
    {/*  </SidebarListItemButton>*/}
    {/*</SidebarLink>*/}
    {/*<Divider/>*/}

    {/*/!*Категории Обращений*!/*/}
    {/*<SidebarLink to="/categories">*/}
    {/*  <SidebarListItemButton>*/}
    {/*    <ListItemIcon>*/}
    {/*      <Category/>*/}
    {/*    </ListItemIcon>*/}
    {/*    <ListItemText primary={PathNames['/categories']}/>*/}
    {/*  </SidebarListItemButton>*/}
    {/*</SidebarLink>*/}
    {/*<Divider/>*/}

    {/*Подразделения*/}
    {hasPermissions('departments') ?
      <SidebarLink to="/departments">
        <SidebarListItemButton>
          <ListItemIcon>
            <Apartment sx={{}}/>
          </ListItemIcon>
          <ListItemText primary={PathNames['/departments']}/>
        </SidebarListItemButton>
      </SidebarLink> : ''}
    <Divider/>

    {/*Группы подразделений*/}
    {hasPermissions('departments') ?
      <SidebarLink to="/departments_groups">
        <SidebarListItemButton>
          <ListItemIcon>
            <Hub sx={{}}/>
          </ListItemIcon>
          <ListItemText primary={PathNames['/departments_groups']}/>
        </SidebarListItemButton>
      </SidebarLink> : ''}
    <Divider/>

    {/*Аналитика*/}
    {hasPermissions('analytics') ?
      <SidebarLink to="/analytics">
        <SidebarListItemButton>
          <ListItemIcon>
            <BarChartIcon/>
          </ListItemIcon>
          <ListItemText primary={PathNames['/analytics']}/>
        </SidebarListItemButton>
      </SidebarLink> : ''}
    <Divider/>

    {/*Почта*/}
    {hasPermissions('emails') ?
      <SidebarLink to="/emails">
        <SidebarListItemButton>
          <ListItemIcon>
            <AlternateEmail/>
          </ListItemIcon>
          <ListItemText primary={PathNames['/emails']}/>
        </SidebarListItemButton>
      </SidebarLink> : ''}
    <Divider/>
  </List>;
};

const SideBar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const groups = JSON.parse(localStorage.getItem('authGroups'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const issueStatuses = useSelector(state => state.issueStatuses);

  useEffect(() => {
    let mappedGroups = groups && groups.length ? groups.map(e => e.name) : [];
    if (analyticsOnly(mappedGroups)) {
      // console.log('Запрос статистики');
    } else {
      dispatch(issueStatusesActions.getList());
    }
  }, []);


  const drawer = (
    <SidebarContainer>
      <CustomToolbar/>
      <Divider/>
      <Divider/>
      <LinksList submenuList={issueStatuses.list} groups={groups}/>
    </SidebarContainer>
  );

  const getPageTitle = () => {
    let transformedPathName = location.pathname.replace(/\/\d+\/?/g, '/edit');
    //цифры после / меняем на /edit
    return PathNames[transformedPathName] || 'Главная';
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: {sm: `calc(100% - ${drawerWidth}px)`},
          ml: {sm: `${drawerWidth}px`},
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{mr: 2, display: {sm: 'none'}}}
          >
            <MenuIcon/>
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {getPageTitle()}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
        aria-label="mailbox folders"
      >
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: {xs: 'block', sm: 'none'},
            '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: {xs: 'none', sm: 'block'},
            '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
};

export const PageLayout = ({children}) => (
  <Box sx={{display: 'flex', height: '100%'}}>
    <CssBaseline/>
    <SideBar/>
    <Box sx={{flexGrow: 1, p: 3, width: {sm: `calc(100% - ${drawerWidth}px)`}}}>
      <Toolbar/>
      {children}
    </Box>
  </Box>
);