import * as alertConstants from '../constants/alerts';

export const alertActions = {
  networkError,
  apiError,
  customError,
  clearAlert
};


function clearAlert() {
  return { type: alertConstants.CLEAR_ALERT };
}

function networkError() {
  return { type: alertConstants.NETWORK_ERROR };
}

function apiError(payload) {
  return { type: alertConstants.API_ERROR, payload };
}

function customError(payload) {
  return { type: alertConstants.CUSTOM_ERROR, payload };
}