module.exports = {
  url: process.env.REACT_APP_API_URL,
  port: process.env.REACT_APP_API_PORT,
  path: {
    auth: {
      login: '/api/user/auth'
    },
    analytics: {
      stats: '/api/admin/analytics/stats'
    },
    files: '/api/file',
    issues: '/api/admin/issues',
    messages: '/api/admin/messages',
    emails: '/api/admin/emails',
    issueStatuses: '/api/admin/issues/statuses',
    executors: '/api/admin/issues/executors',
    groups: '/api/admin/groups',
    categories: '/api/admin/categories',
    geo: {
      units: '/api/admin/units',
      areas: '/api/admin/areas',
      cities: '/api/admin/cities',
      departments: '/api/admin/departments',
      departments_groups: '/api/admin/departments_groups',
    },
    permissions: '/api/admin/permissions',
    users: '/api/admin/users',
    remove_users: '/api/admin/users/remove',
  }
};