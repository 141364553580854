import * as React from 'react';
import {PageLayout} from './sidebar';

const NotFound = () => (
  <PageLayout>
    <div className="">
      <p>Запрашиваемая страница не найдена</p>
    </div>
  </PageLayout>
);

export default NotFound;