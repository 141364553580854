import React from 'react';
import './css/App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Alert, Snackbar} from '@mui/material';
import {alertActions} from './redux/actions';
import NotFound from './components/notFound';
import {ProtectedRoute, RequireAuth} from './components/routing';
import {
  AnalyticsScreen,
  EmailEditScreen,
  EmailsScreen,
  IssueEditScreen,
  IssuesScreen,
  LandingScreen,
  LoginScreen,
  UserAddScreen,
  UserEditScreen,
  UsersScreen
} from './screens';
import {DepartmentAddScreen, DepartmentEditScreen, DepartmentsScreen} from './screens/departments';
import {
  DepartmentGroupAddScreen,
  DepartmentGroupEditScreen,
  DepartmentsGroupsScreen
} from './screens/departmentsGroups';

function App() {
  const alerts = useSelector(state => state.alerts);
  const dispatch = useDispatch();

  const open = () => {
    return !!alerts.message;
  };

  const handleClose = () => {
    dispatch(alertActions.clearAlert());
  };

  return (
    <div style={{height: '100%'}}>
      {alerts.message &&
        <Snackbar
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          open={open()}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={alerts.severity}>
            {alerts.message}
          </Alert>
        </Snackbar>
      }
      {alerts.errors && !!alerts.errors.length &&
        <Snackbar
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          open={open()}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <div>
            {alerts.errors.map(e =>
              <Alert onClose={handleClose} severity={alerts.severity}>
                {e.detail}
              </Alert>
            )}
          </div>
        </Snackbar>
      }
      <BrowserRouter>
        <Routes>
          <Route index exact path="/" element={<LandingScreen/>}/>
          <Route path="/login" element={<LoginScreen/>}/>
          <Route path="/analytics" element={
            <RequireAuth>
              <ProtectedRoute isAllowed={true}>
                <AnalyticsScreen/>
              </ProtectedRoute>
            </RequireAuth>
          }/>
          <Route path="/users" element={
            <RequireAuth>
              <ProtectedRoute isAllowed={true}>
                <UsersScreen/>
              </ProtectedRoute>
            </RequireAuth>
          }/>
          <Route path="/users/:id" element={
            <RequireAuth>
              <ProtectedRoute isAllowed={true}>
                <UserEditScreen/>
              </ProtectedRoute>
            </RequireAuth>
          }/>
          <Route path="/users/add" element={
            <RequireAuth>
              <ProtectedRoute isAllowed={true}>
                <UserAddScreen/>
              </ProtectedRoute>
            </RequireAuth>
          }/>
          {/*<Route path="/groups" element={*/}
          {/*  <RequireAuth>*/}
          {/*    <ProtectedRoute isAllowed={true}>*/}
          {/*      <GroupsScreen/>*/}
          {/*    </ProtectedRoute>*/}
          {/*  </RequireAuth>*/}
          {/*}/>*/}
          {/*<Route path="/groups/:id" element={*/}
          {/*  <RequireAuth>*/}
          {/*    <ProtectedRoute isAllowed={true}>*/}
          {/*      <GroupEditScreen/>*/}
          {/*    </ProtectedRoute>*/}
          {/*  </RequireAuth>*/}
          {/*}/>*/}
          <Route path="/issues" element={
            <RequireAuth>
              <ProtectedRoute isAllowed={true}>
                <IssuesScreen/>
              </ProtectedRoute>
            </RequireAuth>
          }/>
          <Route path="/issues/:id" element={
            <RequireAuth>
              <ProtectedRoute isAllowed={true}>
                <IssueEditScreen/>
              </ProtectedRoute>
            </RequireAuth>
          }/>

          {/*<Route path="/categories" element={*/}
          {/*  <RequireAuth>*/}
          {/*    <ProtectedRoute isAllowed={true}>*/}
          {/*      <CategoriesScreen/>*/}
          {/*    </ProtectedRoute>*/}
          {/*  </RequireAuth>*/}
          {/*}/>*/}
          {/*<Route path="/categories/:id" element={*/}
          {/*  <RequireAuth>*/}
          {/*    <ProtectedRoute isAllowed={true}>*/}
          {/*      <CategoryEditScreen/>*/}
          {/*    </ProtectedRoute>*/}
          {/*  </RequireAuth>*/}
          {/*}/>*/}
          {/*<Route path="/categories/add" element={*/}
          {/*  <RequireAuth>*/}
          {/*    <ProtectedRoute isAllowed={true}>*/}
          {/*      <CategoryAddScreen/>*/}
          {/*    </ProtectedRoute>*/}
          {/*  </RequireAuth>*/}
          {/*}/>*/}

          <Route path="/departments" element={
            <RequireAuth>
              <ProtectedRoute isAllowed={true}>
                <DepartmentsScreen/>
              </ProtectedRoute>
            </RequireAuth>
          }/>
          <Route path="/departments/:id" element={
            <RequireAuth>
              <ProtectedRoute isAllowed={true}>
                <DepartmentEditScreen/>
              </ProtectedRoute>
            </RequireAuth>
          }/>
          <Route path="/departments/add" element={
            <RequireAuth>
              <ProtectedRoute isAllowed={true}>
                <DepartmentAddScreen/>
              </ProtectedRoute>
            </RequireAuth>
          }/>

          <Route path="/departments_groups" element={
            <RequireAuth>
              <ProtectedRoute isAllowed={true}>
                <DepartmentsGroupsScreen/>
              </ProtectedRoute>
            </RequireAuth>
          }/>
          <Route path="/departments_groups/:id" element={
            <RequireAuth>
              <ProtectedRoute isAllowed={true}>
                <DepartmentGroupEditScreen/>
              </ProtectedRoute>
            </RequireAuth>
          }/>
          <Route path="/departments_groups/add" element={
            <RequireAuth>
              <ProtectedRoute isAllowed={true}>
                <DepartmentGroupAddScreen/>
              </ProtectedRoute>
            </RequireAuth>
          }/>
          <Route path="/emails" element={
            <RequireAuth>
              <ProtectedRoute isAllowed={true}>
                <EmailsScreen/>
              </ProtectedRoute>
            </RequireAuth>
          }/>
          <Route path="/emails/:id" element={
            <RequireAuth>
              <ProtectedRoute isAllowed={true}>
                <EmailEditScreen/>
              </ProtectedRoute>
            </RequireAuth>
          }/>
          <Route path="*" element={<NotFound/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}


export default App;