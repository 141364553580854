import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {analyticsActions, issuesActions} from '../redux/actions';
import {port, url} from '../config/api';
import {AnalyticsDownloadButton} from '../components/styledComponents';
import {FilterDatePicker} from '../components/datePickers';
import strings from '../config/strings';
import {Avatar, Box, Checkbox, FormControlLabel, Grid, Typography} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import {blueGrey, green, purple, red} from '@mui/material/colors';

import ExecutorsCount from '../img/executors-count.png';
import MessagesCount from '../img/messages-count.png';
import UsersCount from '../img/users-count.png';
import {PieChart} from 'react-minimal-pie-chart';
import {adminsModeratorsBatOnly, adminsOnly, analyticsOnly} from '../helpers';

export const AnalyticsScreen = () => {
  const dispatch = useDispatch();
  const issues = useSelector(state => state.issues);
  const analytics = useSelector(state => state.analytics);

  let [isAnalyticsOnly, setIsAnalyticsOnly] = useState(true);
  let [isAdmin, setIsAdmin] = useState(false);
  let [isAdminModerBat, setIsAdminModerBat] = useState(false);

  const initialChartState = [
    {field: 'progress_issues_count', title: 'Взято в работу: ', value: 10, percentage: 10, color: purple[500]},
    {field: 'incorrect_issues_count', title: 'Некорректных: ', value: 10, percentage: 10, color: red[500]},
    {field: 'completed_issues_count', title: 'Решенных: ', value: 10, percentage: 10, color: green[500]},
    {field: 'remained', title: 'Остальные: ', value: 10, percentage: 10, color: '#7193BF'},
  ];

  const [mapped, setChartData] = useState(initialChartState);

  const referToDownloadDoc = (format) => {
    dispatch(issuesActions.downloadFile(`${url}:${port}/api/issue/${format}-report`, issues.reportsParams, format));
  };
  const referToDownloadDocWithParams = (format, reportsParams) => {
    let paramsWithCategories = {};
    Object.assign(paramsWithCategories, reportsParams, issues.reportsParams);
    dispatch(issuesActions.downloadFile(`${url}:${port}/api/issue/${format}-report`, paramsWithCategories, format));
  };

  const handleFiltersChange = (field) => (value) => {
    let val = value ?? null;
    let updating = {};

    if (field === 'created_at_from' && val !== null) {
      val = new Date(value).setHours(0, 0, 0, 0);
    }

    if (field === 'created_at_to' && val !== null) {
      val = new Date(value).setHours(23, 59, 59, 999);
    }

    // Не отправляем в запросе false значения
    if (['is_jti', 'is_bat', 'is_pmi'].includes(field) && val === false) {
      val = null;
    }

    updating[field] = val;
    dispatch(issuesActions.updateReportsParams(updating));
  };

  useEffect(() => {
    let now = new Date();
    dispatch(issuesActions.updateReportsParams({
      created_at_from: new Date((new Date()).setFullYear(2022, 2, 1)).setHours(0, 0, 0, 0),
      created_at_to: (new Date).setHours(23, 59, 59, 999),
    }));

    dispatch(analyticsActions.getStats());

  }, []);

  useEffect(() => {
    if (analytics.stats) {

      let mappedGroups = analytics.stats.groups && analytics.stats.groups.length ? analytics.stats.groups.map(e => e.name) : [];
      setIsAnalyticsOnly(analyticsOnly(mappedGroups));
      setIsAdmin(adminsOnly(mappedGroups, !!analytics.stats.is_superuser));
      setIsAdminModerBat(adminsModeratorsBatOnly(mappedGroups, !!analytics.stats.is_superuser));

      let mappedData = [];

      let remainedCount = analytics.stats['all_issues_count'];
      for (let key in analytics.stats) {
        if (['progress_issues_count', 'incorrect_issues_count', 'completed_issues_count'].includes(key)) {
          let result = initialChartState.find(e => e.field === key);
          result.value = analytics.stats[key] || 0;
          result.title += analytics.stats[key] || '';
          remainedCount -= analytics.stats[key] || 0;
          result.percentage = parseInt((analytics.stats[key] || 0) / (analytics.stats['all_issues_count'] || 0) * 100);
          mappedData.push(result);
        }
      }
      let remained = initialChartState.find(e => e.field === 'remained');
      remained.value = remainedCount;
      remained.title += remainedCount;
      remained.percentage = (remainedCount / (analytics.stats['all_issues_count'] || 0) * 100);
      // mappedData.push(remained);

      setChartData(mappedData);
    }
  }, [analytics.stats]);

  const renderDownloadButton = (text, format) => {
    return <Grid container item xs={12} md={6} lg={4} sx={{
      padding: 2
    }}>
      <Grid container item xs={12} md={12} lg={12} sx={{
        backgroundColor: blueGrey[50],
        padding: 4
      }}>
        <Grid item xs={12} md={8} lg={8} justifyContent={'center'} alignItems={'center'} textAlign={'center'}
              display={'flex'} fontSize={14}>
          {text}
        </Grid>
        <Grid item xs={12} md={4} lg={4} justifyContent={'center'} alignItems={'center'} display={'flex'}>
          <AnalyticsDownloadButton
            disabled={issues.loading}
            loading={issues.downloadingReport === format}
            loadingPosition="start"
            onClick={() => referToDownloadDoc(format)}
            startIcon={<DownloadIcon sx={{color: 'white'}}/>}>
            Скачать
          </AnalyticsDownloadButton>
        </Grid>
      </Grid>
    </Grid>;
  };

  const renderDownloadButtonMaxTwo = (text, format, filterParams) => {
    return <Grid container item xs={12} md={6} lg={6} sx={{
      padding: 2
    }}>
      <Grid container item xs={12} md={12} lg={12} sx={{
        backgroundColor: blueGrey[50],
        padding: 4
      }}>
        <Grid item xs={12} md={8} lg={8} justifyContent={'center'} alignItems={'center'} textAlign={'center'}
              display={'flex'} fontSize={14}>
          {text}
        </Grid>
        <Grid item xs={12} md={4} lg={4} justifyContent={'center'} alignItems={'center'} display={'flex'}>
          <AnalyticsDownloadButton
            disabled={issues.loading}
            loading={issues.downloadingReport === format}
            loadingPosition="start"
            onClick={() => referToDownloadDocWithParams(format, filterParams)}
            startIcon={<DownloadIcon sx={{color: 'white'}}/>}>
            Скачать
          </AnalyticsDownloadButton>
        </Grid>
      </Grid>
    </Grid>;
  };

  const renderStatsItem = (img, title, count) => {
    return <Grid
      item
      xs={12}
      md={4}
      lg={4}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Avatar
        src={img}
        alt={title}
        sx={{width: 50, height: 50, alignSelf: 'center', margin: 1}}
      />
      <Box minHeight={30} display={'flex'} justifyContent={'center'} alignItems={'end'}>
        <Typography textAlign={'center'} fontSize={12}>{title}</Typography>
      </Box>
      <Box mt={4} display={'flex'} justifyContent={'center'} alignItems={'end'}>
        <Typography textAlign={'center'} fontSize={22} fontWeight={'bold'}>{count}</Typography>
      </Box>
    </Grid>;
  };

  const renderChart = (mappedData) => {
    return <PieChart
      style={{
        width: '100%',
        fontFamily:
          '"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif',
        fontSize: '8px',
      }}
      data={mappedData}
      // label={({dataEntry}) => dataEntry.percentage + '%'}
      labelPosition={72}
      radius={PieChart.defaultProps.radius - 6}
      background={'#7193BF'}
      lineWidth={60}
      totalValue={analytics.stats.all_issues_count}
      segmentsStyle={{transition: 'stroke .3s', cursor: 'pointer'}}
      animate
      labelStyle={{
        fill: '#fff',
        opacity: 0.75,
        pointerEvents: 'none',
      }}
    />;
  };

  const renderDetail = (text, value) => {
    return <Grid item xs={12} md={6} sx={{
      padding: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <Grid item sx={{
        backgroundColor: blueGrey[50],
        padding: 4
      }} xs={12} md={12}>
        <Typography>{text} : <span style={{
          fontWeight: 'bold'
        }}>{value}</span></Typography>

      </Grid>
    </Grid>;
  };

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'start',
      flexDirection: 'column',
      minHeight: 730
    }}>
      <Grid container mb={4} justifyContent={'center'} display={'flex'}>
        <Typography
          textAlign={'center'}
          fontSize={20}
        >
          Общие показатели работы системы
        </Typography>
      </Grid>

      <Grid container mb={8}>
        {renderStatsItem(ExecutorsCount, 'Кол-во территориальных органов в системе', analytics.stats ? analytics.stats.executors_count : 0)}
        {renderStatsItem(UsersCount, 'Кол-во пользователей', analytics.stats ? analytics.stats.users_count : 0)}
        {renderStatsItem(MessagesCount, 'Всего сообщений в чате', analytics.stats ? analytics.stats.messages_count : 0)}
      </Grid>
      <Grid container mt={8} mb={8}>
        <Grid item container xs={6} md={4} lg={2}>
          <Grid item xs={12} display={'flex'} alignItems={'start'} justifyContent={'center'}>
            {analytics.stats ? renderChart(mapped) : ''}
          </Grid>
        </Grid>
        <Grid
          item container xs={6} md={8} lg={8}
        >
          {renderDetail('Всего обращений', analytics.stats ? analytics.stats.all_issues_count : 0)}
          {renderDetail('Взято в работу', analytics.stats ? analytics.stats.progress_issues_count : 0)}
          {renderDetail('Некорректных', analytics.stats ? analytics.stats.incorrect_issues_count : 0)}
          {renderDetail('Решенных', analytics.stats ? analytics.stats.completed_issues_count : 0)}
        </Grid>
      </Grid>

      <Grid container item mt={8} md={12} xs={12} lg={12} mb={8}>
        <Grid item md={6} xs={12} lg={6}>
          <FilterDatePicker
            width={'90%'}
            clearable={false}
            size={'small'}
            label={strings['createdFrom']}
            name={'createdFrom'}
            value={issues.reportsParams['created_at_from']}
            onChange={(dateObj) => (handleFiltersChange('created_at_from'))(dateObj ? dateObj.getTime() : null)}
          />
        </Grid>
        <Grid item md={6} xs={12} lg={6}>
          <FilterDatePicker
            width={'90%'}
            clearable={false}
            size={'small'}
            label={strings['createdTo']}
            name={'createdTo'}
            value={issues.reportsParams['created_at_to']}
            onChange={(dateObj) => (handleFiltersChange('created_at_to'))(dateObj ? dateObj.getTime() : null)}
          />
        </Grid>
      </Grid>
      <Grid container item md={12} xs={12} lg={12} mb={1}>
        {!isAnalyticsOnly && renderDownloadButton('Статический отчет по Юнитам', 'static-units')}
        {!isAnalyticsOnly && renderDownloadButton('Статистический отчет по городам', 'static-cities')}
        {!isAnalyticsOnly && renderDownloadButton('Детализированный статистический отчет', 'static-detailed')}
        {!isAnalyticsOnly && renderDownloadButton('Жизненный цикл обращений', 'life-cycle')}
        {!isAnalyticsOnly && renderDownloadButton('Статистика количества пользователей по регионам', 'users-regions')}
        {!isAnalyticsOnly && renderDownloadButton('Отчет пользователи JTI', 'users-jti')}
        {!isAnalyticsOnly && renderDownloadButton('Отчет пользователи BAT', 'users-bat')}
        {!isAnalyticsOnly && renderDownloadButton('Отчет пользователи PMI', 'users-pmi')}
        {!isAnalyticsOnly && renderDownloadButton('Отчет по Юнитам с разделением групп пользователей', 'units-separated-groups')}
        {isAdmin && renderDownloadButton('Отчет по ответам полиции', 'police-responses')}
        {isAdminModerBat && renderDownloadButton('Детализированный отчет БЮ ITMS', 'detailed-itms')}
      </Grid>
      {!isAnalyticsOnly &&
        <Grid container item mt={8} md={12} xs={12} lg={12} mb={8}>
          <Grid item md={4} xs={12} lg={4} display={'flex'} justifyContent={'center'}>
            <FormControlLabel
              sx={{
                minWidth: 180
              }}
              control={
                <Checkbox
                  checked={issues.reportsParams['is_jti'] || false}
                  onChange={e => handleFiltersChange('is_jti')(e.target.checked)}
                  name={'isJti'}
                />
              }
              label={strings['isJti']}
            />
          </Grid>
          <Grid item md={4} xs={12} lg={4} display={'flex'} justifyContent={'center'}>
            <FormControlLabel
              sx={{
                minWidth: 180
              }}
              control={
                <Checkbox
                  checked={issues.reportsParams['is_bat'] || false}
                  onChange={e => handleFiltersChange('is_bat')(e.target.checked)}
                  name={'isBat'}
                />
              }
              label={strings['isBat']}
            />
          </Grid>
          <Grid item md={4} xs={12} lg={4} display={'flex'} justifyContent={'center'}>
            <FormControlLabel
              sx={{
                minWidth: 180
              }}
              control={
                <Checkbox
                  checked={issues.reportsParams['is_pmi'] || false}
                  onChange={e => handleFiltersChange('is_pmi')(e.target.checked)}
                  name={'isPmi'}
                />
              }
              label={strings['isPmi']}
            />
          </Grid>
        </Grid>}
      {!!(isAnalyticsOnly || isAdmin) &&
        <Grid container item md={12} xs={12} lg={12} mb={8}>
          <Grid container item md={12} xs={12} lg={12} mb={1}>
            {renderDownloadButtonMaxTwo('Статистический отчет по Юнитам вейпы', 'static-units', {
              category_ids: '3'
            })}
            {renderDownloadButtonMaxTwo('Детализированный статистический отчет вейпы', 'static-detailed', {
              category_ids: '3'
            })}
          </Grid>
          <Grid container item md={12} xs={12} lg={12} mb={1}>
            {renderDownloadButtonMaxTwo('Статистический отчет по Юнитам стики', 'static-units', {
              category_ids: '4'
            })}
            {renderDownloadButtonMaxTwo('Детализированный статистический отчет стики', 'static-detailed', {
              category_ids: '4'
            })}
          </Grid>
        </Grid>
      }
      <Grid container item md={12} xs={12} lg={12} mb={1}>
        {isAnalyticsOnly && renderDownloadButton('Статический отчет по Юнитам', 'static-units')}
        {renderDownloadButton('Отчет по обращениям без DNP', 'no-dnp')}
        {renderDownloadButton('Отчет по решенным обращениям', 'completed-issues')}
        {renderDownloadButton('Детализированный статистический отчет по пользователям', 'static-group-detailed')}
      </Grid>
    </div>
  );
};