import * as React from 'react';
import ruLocale from 'date-fns/locale/ru';

import 'date-fns';
import strings from '../config/strings';
import {DateTimePicker as MuiDateTimePicker,} from '@mui/x-date-pickers';

import TextField from '@mui/material/TextField';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {Box} from '@mui/material';
import {StyledMobileDatePicker, StyledMobileDateTimePicker} from './styledComponents';

export const DateTimePicker = ({value, label, onChange, ...props}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
      <MuiDateTimePicker
        {...props}
        renderInput={(params) => <TextField size={'small'} sx={{width: '100%'}} {...params} />}
        inputFormat="dd.MM.yyyy HH:mm"
        value={value || null}
        onChange={onChange}
        label={label}
        ampm={false}
        ampmInClock={false}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </LocalizationProvider>
  );
};

export const DatePicker = ({value, label, onChange, ...props}) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={ruLocale}
      localeText={{
        okButtonLabel: strings.button.accept,
        clearButtonLabel: strings.button.clear,
        cancelButtonLabel: strings.button.cancel
      }}
    >
      <StyledMobileDatePicker
        {...props}
        renderInput={(params) => <TextField size={'small'} sx={{width: '100%'}} {...params} />}
        inputFormat="dd.MM.yyyy"
        value={value || null}
        onChange={onChange}
        label={label}
        ampm={false}
        ampmInClock={false}
        inputProps={{
          style: {
            margin: 1,
            backgroundColor: 'white'
          },
        }}
        componentsProps={{
          actionBar: {
            // The actions will be the same between desktop and mobile
            actions: [props.clearable === false ? '' : 'clear', 'cancel', 'accept'],
          },
        }}
      />
    </LocalizationProvider>
  );
};

export const FilterDateTimePicker = ({value, label, onChange, ...props}) => {
  return <Box sx={{width: props.width || '121px', m: 1}}>
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={ruLocale}
      localeText={{
        okButtonLabel: strings.button.accept,
        clearButtonLabel: strings.button.clear,
        cancelButtonLabel: strings.button.cancel
      }}
    >
      <StyledMobileDateTimePicker
        {...props}
        renderInput={(params) => <TextField size={'small'} sx={{
          width: '100%',
          height: '40px',
          backgroundColor: 'white'
        }} {...params} />}
        inputFormat="dd.MM.yyyy HH:mm"
        value={value || null}
        onAccept={onChange}
        onChange={() => {
        }}
        label={label}
        ampm={false}
        ampmInClock={false}
        inputProps={{
          style: {
            margin: 1,
            backgroundColor: 'white'
          },
        }}
        componentsProps={{
          actionBar: {
            // The actions will be the same between desktop and mobile
            actions: [props.clearable === false ? '' : 'clear', 'cancel', 'accept'],
          },
        }}
      />
    </LocalizationProvider></Box>;
};

export const FilterDatePicker = ({value, label, onChange, ...props}) => {
  return <Box sx={{width: props.width || '121px', m: 1}}>
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={ruLocale}
      localeText={{
        okButtonLabel: strings.button.accept,
        clearButtonLabel: strings.button.clear,
        cancelButtonLabel: strings.button.cancel
      }}
    >
      <StyledMobileDatePicker
        {...props}
        renderInput={(params) => <TextField size={'small'} sx={{
          width: '100%',
          height: '40px',
          backgroundColor: 'white'
        }} {...params} />}
        inputFormat="dd.MM.yyyy"
        value={value || null}
        onChange={onChange}
        label={label}
        ampm={false}
        ampmInClock={false}
        inputProps={{
          style: {
            margin: 1,
            backgroundColor: 'white'
          },
        }}
        componentsProps={{
          actionBar: {
            // The actions will be the same between desktop and mobile
            actions: [props.clearable === false ? '' : 'clear', 'cancel', 'accept'],
          },
        }}
      />
    </LocalizationProvider></Box>;
};