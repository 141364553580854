import * as constants from '../constants/users';
import {pagination} from '../../config/settings';
import * as _ from 'lodash';

export const initialState = {
  item: null,
  credentials: null,
  list: [],
  totalCount: 0,
  loading: false,
  params: {
    ordering: '-id', limit: pagination.limit, offset: 0, search: null
  },
  selected: [],
  pagesCount: 1,
  page: 1,
};

export default (state = _.cloneDeep(initialState), action) => {
  switch (action.type) {
    case constants.GET_USERS:
    case constants.GET_USER:
    case constants.UPDATE_USERS:
    case constants.REMOVE_USERS:
    case constants.REGENERATE_USERS_CREDENTIALS:
      return {
        ...state,
        loading: true
      };

    case constants.GET_USERS_ERROR:
    case constants.GET_USER_ERROR:
    case constants.UPDATE_USERS_ERROR:
    case constants.REMOVE_USERS_ERROR:
    case constants.REGENERATE_USERS_CREDENTIALS_ERROR:
      return {
        ...state,
        clearing: false,
        loading: false,
      };

    case constants.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        list: [],
        item: action.payload,
        totalCount: action.totalCount,
      };
    case constants.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        clearing: false,
        list: action.payload,
        item: null,
        totalCount: action.totalCount,
        pagesCount: Math.ceil(action.totalCount / pagination.limit)
      };

    case constants.EDIT_USERS:
      return {
        ...state,
        item: action.data,
      };
    case constants.ADD_USERS_SUCCESS:
    case constants.REGENERATE_USERS_CREDENTIALS_SUCCESS:
      return {
        ...state,
        credentials: action.payload,
      };
    case constants.CLEAR_USERS_CREDENTIALS:
      return {
        ...state,
        credentials: null,
      };
    case constants.USERS_CHANGE_PARAMS:
      return {
        ...state,
        params: action.data,
      };
    case constants.USERS_UPDATE_PARAMS:
      return {
        ...state,
        params: action.data !== null ? _.merge(state.params, action.data) : _.cloneDeep(initialState.params)
      };
    case constants.USERS_RESET_PARAMS:
      return {
        ...state,
        clearing: false,
        params: _.cloneDeep(initialState.params)
      };
    case constants.USERS_CHANGE_PAGE:
      return {
        ...state,
        page: +action.data,
      };
    case constants.USERS_CLEAR:
      return {
        ...state,
        clearing: true,
        totalCount: 0,
        pagesCount: 1,
        page: 1,
        item: null,
        list: [],
      };
    case constants.SELECT_USERS:
      return {
        ...state,
        selected: action.data,
      };
    case constants.UNSELECT_USERS:
      return {
        ...state,
        selected: [],
      };

    default:
      return state;
  }
};