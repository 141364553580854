export const GET_DEPARTMENTS = 'GET_DEPARTMENTS';
export const GET_DEPARTMENTS_ERROR = 'GET_DEPARTMENTS_ERROR';
export const GET_DEPARTMENTS_SUCCESS = 'GET_DEPARTMENTS_SUCCESS';

export const GET_DEPARTMENT = 'GET_DEPARTMENT';
export const GET_DEPARTMENT_ERROR = 'GET_DEPARTMENT_ERROR';
export const GET_DEPARTMENT_SUCCESS = 'GET_DEPARTMENT_SUCCESS';

export const EDIT_DEPARTMENT = 'EDIT_DEPARTMENT';
export const SELECT_DEPARTMENTS = 'SELECT_DEPARTMENTS';
export const UNSELECT_DEPARTMENTS = 'UNSELECT_DEPARTMENTS';

export const ADD_DEPARTMENT = 'ADD_DEPARTMENT';
export const ADD_DEPARTMENT_ERROR = 'ADD_DEPARTMENT_ERROR';
export const ADD_DEPARTMENT_SUCCESS = 'ADD_DEPARTMENT_SUCCESS';

export const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT';
export const UPDATE_DEPARTMENT_ERROR = 'UPDATE_DEPARTMENT_ERROR';
export const UPDATE_DEPARTMENT_SUCCESS = 'UPDATE_DEPARTMENT_SUCCESS';

export const REMOVE_DEPARTMENT = 'REMOVE_DEPARTMENT';
export const REMOVE_DEPARTMENT_ERROR = 'REMOVE_DEPARTMENT_ERROR';
export const REMOVE_DEPARTMENT_SUCCESS = 'REMOVE_DEPARTMENT_SUCCESS';

export const DEPARTMENTS_CHANGE_PARAMS = 'DEPARTMENTS_CHANGE_PARAMS';
export const DEPARTMENTS_UPDATE_PARAMS = 'DEPARTMENTS_UPDATE_PARAMS';
export const DEPARTMENTS_RESET_PARAMS = 'DEPARTMENTS_RESET_PARAMS';
export const DEPARTMENTS_CHANGE_PAGE = 'DEPARTMENTS_CHANGE_PAGE';

export const DEPARTMENTS_CLEAR = 'DEPARTMENTS_CLEAR';