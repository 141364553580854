export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_MESSAGES_ERROR = 'GET_MESSAGES_ERROR';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';

export const GET_MESSAGE = 'GET_MESSAGE';
export const GET_MESSAGE_ERROR = 'GET_MESSAGE_ERROR';
export const GET_MESSAGE_SUCCESS = 'GET_MESSAGE_SUCCESS';

export const EDIT_MESSAGE = 'EDIT_MESSAGE';

export const ADD_MESSAGE = 'ADD_MESSAGE';
export const ADD_MESSAGE_ERROR = 'ADD_MESSAGE_ERROR';
export const ADD_MESSAGE_SUCCESS = 'ADD_MESSAGE_SUCCESS';

export const UPDATE_MESSAGE = 'UPDATE_MESSAGE';
export const UPDATE_MESSAGE_ERROR = 'UPDATE_MESSAGE_ERROR';
export const UPDATE_MESSAGE_SUCCESS = 'UPDATE_MESSAGE_SUCCESS';

export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const REMOVE_MESSAGE_ERROR = 'REMOVE_MESSAGE_ERROR';
export const REMOVE_MESSAGE_SUCCESS = 'REMOVE_MESSAGE_SUCCESS';

export const MESSAGES_CHANGE_PARAMS = 'MESSAGES_CHANGE_PARAMS';
export const MESSAGES_CHANGE_PAGE = 'MESSAGES_CHANGE_PAGE';

export const MESSAGES_CLEAR = 'MESSAGES_CLEAR';