export const GET_CITIES = 'GET_CITIES';
export const GET_CITIES_ERROR = 'GET_CITIES_ERROR';
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';

export const GET_CITY = 'GET_CITY';
export const GET_CITY_ERROR = 'GET_CITY_ERROR';
export const GET_CITY_SUCCESS = 'GET_CITY_SUCCESS';

export const EDIT_CITY = 'EDIT_CITY';

export const ADD_CITY = 'ADD_CITY';
export const ADD_CITY_ERROR = 'ADD_CITY_ERROR';
export const ADD_CITY_SUCCESS = 'ADD_CITY_SUCCESS';

export const UPDATE_CITY = 'UPDATE_CITY';
export const UPDATE_CITY_ERROR = 'UPDATE_CITY_ERROR';
export const UPDATE_CITY_SUCCESS = 'UPDATE_CITY_SUCCESS';

export const REMOVE_CITY = 'REMOVE_CITY';
export const REMOVE_CITY_ERROR = 'REMOVE_CITY_ERROR';
export const REMOVE_CITY_SUCCESS = 'REMOVE_CITY_SUCCESS';

export const CITIES_CHANGE_PARAMS = 'CITIES_CHANGE_PARAMS';
export const CITIES_UPDATE_PARAMS = 'CITIES_UPDATE_PARAMS';
export const CITIES_RESET_PARAMS = 'CITIES_RESET_PARAMS';
export const CITIES_CHANGE_PAGE = 'CITIES_CHANGE_PAGE';

export const CITIES_CLEAR = 'CITIES_CLEAR';