import * as executorsConstants from '../constants/executors';
import {APIHelper} from '../../helpers';
import {path} from '../../config/api';

export const executorsActions = {
  getList,
  getItem,
  addItem,
  updateItem,
  editItem,
  removeItem,
  setPage,
  setParams,
  updateParams,
  resetParams,
  clear
};

function getList(params, second) {
  return dispatch => {
    dispatch(request(second));
    return APIHelper.get(
      `${path.executors}`,
      {
        params
      }
    ).then(
      res => {
        dispatch(success(res?.data?.results, parseInt(res?.data?.count || 0), second));
        return {
          list: res?.data?.results, totalCount: parseInt(res?.data?.count || 0)
        };
      },
      error => {
        dispatch(failure(error, second));
        return Promise.reject(error);
      }
    );
  };

  function request(second) {
    return {type: executorsConstants.GET_EXECUTORS, second};
  }

  function success(payload, totalCount, second) {
    return {type: executorsConstants.GET_EXECUTORS_SUCCESS, payload, totalCount, second};
  }

  function failure(error, second) {
    return {type: executorsConstants.GET_EXECUTORS_ERROR, error, second};
  }
}

function getItem(id, second) {
  return dispatch => {
    dispatch(request(second));
    return APIHelper.get(
      `${path.executors}/${id}`
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request(second) {
    return {type: executorsConstants.GET_EXECUTOR, second};
  }

  function success(payload, second) {
    if (payload.permissions.length) { // TODO переделать этот костыль
      payload['permissions'] = payload.permissions.map(e => e.id);
    }
    return {type: executorsConstants.GET_EXECUTOR_SUCCESS, payload, second};
  }

  function failure(error, second) {
    return {type: executorsConstants.GET_EXECUTOR_ERROR, error, second};
  }
}

function addItem(data, second) {
  return dispatch => {
    dispatch(request(second));
    return APIHelper.post(
      `${path.executors}`,
      data
    ).then(
      res => {
        dispatch(success(res.data, second));
      },
      error => {
        dispatch(failure(error, second));
        return Promise.reject(error);
      }
    );
  };

  function request(second) {
    return {type: executorsConstants.ADD_EXECUTOR, second};
  }

  function success(payload, second) {
    return {type: executorsConstants.ADD_EXECUTOR_SUCCESS, payload, second};
  }

  function failure(error, second) {
    return {type: executorsConstants.ADD_EXECUTOR_ERROR, error, second};
  }
}

function updateItem(id, data) {
  return dispatch => {
    dispatch(request());
    return APIHelper.put(
      `${path.executors}/${id}`,
      data
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: executorsConstants.UPDATE_EXECUTOR};
  }

  function success(payload) {
    return {type: executorsConstants.UPDATE_EXECUTOR_SUCCESS, payload};
  }

  function failure(error) {
    return {type: executorsConstants.UPDATE_EXECUTOR_ERROR, error};
  }
}

function editItem(data) {
  return {type: executorsConstants.EDIT_EXECUTOR, data};
}

function removeItem(id) {
  return (dispatch) => {
    dispatch(request());
    return APIHelper.delete(
      `${path.executors}/${id}`,
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: executorsConstants.REMOVE_EXECUTOR};
  }

  function success(payload) {
    return {type: executorsConstants.REMOVE_EXECUTOR_SUCCESS, payload};
  }

  function failure(error) {
    return {type: executorsConstants.REMOVE_EXECUTOR_ERROR, error};
  }
}

function setParams(data) {
  return {type: executorsConstants.EXECUTORS_CHANGE_PARAMS, data};
}

function updateParams(data, second) {
  return {type: executorsConstants.EXECUTORS_UPDATE_PARAMS, data, second};
}

function resetParams() {
  return {type: executorsConstants.EXECUTORS_RESET_PARAMS};
}

function clear() {
  return {type: executorsConstants.EXECUTORS_CLEAR};
}

function setPage(data) {
  return {type: executorsConstants.EXECUTORS_CHANGE_PAGE, data};
}