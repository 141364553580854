import * as emailsConstants from '../constants/emails';
import {APIHelper} from '../../helpers';
import {path} from '../../config/api';
import * as issuesConstants from '../constants/issues';

export const emailsActions = {
  getList,
  getItem,
  addItem,
  updateItem,
  updateItemNoLoadingEvent,
  editItem,
  removeItem,
  setPage,
  setParams,
  updateParams,
  resetParams,
  clear
};

function getList(params) {
  return dispatch => {
    dispatch(request());
    return APIHelper.get(
      `${path.emails}`,
      {
        params
      }
    ).then(
      res => {
        dispatch(success(res?.data?.results, parseInt(res?.data?.count || 0)));
        return Promise.resolve(res.data);
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: emailsConstants.GET_EMAILS};
  }

  function success(payload, totalCount) {
    return {type: emailsConstants.GET_EMAILS_SUCCESS, payload, totalCount};
  }

  function failure(error) {
    return {type: emailsConstants.GET_EMAILS_ERROR, error};
  }
}

function getItem(id) {
  return dispatch => {
    dispatch(request());
    return APIHelper.get(
      `${path.emails}/${id}`
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: emailsConstants.GET_EMAIL};
  }

  function success(payload) {
    return {type: emailsConstants.GET_EMAIL_SUCCESS, payload};
  }

  function failure(error) {
    return {type: emailsConstants.GET_EMAIL_ERROR, error};
  }
}

function addItem(data) {
  return dispatch => {
    dispatch(request());
    return APIHelper.post(
      `${path.emails}`,
      data
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: emailsConstants.ADD_EMAIL};
  }

  function success(payload) {
    return {type: emailsConstants.ADD_EMAIL_SUCCESS, payload};
  }

  function failure(error) {
    return {type: emailsConstants.ADD_EMAIL_ERROR, error};
  }
}

function updateItem(id, data) {
  return dispatch => {
    dispatch(request());
    return APIHelper.put(
      `${path.emails}/${id}`,
      data
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: emailsConstants.UPDATE_EMAIL};
  }

  function success(payload) {
    return {type: emailsConstants.UPDATE_EMAIL_SUCCESS, payload};
  }

  function failure(error) {
    return {type: emailsConstants.UPDATE_EMAIL_ERROR, error};
  }
}

function updateItemNoLoadingEvent(id, data) {
  return dispatch => {
    return APIHelper.put(
      `${path.emails}/${id}`,
      data
    ).then(
      res => {
        return Promise.resolve();
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function failure(error) {
    return {type: issuesConstants.UPDATE_ISSUE_ERROR, error};
  }
}

function editItem(data) {
  return {type: emailsConstants.EDIT_EMAIL, data};
}

function removeItem(id) {
  return (dispatch) => {
    dispatch(request());
    return APIHelper.delete(
      `${path.emails}/${id}`,
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: emailsConstants.REMOVE_EMAIL};
  }

  function success(payload) {
    return {type: emailsConstants.REMOVE_EMAIL_SUCCESS, payload};
  }

  function failure(error) {
    return {type: emailsConstants.REMOVE_EMAIL_ERROR, error};
  }
}

function setParams(data) {
  return {type: emailsConstants.EMAILS_CHANGE_PARAMS, data};
}

function updateParams(data) {
  return {type: emailsConstants.EMAILS_UPDATE_PARAMS, data};
}

function resetParams() {
  return {type: emailsConstants.EMAILS_RESET_PARAMS};
}


function setPage(data) {
  return {type: emailsConstants.EMAILS_CHANGE_PAGE, data};
}

function clear() {
  return {type: emailsConstants.EMAILS_CLEAR};
}