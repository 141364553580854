import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {Box, Button, Grid} from '@mui/material';
import {
  Columns,
  CustomPagination,
  DepartmentsSearchAddFilterGroupToolbar,
  onSortingChange,
  selectionChange
} from '../components/dataGrid';
import {
  areasActions,
  citiesActions,
  departmentsActions,
  departmentsGroupsActions,
  usersActions
} from '../redux/actions';
import {generateEmptyErrorsAndValidation} from '../config/validationRules';
import {validateForm} from '../helpers';
import {EditPageContainer, StyledContainer, StyledDataGrid} from '../components/styledComponents';
import strings from '../config/strings';
import {useDebounce} from '../components/hooks';
import {ConfirmRemovingModal, GetCreateGroupModal} from '../components/modal';
import {pagination} from '../config/settings';
import {getAsyncSelectField, getSelectField, getTextField} from '../components/fieldsInItem';
import {renderItemSkeleton} from '../components/skeletons';
import {renderArea, renderGroup} from '../components/renderFields';

export const DepartmentsScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const store = useStore();

  const model = useSelector(state => state.departments);
  const columns = [
    Columns.id(),
    Columns.departmentTitle(),
    Columns.area(renderArea),
    Columns.group(renderGroup),
  ];

  const baseActions = departmentsActions;

  useEffect(() => {
      dispatch(baseActions.getList(model.params));
    }, [model.page, model.params.search, model.params.ordering]
  );

  const rowSelected = ({id}) => {
    navigate(`/departments/${id}`);
  };

  const [searchText, setSearchText] = useState('');

  const handleFiltersChange = (field) => (value) => {
    let val = value ?? null;
    let updating = {};

    updating[field] = val;
    dispatch(departmentsActions.updateParams(updating));
  };

  const handleFiltersClear = async () => {
    dispatch(departmentsActions.resetParams());
    dispatch(departmentsActions.getList(store.getState().departments.params));
  };

  const handleFiltersApply = () => {
    dispatch(departmentsActions.getList(store.getState().departments.params));
  };


  useEffect(() => {
    dispatch(departmentsGroupsActions.clear());
    dispatch(departmentsGroupsActions.resetParams());
  });

  useEffect(() => {
    model.params.search = searchText;
    model.params.offset = 0;
    dispatch(departmentsActions.setParams(model.params));
    dispatch(departmentsActions.setPage(1));
  }, [useDebounce(searchText, 800)]);

  const [groupingActive, setGroupingActive] = useState(false);

  const declineGrouping = (groupId) => {
    dispatch(departmentsGroupsActions.updateItem(groupId, {
      deleteDepartments: model.selected
    })).then(res => {
      setGroupingActive(false);
      dispatch(departmentsActions.unselect());
      dispatch(baseActions.getList(model.params)); // TODO проверить работу
    });
  };


  const acceptGrouping = (groupId) => {
    dispatch(departmentsGroupsActions.updateItem(groupId, {
      departments: model.selected
    })).then(res => {
      setGroupingActive(false);
      dispatch(departmentsActions.unselect());
      dispatch(baseActions.getList(model.params)); // TODO проверить работу
    });
  };

  return (
    <div style={{height: '90%', width: '100%'}}>
      {!model.loading && model.list && model.list.length ?
        <GetCreateGroupModal
          open={!!groupingActive}
          onClose={() => {
            setGroupingActive(false);
          }}
          onAccept={acceptGrouping}
          onDecline={declineGrouping}
          text={'Добавить в группу выбранные подразделения'}
        /> : ''
      }
      <StyledDataGrid
        getRowClassName={() => 'styled'}
        checkboxSelection={true}
        keepNonExistentRowsSelected
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        filterMode="server"
        sortingMode="server"
        paginationMode="server"
        columns={columns}
        rows={model.list}
        onSortModelChange={onSortingChange(model, dispatch, baseActions)}
        onRowClick={rowSelected}
        selectionModel={model.selected}
        onSelectionModelChange={selectionChange(model, dispatch, baseActions)}
        loading={model.loading}
        components={{
          Toolbar: DepartmentsSearchAddFilterGroupToolbar,
          Pagination: CustomPagination(model, dispatch, baseActions),
        }}
        componentsProps={{
          toolbar: {
            path: 'departments',
            model: model,
            handleFiltersChange: handleFiltersChange,
            handleFiltersClear: handleFiltersClear,
            handleFiltersApply: handleFiltersApply,
            startGrouping: () => setGroupingActive(true),
            value: searchText,
            onChange: (event) => setSearchText(event.target.value),
            clearSearch: () => setSearchText(''),
          },
        }}
        localeText={{
          footerRowSelected: (count) =>
            `Выбрано элементов - ${count.toLocaleString()}`,
        }}
      />
    </div>
  );
};


const {fieldsInitState, fieldsValidators} = generateEmptyErrorsAndValidation([
  'title',
  'city',
  'area',
]);


export const DepartmentEditScreen = () => {
  const dispatch = useDispatch();
  const {id} = useParams();
  const navigate = useNavigate();
  const store = useStore();

  const model = useSelector(state => state.departments);
  const areas = useSelector(state => state.areas);
  const cities = useSelector(state => state.cities);

  const [state, setState] = useState(fieldsInitState);

  const handleChange = (field) => (e) => {
    setState(prevState => ({
      ...prevState, [field]: {
        'error': false,
        'helperText': null,
      }
    }));
    let updated = model.item;
    updated[field] = e.target.value;
    dispatch(departmentsActions.editItem(updated));
  };

  const handleSelectChange = (field) => async (e) => {
    let updated = model.item;
    updated[field] = e.target.value;
    dispatch(departmentsActions.editItem(updated));
    Promise.all([
      dispatch(citiesActions.clear()),
      dispatch(citiesActions.setParams({
        area: e.target.value,
        limit: 50,
        offset: 0
      }))
    ]).then(() => {
      dispatch(citiesActions.getList(store.getState().cities.params));
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = validateForm(model.item, fieldsValidators, setState);
    if (data) {
      dispatch(departmentsActions.updateItem(id, data)).then(() => {
        location.reload();
      });
    }
  };

  const handleCancel = () => {
    dispatch(departmentsActions.editItem({}));
    navigate('/departments');
  };

  const [removing, setRemoving] = useState(false);
  const handleRemove = () => {
    setRemoving(true);
  };
  const closeRemoving = () => {
    setRemoving(false);
  };

  const confirmRemove = () => {
    dispatch(departmentsActions.removeItem(id)).then(() => {
      setRemoving(false);
      navigate('/departments');
    });
  };

  useEffect(() => {
      dispatch(departmentsActions.getItem(id)).then(res => {
        dispatch(areasActions.getList({}));
        dispatch(citiesActions.setParams({area: res.area}));
        // dispatch(citiesActions.getList({area: res.area}));
      });
    }, []
  );

  const handleAsyncSelectChange = (field) => (e) => {
    let updated = model.item;
    updated[field] = e?.value;
    dispatch(departmentsActions.editItem(updated));
  };

  const fetchMore = async (search) => {
    const {params} = store.getState().cities;
    let offset = 0;
    if (search === params.search) {
      offset = params.offset + pagination.limit;
    }

    dispatch(citiesActions.updateParams({
      limit: pagination.limit,
      ordering: 'id',
      offset: offset,
      search: search
    }));
    return dispatch(citiesActions.getList(store.getState().cities.params));
  };

  return (
    <EditPageContainer>
      {!model.loading && model.item ?
        <ConfirmRemovingModal
          open={!!removing}
          onClose={closeRemoving}
          onConfirm={confirmRemove}
          text={`Подразделение ID:${id} - ${model.item.title}`}
        /> : ''
      }
      <StyledContainer maxWidth="xs">
        {!model.loading && model.item ?
          <form onSubmit={handleSubmit}>
            <Box sx={{flexGrow: 1}}>
              <Grid container spacing={4}>
                {getTextField(model, state, handleChange, 'id', {disabled: true})}
                {getTextField(model, state, handleChange, 'title')}
                {getSelectField(areas, model, state, handleSelectChange, 'area', 'name', ['id', 'name'])}

                {cities.list.length ? getAsyncSelectField(cities, model, state, handleAsyncSelectChange, fetchMore, 'city', 'title', ['id', 'title']) : null}

                <Grid container={true} mt={4} mb={4} justifyContent={'space-around'}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {strings.button.save}
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={handleCancel}
                  >
                    {strings.button.cancel}
                  </Button>
                  <Button
                    type="button"
                    color="secondary"
                    variant="contained"
                    onClick={handleRemove}
                  >
                    {strings.button.remove}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form> : renderItemSkeleton()
        }
      </StyledContainer>
    </EditPageContainer>
  );
};


export const DepartmentAddScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const store = useStore();
  const model = useSelector(state => state.departments);
  const areas = useSelector(state => state.areas);
  const cities = useSelector(state => state.cities);

  const [state, setState] = useState(fieldsInitState);

  const handleChange = (field) => (e) => {
    setState(prevState => ({
      ...prevState, [field]: {
        'error': false,
        'helperText': null,
      }
    }));
    let updated = model.item;
    updated[field] = e.target?.value;
    dispatch(usersActions.editItem(updated));
  };

  const handleSelectChange = (field) => (e) => {
    let updated = model.item;
    updated[field] = e.target.value;
    dispatch(usersActions.editItem(updated));
    Promise.all([
      dispatch(citiesActions.clear()),
      dispatch(citiesActions.setParams({
        area: e.target.value,
        limit: 50,
        offset: 0
      }))
    ]).then(() => {
      dispatch(citiesActions.getList(store.getState().cities.params));
    });
  };

  const handleAsyncSelectChange = (field) => (e) => {
    let updated = model.item;
    updated[field] = e?.value;
    dispatch(departmentsActions.editItem(updated));
  };

  const fetchMore = async (search) => {
    const {params} = store.getState().cities;
    let offset = 0;
    if (search === params.search) {
      offset = params.offset + pagination.limit;
    }

    dispatch(citiesActions.updateParams({
      limit: pagination.limit,
      ordering: 'id',
      offset: offset,
      search: search
    }));
    return dispatch(citiesActions.getList(store.getState().cities.params));

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = validateForm(model.item, fieldsValidators, setState);
    if (data) {
      dispatch(departmentsActions.addItem(data)).then(() => {
        navigate('/departments');
      });
    }
  };

  const handleCancel = () => {
    dispatch(departmentsActions.editItem({}));
    navigate('/departments');
  };

  useEffect(() => {
      dispatch(departmentsActions.editItem({}));
      dispatch(areasActions.getList({}));
      dispatch(citiesActions.setParams(null)); //TODO не загружаются сразу нужные из стора, доработать
      dispatch(citiesActions.getList(cities.params));
    }, []
  );

  return (
    <EditPageContainer>
      <StyledContainer maxWidth="xs">
        {!model.loading && model.item ?
          <form onSubmit={handleSubmit}>
            <Box sx={{flexGrow: 1}}>
              <Grid container spacing={4}>

                {getTextField(model, state, handleChange, 'title')}
                {getSelectField(areas, model, state, handleSelectChange, 'area', 'name', ['id', 'name'])}

                {cities.list.length ? getAsyncSelectField(cities, model, state, handleAsyncSelectChange, fetchMore, 'city', 'title', ['id', 'title']) : null}


                <Grid container={true} mt={4} mb={4} justifyContent={'space-around'}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {strings.button.save}
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={handleCancel}
                  >
                    {strings.button.cancel}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form> : renderItemSkeleton()
        }
      </StyledContainer>
    </EditPageContainer>
  );
};