import axios from 'axios';

import {port, url} from '../config/api';
import * as errors from '../config/errors';
import * as strings from '../config/strings';
import store from '../redux/store';
import {authHeader} from './auth';
import {LOGOUT} from '../redux/constants';
import {history} from './history';
import {alertActions} from '../redux/actions';

const parseErrorCode = (error) => {
  if (error.response) {
    const code = error.response.status;
    if (code === errors.UNAUTHORIZED) {
      let message = error.response.data.detail;
      store.dispatch((dispatch) => {
        dispatch(alertActions.apiError({
          code,
          message
        }));
      });
      store.dispatch((dispatch) => {
        localStorage.removeItem('xAuthToken');
        localStorage.removeItem('authGroups');
        history.push('/login');
        dispatch({type: LOGOUT});
      });
    } else if ([errors.NOT_FOUND, errors.FORBIDDEN, errors.BAD_REQUEST].includes(code)) {
      let message = error.response.data.detail;
      message = message ? message : strings.errors[code];
      store.dispatch((dispatch) => {
        dispatch(alertActions.apiError({
          code,
          message
        }));
      });
    } else if (code === errors.INTERNAL_SERVER_ERROR) {
      const message = strings.errors[code];
      store.dispatch((dispatch) => {
        dispatch(alertActions.apiError({
          code,
          message
        }));
      });
    } else {
      const {detail} = error.response.data;
      store.dispatch((dispatch) => {
        dispatch(alertActions.apiError({
          code,
          message: detail
        }));
      });
    }
  } else {
    store.dispatch((dispatch) => {
      dispatch(alertActions.networkError());
    });
  }

  return Promise.reject(error.response);
};

export const APIHelper = axios.create();

// Request parsing interceptor
APIHelper.interceptors.request.use(
  async (config) => {
    const headers = authHeader();
    config.baseURL = `${url}:${port}/`;
    if (headers) {
      config.headers = headers;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// Response parsing interceptor
APIHelper.interceptors.response.use(
  (response) => response,
  (error) => parseErrorCode(error),
);