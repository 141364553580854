import * as categoriesConstants from '../constants/categories';
import {APIHelper, categoryJsonToFormData} from '../../helpers';
import {path} from '../../config/api';

export const categoriesActions = {
  getList,
  getItem,
  addItem,
  updateItem,
  editItem,
  removeItem,
  setPage,
  setParams,
  updateParams,
  clear,
  resetParams
};

function getList(params) {
  return dispatch => {
    dispatch(request());
    return APIHelper.get(
      `${path.categories}`,
      {
        params
      }
    ).then(
      res => {
        dispatch(success(res?.data?.results, parseInt(res?.data?.count || 0)));
        return {
          list: res?.data?.results, totalCount: parseInt(res?.data?.count || 0)
        };
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: categoriesConstants.GET_CATEGORIES};
  }

  function success(payload, totalCount) {
    return {type: categoriesConstants.GET_CATEGORIES_SUCCESS, payload, totalCount};
  }

  function failure(error) {
    return {type: categoriesConstants.GET_CATEGORIES_ERROR, error};
  }
}

function getItem(id) {
  return dispatch => {
    dispatch(request());
    return APIHelper.get(
      `${path.categories}/${id}`
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: categoriesConstants.GET_CATEGORY};
  }

  function success(payload) {
    return {type: categoriesConstants.GET_CATEGORY_SUCCESS, payload};
  }

  function failure(error) {
    return {type: categoriesConstants.GET_CATEGORY_ERROR, error};
  }
}

function addItem(data) {
  return dispatch => {
    const formData = categoryJsonToFormData(data);
    dispatch(request());
    return APIHelper.post(
      `${path.categories}`,
      formData
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: categoriesConstants.ADD_CATEGORY};
  }

  function success(payload) {
    return {type: categoriesConstants.ADD_CATEGORY_SUCCESS, payload};
  }

  function failure(error) {
    return {type: categoriesConstants.ADD_CATEGORY_ERROR, error};
  }
}

function updateItem(id, data) {
  return dispatch => {
    delete data['id'];
    const formData = categoryJsonToFormData(data);
    dispatch(request());
    return APIHelper.put(
      `${path.categories}/${id}`,
      formData
    ).then(
      res => {
        dispatch(success(res.data));
        return Promise.resolve(res.data);
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: categoriesConstants.UPDATE_CATEGORY};
  }

  function success(payload) {
    return {type: categoriesConstants.UPDATE_CATEGORY_SUCCESS, payload};
  }

  function failure(error) {
    return {type: categoriesConstants.UPDATE_CATEGORY_ERROR, error};
  }
}

function editItem(data) {
  return {type: categoriesConstants.EDIT_CATEGORY, data};
}

function removeItem(id) {
  return (dispatch) => {
    dispatch(request());
    return APIHelper.delete(
      `${path.categories}/${id}`,
    ).then(
      res => {
        dispatch(success(res.data));
      },
      error => {
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return {type: categoriesConstants.REMOVE_CATEGORY};
  }

  function success(payload) {
    return {type: categoriesConstants.REMOVE_CATEGORY_SUCCESS, payload};
  }

  function failure(error) {
    return {type: categoriesConstants.REMOVE_CATEGORY_ERROR, error};
  }
}

function setParams(data) {
  return {type: categoriesConstants.CATEGORIES_CHANGE_PARAMS, data};
}

function updateParams(data) {
  return {type: categoriesConstants.CATEGORIES_UPDATE_PARAMS, data};
}

function resetParams() {
  return {type: categoriesConstants.CATEGORIES_RESET_PARAMS};
}

function setPage(data) {
  return {type: categoriesConstants.CATEGORIES_CHANGE_PAGE, data};
}

function clear() {
  return {type: categoriesConstants.CATEGORIES_CLEAR};
}