export const GET_ISSUE_STATUSES = 'GET_ISSUE_STATUSES';
export const GET_ISSUE_STATUSES_ERROR = 'GET_ISSUE_STATUSES_ERROR';
export const GET_ISSUE_STATUSES_SUCCESS = 'GET_ISSUE_STATUSES_SUCCESS';

export const GET_ISSUE_STATUS = 'GET_ISSUE_STATUS';
export const GET_ISSUE_STATUS_ERROR = 'GET_ISSUE_STATUS_ERROR';
export const GET_ISSUE_STATUS_SUCCESS = 'GET_ISSUE_STATUS_SUCCESS';

export const EDIT_ISSUE_STATUS = 'EDIT_ISSUE_STATUS';

export const ADD_ISSUE_STATUS = 'ADD_ISSUE_STATUS';
export const ADD_ISSUE_STATUS_ERROR = 'ADD_ISSUE_STATUS_ERROR';
export const ADD_ISSUE_STATUS_SUCCESS = 'ADD_ISSUE_STATUS_SUCCESS';

export const UPDATE_ISSUE_STATUS = 'UPDATE_ISSUE_STATUS';
export const UPDATE_ISSUE_STATUS_ERROR = 'UPDATE_ISSUE_STATUS_ERROR';
export const UPDATE_ISSUE_STATUS_SUCCESS = 'UPDATE_ISSUE_STATUS_SUCCESS';

export const REMOVE_ISSUE_STATUS = 'REMOVE_ISSUE_STATUS';
export const REMOVE_ISSUE_STATUS_ERROR = 'REMOVE_ISSUE_STATUS_ERROR';
export const REMOVE_ISSUE_STATUS_SUCCESS = 'REMOVE_ISSUE_STATUS_SUCCESS';

export const ISSUE_STATUSES_CHANGE_PARAMS = 'ISSUE_STATUSES_CHANGE_PARAMS';
export const ISSUE_STATUSES_RESET_PARAMS = 'ISSUE_STATUSES_RESET_PARAMS';
export const ISSUE_STATUSES_UPDATE_PARAMS = 'ISSUE_STATUSES_UPDATE_PARAMS';
export const ISSUE_STATUSES_CLEAR = 'ISSUE_STATUSES_CLEAR';
export const ISSUE_STATUSES_CHANGE_PAGE = 'ISSUE_STATUSES_CHANGE_PAGE';